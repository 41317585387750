import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Radio,
  Button,
} from 'antd';
import { Icon } from '@iconify/react';
import RPInput from '@/components/RPInput';
import RPButton from '@/components/RPButton';
import RPInputAmount from '@/components/RPInputAmount';
import { formatter } from '@/utils';
import {
  AMOUNT_LIMIT,
  DEFAULT_CURRENCY,
} from '@/constants';
import { hasSpecialCharacters } from '@/utils/validate';

const AmountTypeOptions = [
  { label: 'Fixed', value: 'fixed-amount' },
  { label: 'Customer\'s Decision', value: 'customers-decide-amount' },
];

function PriceField({
  label,
  index,
  amount,
  onUpdate,
  onCancel,
  onDelete,
  deletable,
  isEditing,
  amountType,
  onMoveUp,
  onMoveDown,
  hideMoveUp = false,
  hideMoveDown = false,
}) {
  const [editing, setEditing] = useState(isEditing);
  const [newLabel, setNewLabel] = useState(label);
  const [hasErrorAmount, setHasErrorAmount] = useState(!label || !amount);
  const [currentAmountType, setCurrentAmountType] = useState(amountType);
  const [currentAmount, setCurrentAmount] = useState(amount);
  const [showFixedAmmount, setShowFixedAmount] = useState(currentAmountType === 'fixed-amount');
  const [showAmmountFillByCustomer, setShowAmountFillByCustomer] = useState(
    currentAmountType === 'customers-decide-amount'
  );
  const priceFieldRef = useRef();
  const [hasErrorLabel, setHasErrorLabel] = useState(false);
  const [errorLabel, setErrorLabel] = useState({});

  const handleOnChangeAmountType = (event) => {
    const { value } = event.target;
    setCurrentAmountType(value);

    if (value === 'fixed-amount') {
      // setHasError(true);
      setShowFixedAmount(true);
      setShowAmountFillByCustomer(false);
      const isValid = !!currentAmount && !Number.isNaN(currentAmount) && currentAmount > 0;
      if (isValid) {
        setHasErrorAmount(false);
      } else {
        setHasErrorAmount(true);
      }
    }

    if (value === 'customers-decide-amount') {
      setHasErrorAmount(false);
      setShowFixedAmount(false);
      setShowAmountFillByCustomer(true);
    }
  };

  const handleOnDelete = () => {
    onDelete(index);
  };

  const handleOnClickEdit = () => {
    priceFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setEditing(true);
  };

  const onSave = () => {
    // save value to parent component
    onUpdate(newLabel, index, currentAmount, currentAmountType);
    setEditing(false);
  };

  const onClickCancel = () => {
    setNewLabel(label);
    setCurrentAmount(amount);
    setCurrentAmountType(amountType);
    setEditing(false);
    onUpdate(label, index, amount, amountType);
    onCancel(index);
  };

  const onChangeLabel = e => {
    const { value } = e.target;
    setNewLabel(value);
    if (!value || value.trim() === '') {
      setHasErrorLabel(true);
      setErrorLabel({
        message: 'can\'t be blank'
      });
    } else if (hasSpecialCharacters(value)) {
      setHasErrorLabel(true);
      setErrorLabel({
        message: 'shouldn\'t contain special character'
      });
    } else {
      setHasErrorLabel(false);
      setErrorLabel({});
    }
  };

  const handleOnChangeAmount = value => {
    // const value = e.target.value.trim();
    setCurrentAmount(value);

    const isValid = !!value && !Number.isNaN(value) && value > 0 && value <= AMOUNT_LIMIT.MAX;

    if (isValid) {
      setHasErrorAmount(false);
    } else {
      setHasErrorAmount(true);
    }
  };

  const showEditMode = () => {
    priceFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setEditing(true);
  };

  useEffect(() => {
    document.body.classList.toggle('disable-scroll', editing);
    document.getElementById('rp-pp-fc-right')?.classList?.toggle('disable-scroll', editing);
  }, [editing]);

  return (
    <div>
      {
        !!editing && <div className='user-layout__mask' />
      }
      <div ref={priceFieldRef} className={`amount-container --editable${editing ? ' --editing' : ''}`}>
        <div className='field-wrapper__actions'>
          {!editing && (
            <>
              <Button
                type="link"
                onClick={handleOnClickEdit}
              >
                <Icon icon="bxs:pencil" />
              </Button>

              {!hideMoveUp &&
                <Button
                  type="link"
                  onClick={() => onMoveUp(index)}
                >
                  <Icon icon="ep:arrow-up" />
                </Button>
              }

              {!hideMoveDown &&
                <Button
                  type="link"
                  onClick={() => onMoveDown(index)}
                >
                  <Icon icon="ep:arrow-down" />
                </Button>
              }
            </>
          )}

          {!editing && deletable &&
            <Button
              type="link"
              onClick={handleOnDelete}
            >
              <Icon icon="akar-icons:cross" />
            </Button>
          }

        </div>
        <Row
          gutter={24}
          onClick={showEditMode}
        >
          <Col span={24}>
            <div>
              <div className='label-custom'>
                {!editing && <span>{newLabel}</span>}
                {!!editing &&
                  <>
                    <RPInput
                      required
                      value={newLabel}
                      label='Field Label'
                      placeholder='Field Label'
                      onChange={onChangeLabel}
                      status={hasErrorLabel ? 'error' : ''}
                      maxLength={60}
                    />
                    {Object.keys(errorLabel).length > 0 &&
                      <span className='error-text font-neurial-grotesk'>{`Field Label ${errorLabel.message}`}</span>
                    }
                  </>
                }
              </div>

              <div className='amount-type-options'>
                <Radio.Group
                  options={AmountTypeOptions}
                  onChange={handleOnChangeAmountType}
                  optionType="button"
                  disabled={!editing}
                  value={currentAmountType}
                  className="amount-type-options__item"
                />
              </div>
              {!!showFixedAmmount &&
                <RPInputAmount
                  value={currentAmount}
                  label="Amount"
                  prefix={formatter.currencySymbol(DEFAULT_CURRENCY)}
                  placeholder="Input Amount"
                  required
                  onChange={handleOnChangeAmount}
                  status={hasErrorAmount && currentAmount ? 'error' : ''}
                />
              }

              {!!showAmmountFillByCustomer &&
                <RPInput
                  className="rp-payment-pages__form__card__right__input --custom"
                  placeholder="To be filled by customers"
                  hint='Customers Decide Amount makes you unable to edit this'
                  disabled
                />
              }
            </div>

            {!!editing &&
              <div className='payment-page__new__group-input--editing'>
                <RPButton
                  grey
                  onClick={onClickCancel}
                >
                  Cancel
                </RPButton>
                <RPButton
                  gradient
                  onClick={onSave}
                  disabled={hasErrorAmount || hasErrorLabel}
                >
                  Save
                </RPButton>
              </div>
            }
          </Col>
        </Row>
      </div>
    </div>
  );
}

PriceField.propTypes = {
  onCancel: PropTypes.func,
  onUpdate: PropTypes.func,
  index: PropTypes.number,
  amountType: PropTypes.string,
  label: PropTypes.string,
  isEditing: PropTypes.bool,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
  amount: PropTypes.number,
  hideMoveUp: PropTypes.bool,
  hideMoveDown: PropTypes.bool,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
};

export default PriceField;
