import React from 'react';
import { Form } from 'antd';
import RPInput from '@/components/RPInput';
import RPButton from '@/components/RPButton';
import PropTypes from 'prop-types';
import { errorMessages } from '@/constants';
import { validatePassword } from '@/utils/validate';
import './index.scss';

function ContentChangePassword({
  onSubmit,
  form
}) {
  const renderInputUpdatePassword = (
    <div className="my-account__profile__modal">
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        noValidate
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item
          name="currentPassword"
          rules={[
            {
              required: true,
              message: 'Please input your current password',
            },
            () => ({
              validator(_, value) {
                if (value && !validatePassword(value)) {
                  return Promise.reject(new Error(errorMessages.invalidPassword));
                }
                return Promise.resolve();
              },
            }),
          ]}
          wrapperCol={{ span: 24 }}
        >
          <RPInput
            name="currentPassword"
            placeholder="Current Password"
            type="password"
            label="Current Password"
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          rules={[
            {
              required: true,
              message: 'Please input your new password',
            },
            () => ({
              validator(_, value) {
                if (value && !validatePassword(value)) {
                  return Promise.reject(new Error(errorMessages.invalidPassword));
                }
                return Promise.resolve();
              },
            }),
          ]}
          wrapperCol={{ span: 24 }}
        >
          <RPInput
            name="newPassword"
            placeholder="New Password"
            type="password"
            label="New Password"
          />
        </Form.Item>
        <Form.Item
          name="confirmNewPassword"
          rules={[
            {
              required: true,
              message: 'Please input your confirm new password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}
          wrapperCol={{ span: 24 }}
        >
          <RPInput
            name="confirmNewPassword"
            placeholder="Confirm New Password"
            type="password"
            label="Confirm New Password"
          />
        </Form.Item>
        <RPButton
          className="my-account__profile__modal__submit-btn"
          gradient
          center
          htmlType="submit"
        >
          Update
        </RPButton>
      </Form>
    </div>
  );

  return renderInputUpdatePassword;
}

ContentChangePassword.propTypes = {
  onSubmit: PropTypes.func,
  form: PropTypes.any
};

export default ContentChangePassword;
