import React, { useState } from 'react';
import { Document, pdfjs, Page } from 'react-pdf';
import {
  Spin,
  Modal,
  Button
} from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import './index.scss';

function PreviewPdf({ urlPdf, name, visible, handleCancel }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingPdf, setLoadingPdf] = useState(true);

  const onDocumentLoadSuccess = (e) => {
    setLoadingPdf(false);
    setNumPages(e.numPages);
  };

  const nextPage = () => {
    numPages > pageNumber && setPageNumber(pageNumber + 1);
  };

  const previousPage = () => {
    pageNumber > 1 && setPageNumber(pageNumber - 1);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const handleDownloadPDFile = () => {
    fetch(urlPdf)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const aTag = document.createElement('a');
        aTag.href = blobURL;
        aTag.style.display = 'none';

        if (name && name.length) aTag.download = name;
        document.body.appendChild(aTag);
        aTag.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Modal
      visible={visible}
      title={`Preview ${name}`}
      className='modal-preview'
      onCancel={handleCancel}
      footer={null}
    >
      <Spin spinning={loadingPdf}>
        <Document file={urlPdf} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        {
          !loadingPdf && (
            <div className='preview-group'>
              <div className='preview-group__action'>
                <Button type="primary" shape="circle" onClick={previousPage}>
                  <LeftOutlined />
                </Button>
                Page {pageNumber} of {numPages}
                <Button type="primary" shape="circle" onClick={nextPage}>
                  <RightOutlined />
                </Button>
              </div>
              <Button
                type="primary"
                shape="circle"
                onClick={handleDownloadPDFile}
              >
                <DownloadOutlined />
              </Button>
            </div>
          )
        }
      </Spin>
    </Modal>
  );
}

PreviewPdf.propTypes = {
  urlPdf: PropTypes.string,
  name: PropTypes.string,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func
};

export default PreviewPdf;
