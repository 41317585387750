import http from '@/http';
import { ApiKYC } from '@/constants/api';
import {
  parse,
  messageNotifyUtil,
} from '@/utils';

export async function getMerchantKyc() {
  try {
    const res = await http.get(ApiKYC.getMerchantKyc);

    const { status, data } = parse.camelize(res);

    if (status === 200) {
      return {
        success: true,
        data,
      };
    }

    return {
      success: false,
      data: null
    };
  } catch (error) {
    if (error?.response?.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }

    return {
      success: false,
      data: null
    };
  }
}

export async function submitKyc({ payload }) {
  try {
    const res = await http.post(ApiKYC.submitKyc, parse.snakelize(payload));

    return res;
  } catch (error) {
    if (error?.response?.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function updateKyc({ payload }) {
  try {
    const res = await http.patch(ApiKYC.updateKyc, parse.snakelize(payload));

    return res;
  } catch (error) {
    if (error?.response?.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}
