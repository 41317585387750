import React from 'react';

import MyProfile from '@/components/MyAccount/MyProfile';
import { myAccountService } from '@/services';

function MyProfileContainer() {
  const changeDisplayName = async ({ displayName }) =>
    myAccountService.updateDisplayName({ displayName });
  const updateAccountPassword = async ({ oldPassword, newPassword, confirmPassword }) =>
    myAccountService.updatePassword({ oldPassword, newPassword, confirmPassword });
  const changeAccountSupportDetail = async ({ supportEmail, supportWebsiteUrl, supportPhoneNumber }) =>
    myAccountService.changeAccountSupportDetail({ supportEmail, supportWebsiteUrl, supportPhoneNumber });
  const updateAccountContactNumber = async (data) =>
    myAccountService.updateContactNumber(data);
  const getAccountInformation = async () => myAccountService.getAccountInformation();
  const update2FA = async ({ enable, otpToken }) => myAccountService.update2FA({ enable, otpToken });
  const requestOTP = async ({ action, method }) => myAccountService.generateOTP({ action, method });
  const validateOTP = async ({ action, otpCode }) => myAccountService.validateOTP({ action, otpCode });

  return (
    <MyProfile
      update2FA={update2FA}
      requestOTP={requestOTP}
      validateOTP={validateOTP}
      changeDisplayName={changeDisplayName}
      getAccountInformation={getAccountInformation}
      updateAccountPassword={updateAccountPassword}
      changeAccountSupportDetail={changeAccountSupportDetail}
      updateAccountContactNumber={updateAccountContactNumber}
    />
  );
}

export default MyProfileContainer;
