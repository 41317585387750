import React from 'react';
import MerchantModeBadge from '@/components/MerchantModeBadge';
import TransactionTable from './table';
import './index.scss';

function Transactions() {
  return (
    <div className="dashboard-transactions">
      <MerchantModeBadge />
      <TransactionTable />
    </div>
  );
}

export default Transactions;
