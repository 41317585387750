import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
} from 'antd';
import {
  uniqueId
} from 'lodash';

import arrowDown from '@/assets/images/arrow-down.svg';
import './index.scss';

function RPSelect({
  label,
  value,
  children,
  required,
  className,
  disabled = false,
  ...props
}) {
  const [selectKlass, setSelectKlass] = useState(value ? '--has-value' : '');

  useEffect(() => {
    value ? setSelectKlass('--has-value') : setSelectKlass('');
  }, [value]);

  const handleOnSelect = (selectedValue) => {
    if (selectedValue) {
      setSelectKlass('--has-value');
    }
  };
  const rpSelectIdName = uniqueId('rp-select-');
  return (
    <div
      className={`rp-select-container ${selectKlass}`}
      id={rpSelectIdName}
    >
      <Select
        onSelect={handleOnSelect}
        size="large"
        suffixIcon={
          !disabled ? <img src={arrowDown} alt="select icon" height={24} width={24} /> : null
        }
        value={value}
        getPopupContainer={() => document.getElementById(`${rpSelectIdName}`)}
        className={`${className} ${selectKlass}`}
        disabled={disabled}
        {...props}
      >
        {children}
      </Select>
      {!!label && (
        <label htmlFor='#' className="custom-input__label">
          {label}
          {!!required && (<span className="custom-input__required">*</span>)}
        </label>
      )
      }
    </div>
  );
}

RPSelect.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.array,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};


export default RPSelect;
