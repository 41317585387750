import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import moment from 'moment';

import ModalReminder from '@/components/PaymentLink/ModalReminder';
import DetailCard from '@/components/PaymentLink/DetailCard';
import ModalCancelLink from '@/components/PaymentLink/ModalCancelLink';
import ModalChangePaymentLink from '@/components/PaymentLink/ModalChangePaymentLink';
import MerchantModeBadge from '@/components/MerchantModeBadge';

function Detail({
  data,
  handleCancelLink,
  handleReminder,
  handleUpdate
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [openModalPL, setOpenModalPL] = useState(false);
  const [form] = Form.useForm();
  const [formExpiry] = Form.useForm();
  const [dateExpiry, setDateExpiry] = useState();
  const [modalType, setModalType] = useState('');

  const showModal = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  const cancelLink = () => {
    setIsCancel(true);
  };

  const changeExpiry = () => {
    setModalType('expiry');
    formExpiry.resetFields();
    formExpiry.setFieldsValue({ expiry: dateExpiry });
    setOpenModalPL(true);
  };

  const changeRefID = () => {
    setModalType('refID');
    formExpiry.resetFields();
    formExpiry.setFieldsValue({ refId: data.referenceId });
    setOpenModalPL(true);
  };

  const onUpdatePL = (values) => {
    switch (modalType) {
      case 'expiry':
        handleUpdate({expireDate: values.expiry?.utc().format('YYYY-MM-DD HH:mm:ss')});
        break;
      case 'refID':
        handleUpdate({refId: values.refId});
        break;
      default:
        break;
    }
    setOpenModalPL(false);
  };

  const onReminder = e => handleReminder(e);

  useEffect(() => {
    if (data.expiredAt) {
      const expiredAt = moment(new Date(data.expiredAt)).format('YYYY-MM-DD HH:mm:ss');
      setDateExpiry(moment(expiredAt, 'YYYY-MM-DD HH:mm:ss'));
    }
  }, [data]);
  return (
    <div
      className="payment-link"
    >
      <MerchantModeBadge />
      <DetailCard
        showModal={showModal}
        data={data}
        cancelLink={cancelLink}
        changeExpiry={changeExpiry}
        changeRefID={changeRefID}
      />
      <ModalReminder
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        form={form}
        hasExpiry={data.hasExpiry}
        reminder={data.remindAfter}
        onSubmit={onReminder}
      />
      <ModalChangePaymentLink
        isModalVisible={openModalPL}
        setIsModalVisible={setOpenModalPL}
        onSubmit={onUpdatePL}
        form={formExpiry}
        typeModal={modalType}
      />
      <ModalCancelLink
        centered
        isModalVisible={isCancel}
        setIsModalVisible={setIsCancel}
        cancelLink={handleCancelLink}
      />
    </div>
  );
}

Detail.propTypes = {
  data: PropTypes.object,
  handleCancelLink: PropTypes.func,
  handleReminder: PropTypes.func,
  handleUpdate: PropTypes.func
};

export default Detail;
