/* eslint-disable max-len */
import React from 'react';
import SignUp from '@/containers/Auth/SignUp';

function SignUpPage() {
  return (
    <SignUp/>
  );
}

export default SignUpPage;
