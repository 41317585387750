import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import {
  Link,
  useHistory,
  generatePath,
  useLocation,
} from 'react-router-dom';

import { CHECKOUT_ROUTES, ROUTES } from '@/constants';
import RPButton from '@/components/RPButton';
import DetailViewCard from '@/components/PaymentPages/DetailViewCard';
import openNewTabIcon from '@/assets/images/payment-pages/detail-view-card/open-new-tab-icon.svg';

import './index.scss';

function SuccessPage({
  data,
  paymentPageCreatedId,
}) {
  const history = useHistory();
  const location = useLocation();

  const handleCreateNewPayPage = () => {
    if (location.pathname === ROUTES.APP.PAYMENT_PAGES.NEW) {
      window.location.reload();
    } else {
      history.replace(ROUTES.APP.PAYMENT_PAGES.NEW);
    }
  };

  const {
    pageDetails,
    paymentDetails,
  } = data;

  return (
    <div>
      <div className="rp-payment-pages__form__card --page-success">
        <Row>
          <Col span={24}>
            <p className='rp-payment-pages__form__card__title'>Payment Page Successfully Created!</p>

            <div className='rp-payment-pages__form__card__preview'>
              <div className='card__preview__container'>
                <DetailViewCard
                  pageDetails={pageDetails}
                  paymentDetails={paymentDetails}
                />
              </div>

              <div className='rp-payment-pages__form__card__preview__new-tab'>
                <Link
                  to={{ pathname: generatePath(CHECKOUT_ROUTES.PAYMENT_PAGE.VIEW, { id: paymentPageCreatedId }) }}
                  target="_blank"
                >
                  <img
                    src={openNewTabIcon}
                    alt='open new tab icon'
                  />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="rp-payment-pages__form__card__group-btn --page-success">
        <RPButton
          grey
          onClick={() => history.replace(ROUTES.APP.PAYMENT_PAGES.INDEX)}
        >
          Back to List
        </RPButton>
        <RPButton
          gradient
          withArrowIcon
          onClick={handleCreateNewPayPage}
        >
          <div className="rp-payment-pages__form__card__submit-btn">
            Create New Payment Page
          </div>
        </RPButton>
      </div>
    </div>
  );
}

SuccessPage.propTypes = {
  data: PropTypes.object,
  paymentPageCreatedId: PropTypes.string,
};

export default SuccessPage;
