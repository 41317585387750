import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
} from 'antd';
import {
  useLocation,
} from 'react-router-dom';

import Sidebar from '@/components/Sidebar';
import HeaderContainer from '@/containers/Header';

import './index.scss';

const { Content } = Layout;

const headers = {
  '/app/dashboard': {
    headerType: 'transparent',
  },
  '/app/activation': {
    headerType: 'white',
  },
  '/app/paymentpages': {
    headerType: 'transparent',
  },
  '/app/transactions': {
    headerType: 'white',
  },
  '/app/paymentlinks': {
    headerType: 'transparent',
  },
  '/app/paymentbuttons': {
    headerType: 'transparent',
  },
};

function AuthLayout({
  children,
  blankPage = false,
}) {
  const location = useLocation();
  const {
    headerType,
  } = headers[location.pathname] || { headerType: 'white' };
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="auth-layout">
      <Layout
        style={{
          minHeight: '100vh',
        }}
        className="user-layout"
      >
        {blankPage && children}
        {!blankPage &&
          <>
            <Sidebar
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
            <Layout className="site-layout">
              <HeaderContainer headerType={headerType} />
              <Content>
                {children}
              </Content>
            </Layout>
          </>
        }
      </Layout>
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element,
  blankPage: PropTypes.bool,
};

export default AuthLayout;
