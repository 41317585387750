import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import { uniqueId } from 'lodash';
import RPButton from '@/components/RPButton';
import logo from '@/assets/images/logo-white.svg';
import { formatter } from '@/utils';
import PreviewInput from './PreviewInput';
import PreviewButtonDetail from './PreviewButtonDetail';

const Step3 = React.memo(({
  data,
  onSubmit,
  clickBack,
  amount,
  labelButton,
  isEdit,
}) => (
  <div>
    <div className="dashboard-kyc__card payment-button__new__preview-full">
      <Row>
        <Col
          flex="50%"
          className="dashboard-kyc__card__left --border-none"
        >
          <p className='payment-button__new__preview-full__title'>
            Customers will see the button and forms as shown below
          </p>
          <div className='payment-button__new__preview__overlay' />
          <PreviewButtonDetail label={labelButton} />
        </Col>
        <Col
          flex="50%"
          className="dashboard-kyc__card__left --border-none"
        >
          <div className='payment-button__new__preview__overlay' />
          <div className="payment-button__new__preview__card">
            <div className="payment-button__new__preview__card__header">
              <img src={logo} alt="icon" width="83px" height="23px" />
              <p className='payment-button__new__preview__card__header--hint'>
                You&apos;ve received a payment request for
              </p>
              <p className='mb-0'>
                {formatter.formatCurrency(Number(amount || 0))}
              </p>
              <Button className="page-header__button__white -custom payment-link__email__button">
                <div className="mb-0 page-header__button__text">Pay Now</div>
                <div className="mb-0 page-header__button__icon" />
              </Button>
            </div>
            <div className='payment-button__new__preview__card__form'>
              {
                data.map(obj => (
                  <PreviewInput
                    key={uniqueId('--preview-input')}
                    type={obj.type}
                    label={obj.label}
                  />
                ))
              }
            </div>
          </div>
        </Col>
      </Row>
    </div>
    <div className="dashboard-kyc__card__group-btn">
      <RPButton
        grey
        onClick={clickBack}
      >
        Back
      </RPButton>
      <RPButton
        gradient
        withArrowIcon
        onClick={onSubmit}
      >
        <div className="dashboard-kyc__card__submit-btn">
          {isEdit ? 'Update Button' : 'Create Button' }
        </div>
      </RPButton>
    </div>
  </div>
));

Step3.propTypes = {
  onSubmit: PropTypes.func,
  clickBack: PropTypes.func,
  data: PropTypes.array,
  amount: PropTypes.string,
  labelButton: PropTypes.string,
  isEdit: PropTypes.bool
};

export default Step3;
