import React, { useState } from 'react';
import {
  Form,
  Spin,
} from 'antd';
import { ROUTES } from '@/constants';
import { signup, resendEmailConfirm, oauthLogin } from '@/services/authService';
import SignUp from '@/components/Auth/SignUp';
import Resend from '@/components/Auth/Resend';

function SignUpPage() {
  const [signupForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const handleSignup = async (values) => {
    setLoading(true);
    const res = await signup({ email: values.email, password: values.password });
    if (res?.status === 200) {
      setIsResend(true);
    }
    setLoading(false);
  };

  const loginOauth = async params => {
    const res = await oauthLogin(params);
    if (res.status === 200) {
      window.location.href = ROUTES.APP.DASHBOARD_PATH;
    }
  };

  const responseFacebook = async response => {
    setLoading(true);
    if (response?.accessToken) {
      const params = {
        provider: 'facebook',
        accessToken: response.accessToken
      };
      await loginOauth(params);
    }
    setLoading(false);
  };

  const responseGoogle = async response => {
    setLoading(true);
    if (response?.accessToken) {
      const params = {
        provider: 'google',
        accessToken: response.accessToken
      };
      await loginOauth(params);
    }
    setLoading(false);
  };

  const resendEmail = async () => {
    await resendEmailConfirm({ email: signupForm.getFieldValue('email') });
  };

  return (
    <Spin spinning={loading}>
      {
        isResend ?
          <Resend
            resendEmail={resendEmail}
            email={signupForm.getFieldValue('email')}
          /> :
          <SignUp
            handleSignup={handleSignup}
            signupForm={signupForm}
            loading={loading}
            responseFacebook={responseFacebook}
            responseGoogle={responseGoogle}
          />
      }
    </Spin>
  );
}

export default SignUpPage;
