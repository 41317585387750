import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import { uniqueId } from 'lodash';

import RPButton from '@/components/RPButton';
import formPaymentPagesIcon from '@/assets/images/payment-pages/form/payment-page-icon.svg';
import {
  availableAdditionalInputFields,
  availableAdditionalPriceFields
} from '@/components/PaymentPages/Step/AvailableAdditionalFields';
import PPInputField from '@/components/PaymentPages/Step/PaymentDetails/PPInputField';
import PriceField from '@/components/PaymentPages/Step/PaymentDetails/PriceField';
import AddFieldModal from '@/components/PaymentPages/Step/AddFieldModal';
import { arrayMove } from '@/utils';
import checkedIcon from '@/assets/images/kyc/checked-icon.svg';
import uncheckedIcon from '@/assets/images/kyc/unchecked-icon.svg';

import '../index.scss';

const listFieldsAvailable = [...availableAdditionalInputFields, ...availableAdditionalPriceFields];

function PaymentDetails({
  data,
  setData,
  onSubmit,
  clickBack,
  setShowExitPrompt,
}) {
  const [formCheck, setFormCheck] = useState([
    { label: 'Amount Type', value: false, id: 'amount_type' },
  ]);
  const [showModalAddField, setShowModalAddField] = useState(false);
  const [paypageFileds, setPaypageFileds] = useState(data.paymentDetails.fields || []);

  const isPriceField = (field) => Object.prototype.hasOwnProperty.call(field, 'amountType') &&
    Object.prototype.hasOwnProperty.call(field, 'amount');

  const isCompleted = () => {
    const priceFields = paypageFileds.filter(f => isPriceField(f));

    return priceFields.filter(el => !el.amountType || (el.amountType === 'fixed-amount' && !el.amount)).length > 0;
  };

  const hideModalAddField = () => {
    setShowModalAddField(false);
  };

  const handleOnClickAddField = () => {
    setShowModalAddField(true);
  };

  const handleAddFiled = (values) => {
    setShowModalAddField(false);
    setShowExitPrompt(true);
    const fieldAdded = listFieldsAvailable.find(el => el.key === values.additionalField);

    if (fieldAdded.fieldType === 'price-field') {
      setPaypageFileds([
        ...paypageFileds,
        {
          label: null,
          dataType: fieldAdded.dataType,
          editing: true,
          deletable: true,
          amountType: fieldAdded.key,
          amount: undefined,
          key: paypageFileds.length - 1,
          editable: true,
        }
      ]);
    }

    if (fieldAdded.fieldType === 'input-field') {
      setPaypageFileds([
        ...paypageFileds,
        {
          label: null,
          dataType: fieldAdded.dataType,
          editing: true,
          deletable: true,
          type: values.additionalField,
          key: paypageFileds.length - 1,
          editable: true,
        }
      ]);
    }
  };

  const validPriceField = field =>
    field.amountType === 'fixed-amount' &&
    field.amount > 0 ||
    field.amountType === 'customers-decide-amount';

  const onUpdate = (label, index, amount = undefined, amountType = null) => {
    if (isPriceField(paypageFileds[index])) {
      paypageFileds[index] = {
        ...paypageFileds[index],
        label,
        amount,
        amountType,
        editing: false,
      };

      const formChecked = formCheck.map(check => ({
        ...check,
        value: validPriceField(paypageFileds[index]),
      }));
      setFormCheck(formChecked);
    } else {
      paypageFileds[index] = { ...paypageFileds[index], label, editing: false };
    }

    setPaypageFileds([...paypageFileds]);
  };

  const handleOnDeleteAdditionalField = (index) => {
    paypageFileds.splice(index, 1);
    setPaypageFileds([...paypageFileds]);
  };

  const onCancelAddField = (index) => {
    const { deletable, label } = paypageFileds[index];

    if (!label && !!deletable) {
      paypageFileds.splice(index, 1);
      setPaypageFileds([...paypageFileds]);
    }
  };

  const handleOnSubmit = () => {
    setData({ ...data, paymentDetails: { fields: [...paypageFileds] } });
    onSubmit();
  };

  const handleClickBack = () => {
    setData({ ...data, paymentDetails: { fields: [...paypageFileds] } });
    clickBack();
  };

  const handleMoveUp = (currentIndex) => {
    setShowExitPrompt(true);
    const result = arrayMove(paypageFileds, currentIndex, currentIndex - 1);
    setPaypageFileds([...result]);
  };

  const handleMoveDown = (currentIndex) => {
    setShowExitPrompt(true);
    const result = arrayMove(paypageFileds, currentIndex, currentIndex + 1);
    setPaypageFileds([...result]);
  };

  useEffect(() => {
    const fields = [...data.paymentDetails.fields];

    setPaypageFileds(fields);
    fields.every((field) => {
      if (isPriceField(field)) {
        const formChecked = formCheck.map(check => ({
          ...check,
          value: validPriceField(field),
        }));
        setFormCheck(formChecked);
        return !validPriceField(field);
      };
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderInputOrPriceField = (field, index) => {
    const {
      label,
      amount,
      editing,
      deletable,
      editable,
      amountType,
    } = field;
    const isFirstItem = index === 0;
    const isLastItem = index === paypageFileds.length - 1;

    if (isPriceField(field)) {
      return (
        <PriceField
          label={label}
          index={index}
          amount={amount ? parseFloat(amount) : null}
          isEditing={editing}
          onUpdate={onUpdate}
          deletable={deletable}
          amountType={amountType}
          onMoveUp={handleMoveUp}
          hideMoveUp={isFirstItem}
          hideMoveDown={isLastItem}
          onCancel={onCancelAddField}
          onMoveDown={handleMoveDown}
          onDelete={handleOnDeleteAdditionalField}
          key={uniqueId('--additional-price-field')}
        />
      );
    }

    return (
      <PPInputField
        index={index}
        label={label}
        editable={editable}
        isEditing={editing}
        onUpdate={onUpdate}
        deletable={deletable}
        onMoveUp={handleMoveUp}
        hideMoveUp={isFirstItem}
        hideMoveDown={isLastItem}
        onCancel={onCancelAddField}
        onMoveDown={handleMoveDown}
        onDelete={handleOnDeleteAdditionalField}
        key={uniqueId('--additional-input-field')}
      />
    );
  };

  return (
    <div>
      <div
        className='rp-payment-pages__form__card --payment-details'
        id='new-rp-payment-page'
      >
        <Row>
          <Col flex='50%' className='rp-payment-pages__form__card__left'>
            <img
              src={formPaymentPagesIcon}
              alt='icon'
              width='32px'
              height='32px'
            />
            <h2 className='rp-payment-pages__form__card__left__title'>Payment Page Details</h2>
            <p
              className='rp-payment-pages__form__card__left__desc'
            >
              Provide the relevant information in this form to continue creating your new payment page.
            </p>
            <p className="rp-payment-pages__form__card__left__desc">Form Checklist</p>
            {
              formCheck.map((item) => (
                <div
                  className="rp-payment-pages__form__card__left__desc --group"
                  key={uniqueId('form-check-')}
                >
                  <img
                    src={item.value ? checkedIcon : uncheckedIcon}
                    alt="icon"
                    width="24px"
                    height="24px"
                  />
                  {item.label}
                </div>
              ))
            }
          </Col>
          <Col flex='50%' className='rp-payment-pages__form__card__right' id='rp-pp-fc-right'>
            {paypageFileds.length > 0 &&
              paypageFileds.map((field, index) => renderInputOrPriceField(field, index))
            }
            <div className='add-field-container'>
              <RPButton
                grey
                onClick={handleOnClickAddField}
              >
                Add Field
              </RPButton>
            </div>
          </Col>
        </Row>

        <AddFieldModal
          onAddField={handleAddFiled}
          visible={showModalAddField}
          hideModalAddField={hideModalAddField}
        />
      </div>
      <div className='rp-payment-pages__form__card__group-btn'>
        <RPButton
          grey
          onClick={handleClickBack}
        >
          Back
        </RPButton>
        <RPButton
          gradient
          withArrowIcon
          onClick={handleOnSubmit}
          disabled={isCompleted()}
        >
          <div className='rp-payment-pages__form__card__submit-btn'>
            Next
          </div>
        </RPButton>
      </div>
    </div >
  );
}

PaymentDetails.propTypes = {
  setShowExitPrompt: PropTypes.func,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  clickBack: PropTypes.func.isRequired,
};

export default PaymentDetails;
