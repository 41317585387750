/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Result
} from 'antd';
import PropTypes from 'prop-types';
import { errorMessages, ROUTES } from '@/constants';
import RPButton from '@/components/RPButton';
import maskBg from '@/assets/images/login-register/mask.svg';
import { validatePassword } from '@/utils/validate';
import './forgotPassword.scss';

function UpdatePassword({
  handle,
  error,
  success,
  loading,
}) {
  return (
    <div className='forgot-password'>
      <Row>
        <Col
          span={12}
          className='forgot-password__left'
        >
          <div className='forgot-password__left__container'>
            <div className='forgot-password__left__content'>
              <div
                className={`forgot-password__left__content__title ${error || success ? '--text-center' : ''}`}
              >
                {success ? 'Password Reset' : 'Update Password'}
              </div>
              {!error && !success && (
                <div className='forgot-password__left__content__desc'>Enter and repeat your new password. Your password must be at least 8 characters, contain at least 1 uppercase, lowercase, and special character.</div>
              )}
              {
                error && (
                  <Result
                    status="error"
                    subTitle="Token is invalid or has been expired"
                  />
                )
              }
              {
                success && (
                  <Result
                    status="success"
                    subTitle="Your password has been reset successfully"
                  />
                )
              }
            </div>
            {
              !error && !success && (
                <div className='form'>
                  <Form
                    name='reset-password'
                    layout='vertical'
                    onFinish={handle}
                  >
                    <Form.Item
                      label={
                        <div className='form-item-label --custom'>
                          <div>New Password</div>
                        </div>
                      }
                      name='password'
                      rules={[
                        { required: true, message: 'Password is required' },
                        () => ({
                          validator(_, value) {
                            if (value && !validatePassword(value)) {
                              return Promise.reject(new Error(errorMessages.invalidPassword));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder='New Password'
                        className='form-item-input-password'
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <div className='form-item-label --custom'>
                          <div>Confirm New Password</div>
                        </div>
                      }
                      name='confirmPassword'
                      rules={[
                        { required: true, message: 'Confirm Password is required' },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Confirm New Password do not match with New Password!'));
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder='Confirm New Password'
                        className='form-item-input-password'
                      />
                    </Form.Item>
                    <div className='form-btn-login'>
                      <RPButton
                        danger
                        htmlType='submit'
                        className='btn-submit'
                        loading={loading}
                      >
                        Update Password
                      </RPButton>
                    </div>
                  </Form>
                </div>
              )
            }
          </div>

          <div className='actions'>
            {!error && !success &&
              <>
                <div>Already have an account?</div>
                <Link to={ROUTES.LOGIN_PATH}>Sign In</Link>
              </>
            }
            {(error || success) &&
              <Link to={ROUTES.LOGIN_PATH}>Return To Sign In</Link>
            }
          </div>
        </Col>
        <Col
          span={12}
          className='forgot-password__right'
        >
          <div className='forgot-password__right__container'>
            <div className='forgot-password__right__super-title'>Supercharge</div>
            <div className='forgot-password__right__desc'>your business today</div>
          </div>
          <div className='forgot-password__right__image'>
            <img
              src={maskBg}
              alt='mask bg'
              width='100%'
              height='100%'
            />
          </div>
        </Col>
      </Row>

    </div>
  );
}
UpdatePassword.propTypes = {
  handle: PropTypes.func,
  error: PropTypes.bool,
  success: PropTypes.bool,
  loading: PropTypes.bool,
};

export default UpdatePassword;
