import React, { useEffect } from 'react';
import { Form } from 'antd';
import RPInput from '@/components/RPInput';
import RPButton from '@/components/RPButton';
import PropTypes from 'prop-types';
import './index.scss';

function ContentDisplayName({ onSubmit, data, form }) {
  useEffect(() => {
    form.setFieldsValue(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div className="my-account__profile__modal__display-name">
      <p>
        This is the display name that you and your team will see on the
        RadicalPay dashboard.
      </p>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        noValidate
        onFinish={onSubmit}
        initialValues={data}
      >
        <Form.Item
          label=""
          name="displayName"
          rules={[
            { required: true, message: 'Please input Display Name' },
          ]}
          wrapperCol={{ span: 24 }}
        >
          <RPInput
            name="displayName"
            placeholder="Display Name"
            type="displayName"
            label="Display Name"
          />
        </Form.Item>
        <RPButton
          className="my-account__profile__modal__submit-btn"
          gradient
          center
          htmlType="submit"
        >
          Update
        </RPButton>
      </Form>
    </div>
  );
}

ContentDisplayName.propTypes = {
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  form: PropTypes.any,
};

export default ContentDisplayName;
