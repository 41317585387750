import React from 'react';
import PropTypes from 'prop-types';
import logo from '@/assets/images/logo-transparent.svg';

function Preview({ label }) {

  return (
    <div className="payment-button__new__preview__button">
      <div className="payment-button__new__preview__button__logo">
        <img src={logo} alt="icon" width="20px" height="30px" />
      </div>
      <div className="payment-button__new__preview__button__label">
        <p>{label || 'Pay Now'}</p>
        <div className="payment-button__new__preview__button__icon" />
      </div>
    </div>
  );
}
Preview.propTypes = {
  label: PropTypes.string,
};

export default Preview;
