/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
// import { SketchPicker } from 'react-color';
// import RPInput from '@/components/RPInput';
import UploadImage from '@/components/UploadImage';
import PropTypes from 'prop-types';
import './index.scss';
import { getCompanyDetail, updateThemeColor } from '@/services/myAccountService';

function Company({
  uploadFile,
  defaultSrc
}) {
  const [background, setBackground] = useState('#0031AF');
  // const handleChangeComplete = async (color) => {
  //   setBackground(color);
  // };
  const [isSelectColor, setIsSelectColor] = useState(false);
  const [isSaveColor, setIsSaveColor] = useState(false);

  // const onSelectColor = () => {
  //   setIsSaveColor(true);
  //   setIsSelectColor(!isSelectColor);
  // };

  const handleClosePicker = () => {
    setIsSelectColor(false);
  };

  useEffect(() => {
    const updateColor = async (bg) => {
      await updateThemeColor({ themeColor: bg });
    };
    if (!isSelectColor && isSaveColor) {
      updateColor(background);
      setIsSaveColor(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectColor, isSaveColor]);

  const initData = async () => {
    const res = await getCompanyDetail();
    if (res?.status === 200 ) {
      setBackground(res.data?.themeColor || '#FFFFFF');
    }
  };

  useEffect(() => {
    initData();
  }, []);
  return (
    <div className="my-account__company">
      <div
        className='my-account__company__cover'
        onClick={() => handleClosePicker()}
      />
      {/* <p>Theme Colour</p>
      <div className='my-account__company__input'>
        <RPInput
          value={background}
        />
        <div
          onClick={() => onSelectColor()}
          className='my-account__company__input__tag-color'
          style={{ '--bg-color': background }}
        />
        <SketchPicker
          className={`my-account__company__input__picker ${isSelectColor ? '--show' : ''}`}
          color={background}
          onChange={(color) => {
            handleChangeComplete(color.hex);
          }}
        />
        <p className='my-account__company__input__hint'>
          Select a shade that corresponds with your brand. If none is selected, the default colour will be applied.
        </p>
      </div> */}
      <p>Company Logo</p>
      <UploadImage
        uploadFile={uploadFile}
        defaultSrc={defaultSrc}
      />
    </div>
  );
}

Company.propTypes = {
  uploadFile: PropTypes.func,
  defaultSrc: PropTypes.string
};

export default Company;
