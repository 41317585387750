import React from 'react';
import PaymentPagesContainer from '@/containers/PaymentPage';
import './index.scss';

function PaymentPagesPage() {
  return (
    <PaymentPagesContainer />
  );
}

export default PaymentPagesPage;
