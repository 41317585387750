export const OTPActions = {
  OTPActionLogin: 'login',
  OTPActionChangeContactNumber: 'change_contact_number',
  OTPActionChangeMFA: 'change_mfa',
};

export const OTPDeliveryMethod = {
  OTPDeliveryMethodEmail: 'email',
  OTPDeliveryMethodSMS: 'sms',
};
