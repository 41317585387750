import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import deleteIcon from '@/assets/images/fileupload/delete-icon.svg';
import uploadSuccessIcon from '@/assets/images/fileupload/pdf-icon.svg';

function UploadDone({
  name,
  fileSize,
  remove,
  file,
}) {
  const [preview, setPreview] = useState();
  const getBase64 = (fileObj) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileObj);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const getImagePreview = async fileObj => {
    if (['image/jpeg', 'image/png'].includes(file.type)) {
      if (!fileObj.url && !fileObj.preview) {
        fileObj.preview = await getBase64(fileObj.originFileObj);
      }

      setPreview(fileObj.url || fileObj.preview);
    }
  };
  useEffect(() => {
    getImagePreview(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const renderUploadProcessingTitle = () => {
    let title = name.toUpperCase();

    if (fileSize) {
      title = `${title} (${fileSize})`;
    }

    return <div className="item-name">{title}</div>;
  };

  return (
    <div className="rp-upload-file-done">
      <div className="upload-list-item-info">
        <div className="left-hand">
          <p className="upload-status">Upload Successful</p>
          <div className="under-status-title">
            {renderUploadProcessingTitle()}
            <button
              type='button'
              className="delete"
              onClick={remove}
            >
              <img
                src={deleteIcon}
                alt="delete icon"
                width={17}
                height={18}
              />
            </button>
          </div>
        </div>

        <div className="right-hand">
          <img
            src={preview || uploadSuccessIcon}
            alt="upload icon"
            width={48}
            height={48}
          />
        </div>
      </div>
    </div>
  );
};

UploadDone.propTypes = {
  name: PropTypes.string,
  fileSize: PropTypes.string,
  remove: PropTypes.func,
  file: PropTypes.object,
};

export default UploadDone;
