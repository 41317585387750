import { ROUTES } from '@/constants';
import dbIcon from '@/assets/images/sidebar/db-icon.svg';
import dbIconActive from '@/assets/images/sidebar/db-icon-active.svg';
import transIcon from '@/assets/images/sidebar/trans-icon.svg';
import transIconActive from '@/assets/images/sidebar/trans-icon-active.svg';
import paylinkIcon from '@/assets/images/sidebar/paylink-icon.svg';
import paylinkIconActive from '@/assets/images/sidebar/paylink-icon-active.svg';
import paypageIcon from '@/assets/images/sidebar/paypage-icon.svg';
import paypageIconActive from '@/assets/images/sidebar/paypage-icon-active.svg';
import paybuttonIcon from '@/assets/images/sidebar/paybutton-icon.svg';
import paybuttonIconActive from '@/assets/images/sidebar/paybutton-icon-active.svg';

export const menus = [
  {
    label: 'Dashboard',
    icon: dbIcon,
    activeIcon: dbIconActive,
    path: ROUTES.APP.DASHBOARD_PATH,
    pathsActive: [ROUTES.APP.DASHBOARD_PATH],
  },
  {
    label: 'Transactions',
    icon: transIcon,
    activeIcon: transIconActive,
    path: ROUTES.APP.TRANSACTION_PATH,
    pathsActive: [
      ROUTES.APP.TRANSACTION_SHOW_PATH,
      ROUTES.APP.TRANSACTION_PATH,
    ],
  },
  {
    label: 'Payment Links',
    icon: paylinkIcon,
    activeIcon: paylinkIconActive,
    path: ROUTES.APP.PAYMENT_LINKS.INDEX,
    pathsActive: [
      ROUTES.APP.PAYMENT_LINKS.SHOW,
      ROUTES.APP.PAYMENT_LINKS.INDEX,
    ],
  },
  {
    label: 'Payment Pages',
    icon: paypageIcon,
    activeIcon: paypageIconActive,
    path: ROUTES.APP.PAYMENT_PAGES.INDEX,
    pathsActive: [
      ROUTES.APP.PAYMENT_PAGES.SHOW,
      ROUTES.APP.PAYMENT_PAGES.INDEX,
    ],
  },
  {
    label: 'Payment Buttons',
    icon: paybuttonIcon,
    activeIcon: paybuttonIconActive,
    path: ROUTES.APP.PAYMENT_BUTTONS.INDEX,
    pathsActive: [
      ROUTES.APP.PAYMENT_BUTTONS.INDEX,
    ]
  }
];
