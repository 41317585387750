/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Select, Space } from 'antd';
import { uniqueId } from 'lodash';

import RPTextArea from '@/components/RPTextArea';
import RPRadio from '@/components/RPRadio';
import RPButton from '@/components/RPButton';
import RPSelect from '@/components/RPSelect';
import checkedIcon from '@/assets/images/kyc/checked-icon.svg';
import uncheckedIcon from '@/assets/images/kyc/unchecked-icon.svg';
import kycBoverIcon from '@/assets/images/kyc/kyc-bover-icon.svg';
import RPCheckbox from '@/components/RPCheckbox';
import RPInput from '@/components/RPInput';
import { isValidHttpUrl } from '@/utils/validate';
import {
  BusinessTypesEnum,
  BusinessCategoriesEnum
} from '@/constants/kyc';

const { Option } = Select;

const radioOptions = [
  { value: false, label: 'Without website/ App' },
  { value: true, label: 'On my website/ App' }
];

function BusinessOver({
  clickBack,
  clickNext,
  onSaveAndExit,
  businessOverview,
  saveAndExitLoading,
  setBusinessOverview,
  readOnly
}) {
  const [formCheck, setFormCheck] = useState([
    { label: 'What\'s your Business Type', value: false, id: 'type' },
    { label: 'Select Your Business Category', value: false, id: 'category' },
    { label: 'Your Business Details', value: false, id: 'description' },
    { label: 'Payment Collection', value: false, id: 'payment-collection' },
  ]);
  const hasUrl = !!businessOverview.acceptPaymentWebsiteUrl || !!businessOverview.acceptPaymentAppUrl;
  const [acceptPaymentsOnApp, setAcceptPaymentsOnApp] = useState(!!businessOverview.acceptPaymentAppUrl);
  const [acceptPaymentsOnWebsite, setAcceptPaymentsOnWebsite] = useState(!!businessOverview.acceptPaymentWebsiteUrl);
  const [collectPaymentViaWebOrApp, setCollectPaymentViaWebOrApp] = useState(hasUrl);

  const onChange = e => {
    setBusinessOverview({
      ...businessOverview,
      [e.target.name]: e.target.value
    });
  };

  const onChangeType = value => {
    setBusinessOverview({
      ...businessOverview,
      type: value,
    });
  };

  const onChangeCategory = value => {
    setBusinessOverview({
      ...businessOverview,
      category: value
    });
  };

  useEffect(() => {
    const formChecked = formCheck.map(check => {
      if (check.id === 'payment-collection') {
        let valid = !!businessOverview.acceptPaymentWebsiteUrl || !!businessOverview.acceptPaymentAppUrl ||
          (!businessOverview.acceptPaymentWebsiteUrl && !businessOverview.acceptPaymentAppUrl);
        if (collectPaymentViaWebOrApp) {
          if (acceptPaymentsOnWebsite) {
            valid = !!businessOverview.acceptPaymentWebsiteUrl && isValidHttpUrl(businessOverview.acceptPaymentWebsiteUrl);
          }
          if (acceptPaymentsOnApp) {
            valid = !!businessOverview.acceptPaymentAppUrl && isValidHttpUrl(businessOverview.acceptPaymentAppUrl);
          }
        }
        return ({
          ...check,
          value: valid
        });
      }
      let value = businessOverview[check.id];
      if (typeof value === 'string') {
        value = value.trim();
      }
      return ({
        ...check,
        value: !!value,
      });
    });

    setFormCheck(formChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessOverview, collectPaymentViaWebOrApp, acceptPaymentsOnWebsite, acceptPaymentsOnApp]);

  const isCompleted = () => formCheck.filter(f => !f.value).length > 0;


  const handleOnChangePaymenCollect = (e) => {
    setCollectPaymentViaWebOrApp(!collectPaymentViaWebOrApp);

    // Select 'Without website/app' option
    if (!e.target.value) {
      setAcceptPaymentsOnWebsite(false);
      setAcceptPaymentsOnApp(false);

      setBusinessOverview({
        ...businessOverview,
        acceptPaymentWebsiteUrl: '',
        acceptPaymentAppUrl: '',
      });
    }
  };

  const handleOnChangeAcceptPaymentsOnWebsite = (e) => {
    setAcceptPaymentsOnWebsite(!acceptPaymentsOnWebsite);

    if (!e.target.checked) {
      setBusinessOverview({
        ...businessOverview,
        acceptPaymentWebsiteUrl: '',
      });
    }
  };

  const handleOnChangeAcceptPaymentsOnApp = (e) => {
    setAcceptPaymentsOnApp(!acceptPaymentsOnApp);

    if (!e.target.checked) {
      setBusinessOverview({
        ...businessOverview,
        acceptPaymentAppUrl: '',
      });
    }
  };

  return (
    <div>
      <div className="dashboard-kyc__card">
        <Row>
          <Col flex="50%" className="dashboard-kyc__card__left">
            <img
              src={kycBoverIcon}
              alt="icon"
              width="34px"
              height="42px"
            />
            <h2 className="dashboard-kyc__card__left__title">Business Overview</h2>
            <p className="dashboard-kyc__card__left__desc">Form Checklist</p>
            {
              formCheck.map((data) => (
                <div
                  key={uniqueId('form-check-')}
                  className="dashboard-kyc__card__left__desc --group"
                >
                  <img
                    src={data.value ? checkedIcon : uncheckedIcon}
                    alt="icon"
                    width="24px"
                    height="24px"
                  />
                  {data.label}
                </div>
              ))
            }
          </Col>
          <Col flex="50%" className="dashboard-kyc__card__right">
            <Form.Item
              name={['businessOverview', 'type']}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your type' }]}
            >
              <RPSelect
                placeholder="Business Type"
                label="Business Type"
                name="type"
                className="dashboard-kyc__card__right__input --custom w-100"
                showSearch
                required
                onChange={onChangeType}
                disabled={readOnly}
              >
                {
                  BusinessTypesEnum.map(({ text, value }) => (
                    <Option
                      key={uniqueId('business-type-option-')}
                      value={value}
                    >
                      {text}
                    </Option>
                  ))
                }
              </RPSelect>
            </Form.Item>
            <Form.Item
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your categories' }]}
              name={['businessOverview', 'category']}
            >
              <RPSelect
                placeholder="Business Categories"
                label="Business Categories"
                className="dashboard-kyc__card__right__input --custom w-100"
                showSearch
                name="category"
                required
                onChange={onChangeCategory}
                disabled={readOnly}
              >
                {
                  BusinessCategoriesEnum.map(({ text, value }) => (
                    <Option
                      key={uniqueId('category-option-')}
                      value={value}
                    >
                      {text}
                    </Option>
                  ))
                }
              </RPSelect>
            </Form.Item>
            <Form.Item
              name={['businessOverview', 'description']}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your description' }]}
            >
              <RPTextArea
                placeholder="Business Description"
                label="Business Description"
                rows={4}
                required
                name="description"
                onChange={onChange}
                disabled={readOnly}
              />
            </Form.Item>
            <p
              className="mt-2 dashboard-kyc__card__right__text-hint"
            >
              Please give a brief description of the nature of your business. Please include examples of products you sell, the business category you operate under, your customers and the channels you primarily use to conduct your business(Website, offline retail etc).
            </p>
            <p>How would you like to collect your payment?</p>

            <div className="dashboard-kyc__card__right__collect-payment-options">
              <RPRadio
                value={collectPaymentViaWebOrApp}
                options={radioOptions}
                onChange={handleOnChangePaymenCollect}
                disabled={readOnly}
              />
            </div>

            {!!collectPaymentViaWebOrApp &&
              <div className="dashboard-kyc__card__right__accept-collect-payment">
                <RPCheckbox
                  onChange={handleOnChangeAcceptPaymentsOnWebsite}
                  checked={acceptPaymentsOnWebsite}
                  disabled={readOnly}
                >
                  Accept payments on Website
                </RPCheckbox>
                {!!acceptPaymentsOnWebsite &&
                  <Form.Item
                    wrapperCol={{ span: 24 }}
                    name={['businessOverview', 'acceptPaymentWebsiteUrl']}
                    rules={[
                      {
                        required: !!acceptPaymentsOnWebsite,
                        message: 'Please input your url'
                      },
                      () => ({
                        validator(_, value) {
                          if (value && !isValidHttpUrl(value)) {
                            return Promise.reject(new Error('Please input the valid url'));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <RPInput
                      className="dashboard-kyc__card__right__input --custom"
                      placeholder="Enter URL"
                      label=""
                      required
                      name="acceptPaymentWebsiteUrl"
                      onChange={onChange}
                      disabled={readOnly}
                    />
                  </Form.Item>
                }

                <RPCheckbox
                  onChange={handleOnChangeAcceptPaymentsOnApp}
                  checked={acceptPaymentsOnApp}
                  disabled={readOnly}
                >
                  Accept payments on app
                </RPCheckbox>
                {!!acceptPaymentsOnApp &&
                  <Form.Item
                    wrapperCol={{ span: 24 }}
                    name={['businessOverview', 'acceptPaymentAppUrl']}
                    rules={[
                      {
                        required: !!acceptPaymentsOnWebsite,
                        message: 'Please input your app link'
                      },
                      () => ({
                        validator(_, value) {
                          if (value && !isValidHttpUrl(value)) {
                            return Promise.reject(new Error('Please input the valid app link'));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <RPInput
                      className="dashboard-kyc__card__right__input --custom"
                      placeholder="Enter App Link"
                      label=""
                      required
                      name="acceptPaymentAppUrl"
                      onChange={onChange}
                      disabled={readOnly}
                    />
                  </Form.Item>
                }
              </div>
            }
          </Col>
        </Row>
      </div>
      <div className="dashboard-kyc__card__group-btn">
        <RPButton
          grey
          disabled={saveAndExitLoading}
          onClick={clickBack}
        >
          Back
        </RPButton>
        <Space>
          {
            !readOnly && <RPButton
              grey
              disabled={saveAndExitLoading || isCompleted()}
              onClick={onSaveAndExit}
              loading={saveAndExitLoading}
            >
              <div className="dashboard-kyc__card__submit-btn">
                Save & Exit
              </div>
            </RPButton>
          }


          <RPButton
            gradient
            withArrowIcon
            onClick={clickNext}
            disabled={saveAndExitLoading || isCompleted()}
          >
            <div className="dashboard-kyc__card__submit-btn">
              Next
            </div>
          </RPButton>
        </Space>
      </div>
    </div>
  );
}

BusinessOver.propTypes = {
  clickBack: PropTypes.func,
  clickNext: PropTypes.func,
  onSaveAndExit: PropTypes.func,
  saveAndExitLoading: PropTypes.bool,
  businessOverview: PropTypes.object.isRequired,
  setBusinessOverview: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

export default BusinessOver;
