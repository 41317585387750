/* eslint-disable max-len */
/* eslint-disable no-continue */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Button,
  Popover,
} from 'antd';
import { SelectOutlined } from '@ant-design/icons';
import { uniqueId } from 'lodash';
import { useHistory, Link } from 'react-router-dom';

import { ROUTES } from '@/constants';
import { validateEmail, validatePhoneNumber } from '@/utils/validate';
import RPInput from '@/components/RPInput';
import RPButton from '@/components/RPButton';
import RPTextArea from '@/components/RPTextArea';
import checkedIcon from '@/assets/images/kyc/checked-icon.svg';
import uncheckedIcon from '@/assets/images/kyc/unchecked-icon.svg';
import formPaymentPagesIcon from '@/assets/images/payment-pages/form/payment-page-icon.svg';
import RPPhoneNumber from '@/components/RPPhoneNumber';
import { getAuthUser } from '@/utils/localStorageUtil';

import './index.scss';

function PageDetails({
  form,
  data,
  setForm,
  setData,
  onSubmit,
  setShowExitPrompt,
}) {
  const [formInstance] = Form.useForm();
  const history = useHistory();
  const tacInputRef = useRef(null);
  const [formCheck, setFormCheck] = useState([
    { label: 'Page Title', value: false, id: 'title' },
    { label: 'Page Description', value: false, id: 'description' },
    { label: 'Support Email', value: false, id: 'supportEmail' },
    { label: 'Support Contact Number', value: false, id: 'supportContactNumber' },
  ]);
  const [showTacInput, setShowTacInput] = useState(false);

  const onFinish = (values) => {
    setData({ ...data, pageDetails: { ...values } });
    onSubmit();
  };

  const onChange = (event) => {
    setShowExitPrompt(true);
    if (event.target.name !== 'supportContactNumber') {
      setForm({
        ...form,
        [event.target.name]: event.target.value
      });
    }
  };

  const handleOnChangePhoneNumber = (phone) => {
    setShowExitPrompt(true);
    setForm({
      ...form,
      supportContactNumber: phone
    });
  };

  const getValueFormCheck = (label, value) => {
    switch (label) {
      case 'supportEmail':
        return (!!value && validateEmail(value));
      case 'supportContactNumber':
        return (!!value && validatePhoneNumber(value));
      default:
        return !!value;
    }
  };

  useEffect(() => {
    const formChecked = formCheck.map(check => ({
      ...check,
      value: getValueFormCheck(check.id, form[check.id]),
    }));
    setFormCheck(formChecked);
    setShowTacInput(!!form.termAndConditions);
    formInstance.setFieldsValue(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleAddTermAndCondition = () => {
    setShowTacInput(true);
    tacInputRef.current?.focus();
  };

  const handleOnClickBack = () => {
    history.replace(ROUTES.APP.PAYMENT_PAGES.INDEX);
  };

  const handleOnBlurTac = (event) => {
    if (!event.target.value) {
      setShowTacInput(false);
    }
  };

  const isCompleted = () => formCheck.filter(f => !f.value).length > 0;

  const { merchant } = getAuthUser();
  const [merchantLogoUrl] = useState(merchant?.companyLogoUrl);

  return (
    <Form
      initialValues={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      onFinish={onFinish}
      onChange={onChange}
      noValidate
      form={formInstance}
    >
      <div
        className="rp-payment-pages__form__card --page-details"
        id='new-rp-payment-page'
      >
        <Row>
          <Col flex="50%" className="rp-payment-pages__form__card__left">
            <img
              src={formPaymentPagesIcon}
              alt="icon"
              width="32px"
              height="32px"
            />
            <h2 className="rp-payment-pages__form__card__left__title">Payment Page Details</h2>
            <p
              className="rp-payment-pages__form__card__left__desc"
            >
              Provide the relevant information in this form to continue creating your new payment page.
            </p>
            <p className="rp-payment-pages__form__card__left__desc">Form Checklist</p>
            {
              formCheck.map((item) => (
                <div
                  className="rp-payment-pages__form__card__left__desc --group"
                  key={uniqueId('form-check-')}
                >
                  <img
                    src={item.value ? checkedIcon : uncheckedIcon}
                    alt="icon"
                    width="24px"
                    height="24px"
                  />
                  {item.label}
                </div>
              ))
            }
          </Col>
          <Col flex="50%" className="rp-payment-pages__form__card__right">
            <div className='rp-payment-pages__form__card__merchant-container'>
              <div
                className='merchant-logo'
                style={{
                  '--merchant-logo': merchantLogoUrl ? `url("${merchantLogoUrl}")` : 'url("/assets/images/payment-pages/form/logo-sample.svg")'
                }}
              >
                {!merchantLogoUrl && <Popover
                  placement="bottom"
                  trigger="click"
                  getPopupContainer={() => document.getElementById('new-rp-payment-page')}
                  content={
                    <div className='popover-content'>
                      <p className='my-0'>
                        <span>Add your logo for better conversions through the &nbsp;</span>
                        <Link to={`${ROUTES.APP.MY_ACCOUNT.PATH}/company`} target="_blank">
                          my account page <SelectOutlined rotate={90} /></Link>.
                      </p>
                      <p className='my-0'>It can also be uploaded after completing this page.</p>
                    </div>
                  }
                >
                  <Button
                    type="link"
                    className='add-logo'
                  >
                    ADD LOGO
                  </Button>
                </Popover>}
              </div>
              <div className='merchant-name'>{merchant?.name || 'Business Name'}</div>
            </div>
            <Form.Item
              name="title"
              rules={[
                { required: true, message: 'Please input your Page Title' },
              ]}
              wrapperCol={{ span: 24 }}
            >
              <RPInput
                name="title"
                className="rp-payment-pages__form__card__right__input --custom"
                label="Page Title"
                placeholder="Page Title"
                required
              />
            </Form.Item>

            <Form.Item
              name="description"
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your Page Description' }]}
            >
              <RPTextArea
                placeholder="Page Description"
                label="Page Description"
                rows={1}
                required
                name="description"
              />
            </Form.Item>

            <Form.Item
              name="supportEmail"
              rules={[
                {
                  required: true,
                  message: 'Please input your Support Email'
                },
                () => ({
                  validator(_, value) {
                    if (value && !validateEmail(value)) {
                      return Promise.reject(new Error('Support Email Invalid'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              wrapperCol={{ span: 24 }}
            >
              <RPInput
                name="supportEmail"
                className="rp-payment-pages__form__card__right__input --custom"
                label="Support Email"
                placeholder="Support Email"
                required
              />
            </Form.Item>

            <Form.Item
              name="supportContactNumber"
              rules={[
                {
                  required: true,
                  message: 'Please input your Support Contact Number'
                },
                () => ({
                  validator(_, value) {
                    if (value && !validatePhoneNumber(value)) {
                      return Promise.reject(new Error('Support Contact Number Number Invalid'));
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              wrapperCol={{ span: 24 }}
            >
              <RPPhoneNumber
                name="supportContactNumber"
                className="rp-payment-pages__form__card__right__input --custom"
                specialLabel="Support Contact Number"
                placeholder="Support Contact Number"
                required
                inputProps={{
                  name: 'supportContactNumber',
                  required: true,
                }}
                onChange={handleOnChangePhoneNumber}
              />
            </Form.Item>

            <div className='term-and-condition'>
              <div className='term-and-condition__title'>Terms and Conditions</div>
              <div className='term-and-condition__desc'>
                You agree to share information entered on this page with sachin (owner of this page) and Razorpay, adhering to applicable laws.
              </div>
              {!showTacInput &&
                <Button
                  type='link'
                  className='term-and-condition__btn-add-tac'
                  onClick={handleAddTermAndCondition}
                >
                  Add your own terms and conditions
                </Button>
              }

              {!!showTacInput &&
                <Form.Item
                  name="termAndConditions"
                  wrapperCol={{ span: 24 }}
                  className="tac-input-wrapper"
                >
                  <RPTextArea
                    autoFocus
                    placeholder="Terms and Conditions"
                    label="Terms and Conditions"
                    rows={4}
                    name="termAndConditions"
                    ref={tacInputRef}
                    onBlur={handleOnBlurTac}
                  />
                </Form.Item>
              }
            </div>
          </Col>
        </Row>
      </div>
      <div className="rp-payment-pages__form__card__group-btn">
        <RPButton
          grey
          onClick={handleOnClickBack}
        >
          Back
        </RPButton>
        <RPButton
          gradient
          withArrowIcon
          htmlType="submit"
          disabled={isCompleted()}
        >
          <div className="rp-payment-pages__form__card__submit-btn">
            Next
          </div>
        </RPButton>
      </div>
    </Form>
  );
}

PageDetails.propTypes = {
  form: PropTypes.object,
  data: PropTypes.object,
  setForm: PropTypes.func,
  onSubmit: PropTypes.func,
  setData: PropTypes.func,
  setShowExitPrompt: PropTypes.func,
};

export default PageDetails;
