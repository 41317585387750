import React from 'react';
import {
  Row,
  Col,
  Space,
  Button,
  Skeleton,
} from 'antd';
import {
  useHistory
} from 'react-router-dom';

import RPButton from '@/components/RPButton';
import accountActivatedCheckIcon from '@/assets/images/dashboard/account-activated-check-icon.svg';

import './index.scss';

function PageHeader({
  btn,
  title,
  title1,
  title2,
  titleBtn,
  className,
  description,
  leftBtns,
  rightBtns,
  kycPath,
  loading,
  withImage,
  supportElementImageNode,
}) {
  const history = useHistory();

  const renderHeaderSectionWithBgImage = () => {

    const handleOnClick = () => {
      if (!kycPath) {
        return;
      }

      history.replace(kycPath);
    };

    return (
      <div
        className={`page-header__container background-linear-gradient ${className}`}
      >
        <div className="page-header__support-element-image">
          {supportElementImageNode}
        </div>
        <div className="page-header__main-content">
          <Row>
            {loading &&
              <Col span={13}>
                <Skeleton active />
              </Col>
            }
            {!loading &&
              <Col span={13}>
                <p className="page-header__title">
                  {!!title1 && <span className="page-header__title--bold">{title1}</span>}
                  {!!title1 && !!title2 && <span> • </span>}
                  {!!title2 && <span className="page-header__title--light">{title2}</span>}
                </p>
                <div className="text-white page-header__heading">
                  <div className="page-header__heading__icon">
                    <img
                      src={accountActivatedCheckIcon}
                      alt=""
                      width={48}
                      height={48}
                    />
                  </div>
                  <span>{title}</span>
                </div>
                {!!description && <div className="text-white page-header__description">{description}</div>}
                {!!titleBtn && (
                  <Button
                    className="page-header__button__white -custom"
                    onClick={handleOnClick}
                  >
                    <div className="mb-0 page-header__button__text">{titleBtn}</div>
                    <div className="mb-0 page-header__button__icon" />
                  </Button>
                )}
              </Col>
            }
          </Row>
        </div>
      </div>
    );
  };

  const renderHeaderSectionWithoutBgImage = () => (
    <div
      className={`page-header__container --no-bg ${className}`}
    >
      <div className="page-header__main-content">
        {(leftBtns || rightBtns) &&
          <div className="page-header__group-btns">
            {leftBtns}
            {rightBtns}
          </div>
        }

        <Row>
          <Col span={`${btn ? 12 : 24}`}>
            {!!title && <div className="title">{title}</div>}
            {!!description && <div className="description">{description}</div>}
          </Col>
          {!!btn &&
            <Col span={12} className="text-right">
              <Space size={[16]}>
                <RPButton
                  className="page-header-button"
                >
                  {btn}
                </RPButton>
              </Space>
            </Col>
          }
        </Row>
      </div>
    </div>
  );

  return withImage ? renderHeaderSectionWithBgImage() : renderHeaderSectionWithoutBgImage();
};

export default PageHeader;
