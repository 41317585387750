import React from 'react';
import PropTypes from 'prop-types';
import CardDetail from './CardDetail';

function Detail({ data }) {
  return (
    <div className="dashboard-transactions">
      <p className="detail__test-mode">
        <span className="detail__test-mode__status" />
        You are in Test Mode, so only test data is shown. Activate your account
        to start making live transactions.
      </p>
      <CardDetail data={data} />
    </div>
  );
}

Detail.propTypes = {
  data: PropTypes.object,
};

export default Detail;
