import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import RPButton from '@/components/RPButton';
import './modal.scss';

function ModalCancelLink({
  isModalVisible,
  setIsModalVisible,
  cancelLink,
}) {
  const handleOk = () => {
    cancelLink();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      centered
      title="Cancel Payment Link"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="rp-modal payment-link__modal-cancel"
    >
      <p>Are you sure you want to proceed?</p>
      <div className='payment-link__modal-cancel__group-btns'>
        <RPButton grey onClick={() => handleCancel()}>
          No
        </RPButton>
        <RPButton gradient onClick={() => handleOk()}>
          Yes, cancel
        </RPButton>
      </div>
    </Modal>
  );
}

ModalCancelLink.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  cancelLink: PropTypes.func
};

export default ModalCancelLink;
