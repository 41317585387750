import React from 'react';

import PaymentButtonContainer from '@/containers/PaymentButton/new';
import './index.scss';

function PaymentButtonPage() {
  return (
    <PaymentButtonContainer />
  );
}

export default PaymentButtonPage;
