/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Layout, Menu } from 'antd';
import PropTypes from 'prop-types';
import {
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

import logoWhite from '@/assets/images/logo-white.svg';
import logoWhiteMini from '@/assets/images/logo-white-mini.svg';
import { menus } from './menus';

import './index.scss';

const { Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

function RenderIcon({ icon, menuPathActive, activeIcon }) {
  const match = useRouteMatch({
    path: menuPathActive,
    strict: true,
    sensitive: true
  });

  const iconUrl = match?.isExact ? activeIcon : icon;

  return (
    <span
      role="img"
      className="anticon ant-menu-item-icon"
    >
      <img
        src={iconUrl || ''}
        alt="icon"
        width="32px"
        height="32px"
      />
    </span>
  );
}

RenderIcon.propTypes = {
  icon: PropTypes.string,
  menuPathActive: PropTypes.array,
  activeIcon: PropTypes.string,
};

function Sidebar({
  collapsed,
  setCollapsed,
  ...props
}) {
  const location = useLocation();
  const history = useHistory();
  const items = () => menus.map(menu => (
    getItem(
      menu.label,
      menu.path,
      <RenderIcon
        icon={menu.icon}
        menuPathActive={menu.pathsActive}
        activeIcon={menu.activeIcon}
      />
    )
  )
  );

  const handleClick = e => {
    history.replace(e.key);
  };

  const locationSplit = location.pathname.split('/');
  const selectedMenuKey = `/${locationSplit[1]}/${locationSplit[2]}`;

  return (
    <Sider
      {...props}
      collapsible
      width={250}
      breakpoint='xl'
      trigger={null}
      className="sider-bar"
      collapsed={collapsed}
      onBreakpoint={(broken) => {
        setCollapsed(broken);
      }}
    >
      <div className="sider-bar__logo">
        <img
          src={collapsed ? logoWhiteMini : logoWhite}
          alt="Radical Pay"
          width="100%"
          height="100%"
        />
      </div>
      {!collapsed && <p className="ant-menu-item --disabled text-under-logo">PAYMENT MODE</p>
      }
      <Menu
        theme="dark"
        onClick={handleClick}
        selectedKeys={[selectedMenuKey]}
        mode="inline"
        items={items()}
      />
      <div className="sider-bar__footer">
        <div className="sider-bar__footer__information">
          <div>
            <a href="#">Give Us Feedback</a>
          </div>
          <div>
            <a href="#">Report Bug</a>
          </div>
        </div>
        <div className='collapse-sidebar-btn-container'>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}
        </div>
      </div>
    </Sider>
  );
}

Sidebar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
};

export default Sidebar;
