import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Skeleton
} from 'antd';
import { formatter, useBeforeRender } from '@/utils';
import { DashboardService } from '@/services';
import { calculateCurrentPaymentPercent } from '@/helpers/dashboard';

function CurrentPayment() {
  const [loading, setLoading] = useState(false);
  const defaultPercent = {
    paid: `${100 / 3}%`,
    active: `${100 / 3}%`,
    pending: `${100 / 3}%`,
  };
  const [percent, setPercent] = useState(defaultPercent);
  const [data, setData] = useState({
    activeAmount: 0,
    paidAmount: 0,
    pendingAmount: 0,
    totalAmount: 0,
  });
  const fetchData = async () => {
    setLoading(true);
    const { data: payment, success } = await DashboardService.getCurrentPayment();
    if (success) {
      setData({
        activeAmount: Number(payment.activeAmount || 0) / 100,
        paidAmount: Number(payment.paidAmount || 0) / 100,
        pendingAmount: Number(payment.pendingAmount || 0) / 100,
        totalAmount: Number(payment.totalAmount || 0) / 100
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      let { totalAmount } = data;
      totalAmount = Number(totalAmount);
      if (totalAmount === 0) {
        setPercent(defaultPercent);
      }
      const percentPerStatus = calculateCurrentPaymentPercent(data);

      setPercent({ ...percentPerStatus });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useBeforeRender(() => fetchData(), []);

  const renderSkeletonLoading = () => (
    <>
      <div className="dashboard__payment-card__amount w-30">
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <Row className="dashboard__payment-card__process">
        <Col flex={percent.paid} className="dashboard__payment-card__process__item --paid" />
        <Col flex={percent.active} className="dashboard__payment-card__process__item --active" />
        <Col flex={percent.pending} className="dashboard__payment-card__process__item" />
      </Row>
      <Row>
        <Col flex={percent.paid} className="dashboard__payment-card__group">
          <Skeleton active paragraph={{ rows: 1 }} />
        </Col>
        <Col flex={percent.active} className="dashboard__payment-card__group">
          <Skeleton active paragraph={{ rows: 1 }} />
        </Col>
        <Col flex={percent.pending} className="dashboard__payment-card__group">
          <Skeleton active paragraph={{ rows: 1 }} />
        </Col>
      </Row>
    </>
  );

  return (
    <div className="dashboard__payment-card --current-payment">
      {loading && renderSkeletonLoading()}
      {!loading &&
        <>
          <p className="dashboard__payment-card__total">Total Invoice Amount</p>
          <h1 className="dashboard__payment-card__amount">{formatter.formatCurrency(data.totalAmount)}</h1>
          <Row className="dashboard__payment-card__process">
            <Col flex={percent.paid} className="dashboard__payment-card__process__item --paid" />
            <Col flex={percent.active} className="dashboard__payment-card__process__item --active" />
            <Col flex={percent.pending} className="dashboard__payment-card__process__item" />
          </Row>
          <Row wrap={false}>
            <Col flex={percent.paid} className="dashboard__payment-card__group">
              <p className="mb-1">Paid</p>
              <p className="mb-0">{formatter.formatCurrency(data.paidAmount)}</p>
            </Col>
            <Col flex={percent.active} className="dashboard__payment-card__group">
              <p className="mb-1">Active</p>
              <p className="mb-0">{formatter.formatCurrency(data.activeAmount)}</p>
            </Col>
            <Col flex={percent.pending} className="dashboard__payment-card__group">
              <p className="mb-1">Pending</p>
              <p className="mb-0">{formatter.formatCurrency(data.pendingAmount)}</p>
            </Col>
          </Row>
        </>
      }
    </div>
  );
};

export default CurrentPayment;
