import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RPButton from '@/components/RPButton';
import RPInputOTP from '@/components/RPInputOTP';
import { getAuthUser } from '@/utils/localStorageUtil';
import { OTPActions, OTPDeliveryMethod } from '@/constants/account';
import Countdown from 'react-countdown';
import './index.scss';
import moment from 'moment';

function ContentTwoFactorAuthentication({
  dataOTP,
  onSubmit,
  resendOTP,
  handleChange,
  otpExpiredAt,
  otpValid,
}) {
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [showResendOTP, setShowResendOTP] = useState(false);
  const [reachedExpiresOtpTime, setReachedExpiresOtpTime] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);

  useEffect(() => {
    setHasErrored(false);
    if (dataOTP.length === 6) {
      setDisabledSubmit(false);
    } else {
      setDisabledSubmit(true);
    }

    const showResendAfter = moment(otpExpiredAt).diff(moment().add(1, 'second'), 'miliseconds');
    setTimeout(() => {
      setShowResendOTP(true);
      setReachedExpiresOtpTime(true);
    }, showResendAfter);
  }, [dataOTP, otpExpiredAt]);

  useEffect(() => {
    if (otpValid !== undefined) {
      setHasErrored(!otpValid);
    }
  }, [otpValid]);

  const handleSubmit = () => {
    onSubmit({ otpCode: dataOTP });
  };

  const handleResendOTP = async (e) => {
    e.preventDefault();
    setShowResendOTP(false);
    setReachedExpiresOtpTime(false);
    await resendOTP({
      action: OTPActions.OTPActionChangeMFA,
      method: OTPDeliveryMethod.OTPDeliveryMethodEmail,
    });
  };
  const user = getAuthUser();
  const rendererTimerCoundown = ({ formatted: { minutes, seconds } }) => <span>{minutes}:{seconds}</span>;

  return (
    <div className="my-account__profile__modal__verification">
      <p>
        Please key in the six-digit OTP sent to&nbsp;
        <span className="fw-bold">{user.email}</span>.&nbsp;
        {!reachedExpiresOtpTime &&
          <>
            The OTP will expire in&nbsp;
            <Countdown
              key={otpExpiredAt}
              renderer={rendererTimerCoundown}
              zeroPadTime={2}
              date={new Date(otpExpiredAt)}
            />
          </>
        }
      </p>
      <div className="my-account__profile__modal__verification__otp">
        <RPInputOTP
          otp={dataOTP}
          handleChange={handleChange}
          hasErrored={hasErrored}
        />
        {!!showResendOTP &&
          <p className="my-account__profile__modal__verification__otp__text">
            Didn&apos;t receive the OTP?
            <br />
            Click <a href="##" onClick={handleResendOTP}>here</a> to resend.
          </p>
        }
      </div>
      <RPButton
        disabled={disabledSubmit}
        className="my-account__profile__modal__submit-btn"
        gradient
        onClick={handleSubmit}
        center
      >
        {!hasErrored ? 'Submit' : 'Retry'}
      </RPButton>
    </div>
  );
}

ContentTwoFactorAuthentication.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  dataOTP: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  resendOTP: PropTypes.func.isRequired,
  otpExpiredAt: PropTypes.string,
  otpValid: PropTypes.bool,
};

export default ContentTwoFactorAuthentication;
