import http from '@/http';
import { ApiUser } from '@/constants/api';
import {
  localStorageUtil,
  messageNotifyUtil,
} from '@/utils';

export async function switchMode({ mode = 'test' }) {
  try {
    const payload = {
      mode
    };
    const res = await http.post(ApiUser.switchMode, payload);

    if (res.status === 200) {
      const user = localStorageUtil.getAuthUser();
      localStorageUtil.setAuthUser({ ...user, workMode: mode });
      return { success: true };
    }

    return { success: false };
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: 'You are not allowed to perform this operation',
      });
    }

    return { success: false };
  }
}
