import React, { useState } from 'react';
import PageHeader from '@/components/PageHeader';
import Step from '@/components/PaymentButton/Step';
import StepCreateButton from '@/components/PaymentButton/Steps/StepCreateButton';
import StepCustomerDetails from '@/components/PaymentButton/Steps/StepCustomerDetails';
import StepPreview from '@/components/PaymentButton/Steps/StepPreview';
import { parsePaymentButton } from '@/utils/paymentButton';
import { createPaymentButton } from '@/services/PaymentButtonService';
import { useHistory, Prompt } from 'react-router-dom';
import { ROUTES } from '@/constants';
import useExitPrompt from '@/hooks/useExitPrompt';

const pageHeaderData = {
  title: 'Create a New Payment Button',
  description: 'Complete this form to generate a new payment button.',
  headerType: 'white',
};

function PaymentNewButton() {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({});
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const [data, setData] = useState([
    { label: 'Email', type: 'email', editing: false, value: 'email', deletable: false },
    { label: 'Phone Number', type: 'phone', editing: false, value: 'phone-number', deletable: false },
  ]);

  const onSubmit = async () => {
    if (step === 3) {
      const payload = parsePaymentButton({
        ...form,
        customerDetail: data
      });
      const res = await createPaymentButton(payload);
      if (res.status === 200) {
        setShowExitPrompt(false);
        history.replace(ROUTES.APP.PAYMENT_BUTTONS.INDEX);
      }
      return;
    }
    setStep(step + 1);
  };

  const clickBack = () => {
    if (step <= 1) return;
    setStep(step - 1);
  };

  return (
    <div className='payment-button__new'>
      <Prompt
        when={showExitPrompt}
        message="Do you want to leave this page and discard your changes?"
      />
      <PageHeader {...pageHeaderData} />
      <div
        className="dashboard-kyc"
      >
        <Step step={step} />
        {step === 1 &&
          <StepCreateButton
            setShowExitPrompt={setShowExitPrompt}
            form={form}
            setForm={setForm}
            onSubmit={onSubmit}
          />
        }
        {step === 2 &&
          <StepCustomerDetails
            setShowExitPrompt={setShowExitPrompt}
            data={data}
            amount={form.amount}
            setData={setData}
            onSubmit={onSubmit}
            clickBack={clickBack}
          />
        }
        {step === 3 &&
          <StepPreview
            data={data}
            amount={form.amount}
            onSubmit={onSubmit}
            clickBack={clickBack}
            labelButton={form.buttonLabel}
          />
        }
      </div>
    </div>
  );
}

export default PaymentNewButton;
