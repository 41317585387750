import React from 'react';
import { Switch } from 'react-router-dom';
import { ROUTES } from '@/constants';
import AuthRoute from '@/routes/AuthRoute';
import GuestRoute from '@/routes/GuestRoute';
import LoginPage from '@/pages/LoginPage';
import SignupPage from '@/pages/SignupPage';
import ForgotPasswordPage from '@/pages/ForgotPassword';
import NotFoundPage from '@/pages/NotFoundPage';
import DashboardPage from '@/pages/DashboardPage';
import KycPage from '@/pages/KycPage';
import TransactionPage from '@/pages/TransactionPage';
import TransactionShowPage from '@/pages/TransactionPage/show';
import PaymentPagesPage from '@/pages/PaymentPagesPage';
import PaymentPagesShow from '@/pages/PaymentPagesPage/show';
import PaymentPagesCreatePage from '@/pages/PaymentPagesPage/new';
import PaymentLinkPage from '@/pages/PaymentLinkPage';
import PaymentLinkCreatePage from '@/pages/PaymentLinkPage/new';
import PaymentLinkShowPage from '@/pages/PaymentLinkPage/show';
import PaymentLinkEmail from '@/pages/PaymentLinkPage/email';
import MyAccount from '@/pages/MyAccount';
import PaymentButtonPage from '@/pages/PaymentButtonPage';
import PaymentButtonNewPage from '@/pages/PaymentButtonPage/new';
import PaymentButtonShow from '@/pages/PaymentButtonPage/show';
import PaymentButtonEdit from '@/pages/PaymentButtonPage/edit';
import Settings from '@/pages/Settings';
import WebhookShow from '@/pages/Webhooks/show';
import ConfirmationPage from '@/pages/Confirmation';
import UpdatePasswordPage from '@/pages/UpdatePassword';
import PaymentPagesEdit from '@/pages/PaymentPagesPage/edit';
import ServerErrorPage from '@/pages/ServerErrorPage';
import KycDetail from '@/pages/KycPage/detail';

function Routes() {
  return (
    <Switch>
      <GuestRoute
        exact
        path={ROUTES.ROOT_PATH}
        component={LoginPage}
      />
      <GuestRoute
        path={ROUTES.LOGIN_PATH}
        component={LoginPage}
      />
      <GuestRoute
        path={ROUTES.SIGNUP_PATH}
        component={SignupPage}
      />
      <GuestRoute
        path={ROUTES.FORGOT_PASSWORD}
        component={ForgotPasswordPage}
      />
      <GuestRoute
        path={ROUTES.UPDATE_PASSWORD}
        component={UpdatePasswordPage}
      />
      <GuestRoute
        path={ROUTES.CONFIRMATION}
        component={ConfirmationPage}
      />
      <AuthRoute
        path={ROUTES.APP.DASHBOARD_PATH}
        component={DashboardPage}
      />
      <AuthRoute
        path={ROUTES.APP.KYC_PATH}
        component={KycPage}
      />
      <AuthRoute
        path={ROUTES.APP.TRANSACTION_SHOW_PATH}
        component={TransactionShowPage}
      />
      <AuthRoute
        path={ROUTES.APP.TRANSACTION_PATH}
        component={TransactionPage}
      />
      <AuthRoute
        path={ROUTES.APP.PAYMENT_PAGES.NEW}
        component={PaymentPagesCreatePage}
      />
      <AuthRoute
        path={ROUTES.APP.PAYMENT_PAGES.EDIT}
        component={PaymentPagesEdit}
      />
      <AuthRoute
        path={ROUTES.APP.PAYMENT_PAGES.SHOW}
        component={PaymentPagesShow}
      />
      <AuthRoute
        path={ROUTES.APP.PAYMENT_PAGES.INDEX}
        component={PaymentPagesPage}
      />
      <AuthRoute
        path={ROUTES.APP.PAYMENT_LINKS.NEW}
        component={PaymentLinkCreatePage}
      />
      <GuestRoute
        path={ROUTES.APP.PAYMENT_LINKS.EMAIL}
        component={PaymentLinkEmail}
      />
      <AuthRoute
        path={ROUTES.APP.PAYMENT_LINKS.SHOW}
        component={PaymentLinkShowPage}
      />
      <AuthRoute
        path={ROUTES.APP.PAYMENT_LINKS.INDEX}
        component={PaymentLinkPage}
      />
      <AuthRoute
        path={ROUTES.APP.PAYMENT_BUTTONS.NEW}
        component={PaymentButtonNewPage}
      />
      <AuthRoute
        path={ROUTES.APP.PAYMENT_BUTTONS.EDIT}
        component={PaymentButtonEdit}
      />
      <AuthRoute
        path={ROUTES.APP.PAYMENT_BUTTONS.SHOW}
        component={PaymentButtonShow}
      />
      <AuthRoute
        path={ROUTES.APP.PAYMENT_BUTTONS.INDEX}
        component={PaymentButtonPage}
      />
      <AuthRoute
        path={ROUTES.APP.SETTINGS.WEBHOOKS.SHOW}
        component={WebhookShow}
      />
      <AuthRoute
        path={ROUTES.APP.SETTINGS.INDEX}
        component={Settings}
      />
      <AuthRoute
        path={ROUTES.APP.MY_ACCOUNT.INDEX}
        component={MyAccount}
      />
      <AuthRoute
        path={ROUTES.APP.KYC_COMPLETED}
        component={KycDetail}
      />
      <GuestRoute
        path={ROUTES.SERVER_ERROR}
        component={ServerErrorPage}
      />
      <GuestRoute
        path='*'
        component={NotFoundPage}
      />
    </Switch>
  );
}

export default Routes;
