/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Select,
} from 'antd';
import { uniqueId, upperFirst } from 'lodash';

import RPButton from '@/components/RPButton';
import RPSelect from '@/components/RPSelect';
import RPInput from '@/components/RPInput';
import RPRadio from '@/components/RPRadio';
import RPDatePicker from '@/components/RPDatePicker';
import RPCheckbox from '@/components/RPCheckbox';
import formPaymentPagesIcon from '@/assets/images/payment-pages/form/payment-page-icon.svg';
import { localStorageUtil, validate } from '@/utils';
import moment from 'moment';
import { ENV, RDCP_MODES } from '@/constants';

import './index.scss';

const { Option } = Select;

const radioOptions = [
  { value: true, label: 'Send Receipts Automatically' },
  { value: false, label: 'Don’t Send Receipts Automatically' }
];

function ReceiptsAndSettings({
  data,
  form,
  setData,
  setForm,
  onSubmit,
  clickBack,
  typeOfInformation,
  isEdit,
  setShowExitPrompt,
}) {
  const onFinish = (values) => {
    onSubmit(values);
  };
  const [formInstance] = Form.useForm();
  const currentUser = localStorageUtil.getAuthUser();
  const canCustomUrl = currentUser.workMode === RDCP_MODES.LIVE_MODE;
  const [enableEnterCustomUrl, setEnableEnterCustomUrl] = useState(() =>
    data?.paymentReceiptsAndPageSettings?.enableCustomUrl
  );
  const [cutomURL, setCustomURL] = useState(() =>
    data?.paymentReceiptsAndPageSettings?.customUrl
  );
  const onChange = (event) => {
    setShowExitPrompt(true);
    if (event.target.type === 'checkbox') {
      setForm({
        ...form,
        [event.target.name]: event.target.checked,
      });
      setEnableEnterCustomUrl(event.target.checked);
    }
    else if (event.target.type === 'radio') {
      setForm({
        ...form,
        [event.target.name]: event.target.value === 'true',
      });
    } else {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });

      if (event.target.name === 'customUrl') {
        setCustomURL(event.target.value);
      }
    }
  };

  const onChangeTypeOfInformation = e => {
    setShowExitPrompt(true);
    setForm({
      ...form,
      typeOfInformation: e
    });
  };

  const {
    noExpiry,
    showCustomerInformationOnReceipt,
  } = form;

  useEffect(() => {
    formInstance.setFieldsValue(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleChangePicker = e => {
    setShowExitPrompt(true);
    setForm({
      ...form,
      expiryDate: e,
    });
  };

  useEffect(() => {
    formInstance.resetFields(['customUrl']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableEnterCustomUrl]);

  // Can not select days before today and today
  const disabledDate = (current) => current && current < moment().startOf('day');

  const handleClickBack = () => {
    setData({ ...data, paymentReceiptsAndPageSettings: form });
    clickBack();
  };

  return (
    <Form
      initialValues={form}
      name='basic'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete='off'
      onFinish={onFinish}
      onChange={onChange}
      noValidate
      form={formInstance}
    >
      <div
        className='rp-payment-pages__form__card --payment-receipt-and-page-setting'
      >
        <Row>
          <Col flex='50%' className='rp-payment-pages__form__card__left'>
            <img
              src={formPaymentPagesIcon}
              alt='icon'
              width='32px'
              height='32px'
            />
            <h2 className='rp-payment-pages__form__card__left__title'>Payment Receipt & Page Setting</h2>
            <p
              className='rp-payment-pages__form__card__left__desc'
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sit amet nibh ut ligula ultricies rutrum.
            </p>
          </Col>
          <Col flex='50%' className='rp-payment-pages__form__card__right'>
            <Form.Item
              wrapperCol={{ span: 24 }}
              name="sendReceiptsAutomatically"
            >
              <RPRadio
                options={radioOptions}
                name="sendReceiptsAutomatically"
                childrens={[
                  <div className='radio-hint'>
                    Receipts are emailed to customers immediately after payment.
                  </div>,
                  <div className='radio-hint'>
                    You may send receipts later from dashboard. Your own reference ID may be added too
                  </div>
                ]}
              />
            </Form.Item>

            <div className='show-customer-info-on-receipt'>
              <Form.Item
                label=""
                name="showCustomerInformationOnReceipt"
                valuePropName="checked"
                wrapperCol={{ span: 24 }}
              >
                <RPCheckbox
                  name="showCustomerInformationOnReceipt"
                >
                  Show Customer's Information on Receipt
                </RPCheckbox>
              </Form.Item>

              <Form.Item
                name="typeOfInformation"
                wrapperCol={{ span: 24 }}
              >
                <RPSelect
                  placeholder="Choose Type of information"
                  label="Type of information"
                  className="rp-payment-pages__form__card__right__input --custom w-100"
                  disabled={!showCustomerInformationOnReceipt}
                  required={showCustomerInformationOnReceipt}
                  name="typeOfInformation"
                  onChange={onChangeTypeOfInformation}
                >
                  {
                    typeOfInformation.map((item) => (
                      <Option
                        key={uniqueId('category-option-')}
                        value={item.value}
                        disabled={!item.deletable}
                      >
                        {upperFirst(item.label)} {item.deletable ? '' : '(display by default)'}
                      </Option>
                    ))
                  }
                </RPSelect>
              </Form.Item>
            </div>

            <div className='custom-url'>
              {canCustomUrl &&
                <>
                  <Form.Item
                    label=""
                    name="enableCustomUrl"
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                  >
                    <RPCheckbox
                      name="enableCustomUrl"
                    >
                      Choose custom URL for this page
                    </RPCheckbox>
                  </Form.Item>

                  <Form.Item
                    name="customUrl"
                    className="custom-url-input-wrapper"
                    rules={[
                      {
                        required: enableEnterCustomUrl,
                        message: 'Please input Custom URL'
                      },
                      () => ({
                        validator(_, value) {
                          if (value && !validate.isSlug(value)) {
                            return Promise.reject(new Error('Please input the valid slug'));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    wrapperCol={{ span: 24 }}
                  >
                    <RPInput
                      required
                      disabled={!enableEnterCustomUrl}
                      name="customUrl"
                      className="rp-payment-pages__form__card__right__input --custom"
                      label="Custom URL"
                      placeholder="slug"
                      maxLength={60}
                    />
                  </Form.Item>
                  {enableEnterCustomUrl &&
                    <div className="show-url">{ENV.CHECKOUT_PAGE_BASE_URL}/{cutomURL}</div>
                  }
                </>
              }
              {!canCustomUrl &&
                <>
                  <Form.Item
                    label=""
                    name="enableCustomUrl"
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                  >
                    <RPCheckbox
                      name="enableCustomUrl"
                      disabled
                    >
                      Choose custom URL for this page
                    </RPCheckbox>
                  </Form.Item>

                  <Form.Item
                    name="customUrl"
                    className="custom-url-input-wrapper"
                    wrapperCol={{ span: 24 }}
                  >
                    <RPInput
                      required
                      disabled
                      name="customUrl"
                      className="rp-payment-pages__form__card__right__input --custom"
                      label="Custom URL"
                      placeholder={`${ENV.CHECKOUT_PAGE_BASE_URL}/`}
                      hint='Custom slug is only available in Live Mode'
                    />
                  </Form.Item>
                </>
              }
            </div>

            <div className='page-expiry-date'>
              <div className='title'>Page Expiry date</div>
              <Form.Item
                label=""
                name="noExpiry"
                valuePropName="checked"
                wrapperCol={{ span: 24 }}
              >
                <RPCheckbox
                  name="noExpiry"
                >
                  No Expiry Date
                </RPCheckbox>
              </Form.Item>

              <Form.Item
                name="expiryDate"
                rules={[
                  { required: !noExpiry, message: 'Please Choose Expiry Date' },
                ]}
                wrapperCol={{ span: 24 }}
              >
                <RPDatePicker
                  required={!noExpiry}
                  disabled={noExpiry}
                  name="expiryDate"
                  className="rp-payment-pages__form__card__right__input --custom"
                  label="Expiry Date"
                  placeholder="Expiry Date"
                  onChange={handleChangePicker}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </div>

          </Col>
        </Row>
      </div>
      <div className='rp-payment-pages__form__card__group-btn'>
        <RPButton
          grey
          onClick={handleClickBack}
        >
          Back
        </RPButton>
        <RPButton
          gradient
          htmlType='submit'
        >
          <div className='rp-payment-pages__form__card__submit-btn'>
            {isEdit ? 'Update' : 'Submit'}
          </div>
        </RPButton>
      </div>
    </Form>
  );
}

ReceiptsAndSettings.propTypes = {
  form: PropTypes.object,
  data: PropTypes.object,
  setForm: PropTypes.func,
  setData: PropTypes.func,
  onSubmit: PropTypes.func,
  clickBack: PropTypes.func,
  typeOfInformation: PropTypes.array,
  isEdit: PropTypes.bool,
  setShowExitPrompt: PropTypes.func,
};

export default ReceiptsAndSettings;
