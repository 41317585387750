import React from 'react';
import { Row, Col } from 'antd';
import RPButton from '@/components/RPButton';
import PropTypes from 'prop-types';
import { ModalProfileTypes } from '@/constants/Profile';
import './index.scss';

function Support({
  data,
  showModal,
}) {
  const {
    supportEmail,
    supportPhoneNumber,
    supportWebsiteUrl,
  } = data;

  return (
    <div className="my-account__profile__card">
      <Row gutter={[12, 24]}>
        <Col span={12} className="my-account__profile__card__title">
          Support Details
        </Col>
        <Col span={12}>
          <RPButton
            grey
            onClick={() => showModal(ModalProfileTypes.supportDetail)}
          >
            Edit Details
          </RPButton>
        </Col>
        <Col span={12}>Phone Number</Col>
        <Col span={12}>{supportPhoneNumber ? `+${supportPhoneNumber}` : '--'}</Col>
        <Col span={12}>Email</Col>
        <Col span={12}>{supportEmail || '--'}</Col>
        <Col span={12}>Website/Contact us link</Col>
        <Col span={12}>
          {!supportWebsiteUrl ? '--' : <a href={supportWebsiteUrl}>{supportWebsiteUrl}</a>}
        </Col>
      </Row>
    </div>
  );
}

Support.propTypes = {
  data: PropTypes.object,
  showModal: PropTypes.func,
};
export default Support;
