import http from '@/http';
import {
  parse,
  messageNotifyUtil,
} from '@/utils';
import { ApiTransactions } from '@/constants/api';
import { generatePath } from 'react-router-dom';
import { identity, pickBy } from 'lodash';

export async function getTransactions({ limit, page, createdFrom, createdTo, status, id, refType, refId }) {
  try {
    const params = parse.snakelize({ limit, page, createdFrom, createdTo, status, id, refType, refId });
    const { data } = await http.get(ApiTransactions.index, { params });
    return parse.camelize(data);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function getTransactionDetail({ id }) {
  try {
    const url = generatePath(ApiTransactions.detail, { id });
    const { data } = await http.get(url);
    return parse.camelize(data);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}


export async function sendReceiptTransaction({ id }) {
  try {
    const url = generatePath(ApiTransactions.sendReceipt, { id });
    const { data } = await http.post(url);
    messageNotifyUtil.setSuccessMessage({
      title: 'Send Receipt',
      description: `Transaction ${id} was sent receipt!`,
    });
    return parse.camelize(data);
  } catch (error) {
    const { response } = error;
    if (response?.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: response?.data?.code,
        description: response?.data?.message,
      });
    }

    if (response?.status === 429) {
      messageNotifyUtil.setInfoMessage({
        title: 'Send Receipt',
        description: 'Your request already processed, please try again later.',
      });
    }

    return response;
  }
}

function downloadCSV(csvStr) {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvStr)}`;
  hiddenElement.target = '_blank';
  hiddenElement.download = 'transaction.csv';
  hiddenElement.click();
}

export async function exportTransactions({ createdFrom, createdTo, status, id, refType, refId }) {
  try {
    const params = parse.snakelize({
      createdFrom,
      createdTo,
      status,
      id,
      refType,
      refId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    const result = pickBy(params, identity);
    const searchParams = new URLSearchParams(result);
    const url = `${ApiTransactions.exportCSV}?${searchParams}`;
    const { data } = await http.post(url);
    downloadCSV(data);
    return;
  } catch (error) {
    messageNotifyUtil.setErrorMessage({
      title: error?.response?.data?.code || 'Internal Server Error',
      description: error?.response?.data?.message || 'Something went wrong!',
    });
  }
}
