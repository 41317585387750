import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  uniqueId,
  snakeCase,
  upperCase,
} from 'lodash';
import {
  Row,
  Col,
  Spin,
  Space,
  Skeleton,
  Radio,
  Empty
} from 'antd';
import {
  Area,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';

import { formatter, useBeforeRender } from '@/utils';
import RPButton from '@/components/RPButton';
import CardComponent from '@/components/CardComponent';
import FilterComponent from '@/components/Dashboard/Filter';
import paymentInsightsIcon from '@/assets/images/dashboard/payment-insights-icon.svg';
import { DashboardService } from '@/services';
import { parsePIChartData } from '@/helpers/dashboard';
import { PAYMENT_METHODS_AVAILABLE } from '@/constants';


function CustomToolTip({
  active,
  payload,
  currentPaymentMethodName,
}) {
  if (!active || !payload) {
    return null;
  }

  const renderItemName = (item) => {
    let itemName;
    if (item?.name !== 'paymentVolumn') {
      itemName = item?.name;
    } else if (currentPaymentMethodName === snakeCase(PAYMENT_METHODS_AVAILABLE.CREDIT_DEBIT)) {
      itemName = PAYMENT_METHODS_AVAILABLE.CREDIT_DEBIT;
    } else {
      itemName = PAYMENT_METHODS_AVAILABLE[upperCase(currentPaymentMethodName)];
    }

    return itemName;
  };

  return (
    <div
      className="dashboard__payment-insights__custom-tooltip"
    >
      {payload.map((item) => (
        <p
          key={uniqueId('tooltip-')}
          className="mb-0 dashboard__payment-insights__custom-tooltip__item"
        >
          <span
            className="name text-capitalize">
            {renderItemName(item)}
          </span>: <span className="value">{formatter.formatCurrency(item.value).toLocaleString()}
          </span>
          {/* <div>{item.payload?.name ? moment(item.payload.name) : ''}</div> */}
        </p>
      ))}
    </div>
  );
}

CustomToolTip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  currentPaymentMethodName: PropTypes.string,
};

function PaymentInsights() {
  const [loading, setLoading] = useState(false);
  const [groupBy, setGroupBy] = useState(() => 'day');
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    paymentMethod: snakeCase(PAYMENT_METHODS_AVAILABLE.CREDIT_DEBIT),
    groupBy: 'day',
  });
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [disabledWeekly, setDisabledWeekly] = useState(false);
  const [disabledMonthly, setDisabledMonthly] = useState(false);
  const DataFormater = (number) => {
    if (number > 1000000000) {
      return `${(number / 1000000000).toString()}B`;
    } if (number > 1000000) {
      return `${(number / 1000000).toString()}M`;
    } if (number > 1000) {
      return `${(number / 1000).toString()}K`;
    }
    return number.toString();
  };

  const [showFilter, setShowFilter] = useState(false);

  const handleOnClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const renderSkeletonLoading = () => (
    <>
      <Row
        gutter={16}
        className="dashboard__payment-insights-information"
      >
        <Col
          span={12}
          className="dashboard__payment-insights-information__item"
        >
          <Skeleton active paragraph={{ rows: 1 }} />
        </Col>

        <Col
          span={12}
          className="dashboard__payment-insights-information__item"
        >
          <Skeleton active paragraph={{ rows: 1 }} />
        </Col>
      </Row>
      <ResponsiveContainer
        width="100%"
        height={400}
        className="dashboard__payment-insights-chart position-relative"
      >
        <Spin className="loading-spiner" />
      </ResponsiveContainer>
    </>
  );

  const fetchData = async (params) => {
    setLoading(true);
    const payload = {
      ...params,
      createdFrom: params.createdFrom ? moment(params.createdFrom).startOf('day').utc().format() : null,
      createdTo: params.createdTo ? moment(params.createdTo).endOf('day').utc().format() : null,
    };
    const { success, aggregation, data: dataChart } = await DashboardService.getPaymentInsight(payload);
    if (success) {
      const { paymentMethod, sale } = aggregation;
      setTotalPayment(Number(paymentMethod || 0) / 100);
      setTotalSale(Number(sale || 0) / 100);

      if (dataChart?.length > 0) {
        const dataChartParsed = parsePIChartData({
          data: dataChart,
          groupBy: params.groupBy,
          filterRange: [params.createdFrom, params.createdTo]
        });
        setData(dataChartParsed);
      } else {
        setData([]);
      }
    }
    setLoading(false);
  };

  useBeforeRender(() => fetchData({ paymentMethod: 'credit_debit', groupBy }), []);

  const changeGroup = (e) => {
    setGroupBy(e.target.value);
    fetchData({
      ...filter,
      groupBy: e.target.value
    });
  };

  const handleFilter = (values) => {
    let currentGroupBy = groupBy;
    if (values.daterange?.length === 2) {
      const [from, to] = values.daterange;
      filter.createdFrom = from.toDate();
      filter.createdTo = to.toDate();
      setDisabledWeekly(() => to.diff(from, 'day', true) < 7);
      setDisabledMonthly(() => from.month() === to.month());
      if (
        (to.diff(from, 'day', true) < 7 && currentGroupBy === 'week') ||
        (from.month() === to.month() && currentGroupBy === 'month')
      ) {
        setGroupBy('day');
        currentGroupBy = 'day';
      }
    } else {
      delete filter.createdFrom;
      delete filter.createdTo;
    }
    filter.paymentMethod = values.paymentMethod;

    setFilter({ ...filter, groupBy: currentGroupBy });
    fetchData({ ...filter, groupBy: currentGroupBy });
  };

  const renderTotalPaymentVolumnForPaymentMethod = (paymentMethod) => {
    if (paymentMethod === snakeCase(PAYMENT_METHODS_AVAILABLE.CREDIT_DEBIT)) {
      return PAYMENT_METHODS_AVAILABLE.CREDIT_DEBIT;
    }
    return PAYMENT_METHODS_AVAILABLE[upperCase(filter.paymentMethod)];
  };

  return (
    <div className="dashboard__payment-insights-container">
      <CardComponent
        loading={loading}
        icon={paymentInsightsIcon}
        title="Payment Insights"
        extra={
          <Space size={[16]}>
            <Radio.Group value={groupBy} onChange={changeGroup} size="large">
              <Radio.Button value="day">Daily</Radio.Button>
              <Radio.Button value="week" disabled={disabledWeekly}>Weekly</Radio.Button>
              <Radio.Button value="month" disabled={disabledMonthly}>Monthly</Radio.Button>
            </Radio.Group>
            <RPButton onClick={handleOnClickFilter}>Filter</RPButton>
          </Space>
        }
      >
        <div
          className={`dashboard__payment-activity__filter-container ${showFilter ? 'd-block' : 'd-none'} `}
        >
          <FilterComponent
            closeFilter={handleCloseFilter}
            search={handleFilter}
          />
        </div>
        {loading && renderSkeletonLoading()}
        {!loading &&
          <>
            <Row
              gutter={16}
              className="dashboard__payment-insights-information"
            >
              <Col
                span={12}
                className="dashboard__payment-insights-information__item"
              >
                <p className="title">{renderTotalPaymentVolumnForPaymentMethod(filter.paymentMethod)}</p>
                <p className="value">{formatter.formatCurrency(totalPayment)}</p>
              </Col>

              <Col
                span={12}
                className="dashboard__payment-insights-information__item"
              >
                <p className="title">Total Sales</p>
                <p className="value">{formatter.formatCurrency(totalSale)}</p>
              </Col>
            </Row>
            <ResponsiveContainer
              width="100%"
              height={400}
              className={`dashboard__payment-insights-chart ${data?.length === 0 ? '--empty' : ''}`}
            >
              {
                data?.length === 0 ? (
                  <Empty />
                ) : (
                  <AreaChart
                    data={data}
                  >
                    <defs>
                      <linearGradient id="colorSales" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#E213A5" stopOpacity={1} />
                        <stop offset="95%" stopColor="#E213A5" stopOpacity={0.2} />
                      </linearGradient>
                      <linearGradient id="colorPaymentVolumns" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#0849EF" stopOpacity={1} />
                        <stop offset="95%" stopColor="#0849EF" stopOpacity={0.2} />
                      </linearGradient>
                    </defs>

                    <CartesianGrid vertical={false} />
                    <XAxis dataKey="name" axisLine={false} tickLine={false} />
                    <YAxis tickFormatter={DataFormater} axisLine={false} tickLine={false} />
                    <Tooltip
                      content={<CustomToolTip currentPaymentMethodName={filter.paymentMethod} />}
                      cursor={false}
                    />
                    <Area
                      dataKey="paymentVolumn"
                      stroke="#0849EF"
                      fill="url(#colorPaymentVolumns)"
                      activeDot={{ stroke: 'white', strokeWidth: 3, r: 7 }}
                    />
                    <Area
                      dataKey="sales"
                      stroke="#E213A5"
                      fill="url(#colorSales)"
                      activeDot={{ stroke: 'white', strokeWidth: 3, r: 7 }}
                    />
                  </AreaChart>
                )
              }
            </ResponsiveContainer>
          </>
        }
      </CardComponent>
    </div>
  );
}

export default PaymentInsights;
