import http from '@/http';
import { ApiPaymentLink } from '@/constants/api';
import { generatePath } from 'react-router-dom';
import {
  parse,
  messageNotifyUtil,
} from '@/utils';

export async function getPaymentLinks(filter) {
  try {
    const params = parse.snakelize(filter);
    const { data } = await http.get(ApiPaymentLink.index, { params });
    return parse.camelize(data, { deep: true });
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    } else {
      messageNotifyUtil.setErrorMessage({
        title: null,
        description: error?.response?.data?.message || 'Something went wrong!',
      });
    }
    return error.response;
  }
}

export async function createPaymentLink(payload) {
  try {
    const res = await http.post(ApiPaymentLink.create, parse.snakelize(payload));
    messageNotifyUtil.setSuccessMessage({
      title: 'Create payment link',
      description: 'Your payment link has been created.',
    });
    return res;
  } catch (error) {
    const { response } = error;
    switch (response?.status) {
      case 400:
        messageNotifyUtil.setErrorMessage({
          title: response?.data.code,
          description: response?.data?.message,
        });
        break;
      case 429:
        messageNotifyUtil.setInfoMessage({
          title: 'Create payment link',
          description: 'Your request already processed, please try again later.',
        });
        break;
      default:
        messageNotifyUtil.setErrorMessage({
          title: null,
          description: response?.data?.message || 'Something went wrong!',
        });
        break;
    }
    return response;
  }
}

export async function updatePaymentLink(payload) {
  try {
    const url = generatePath(ApiPaymentLink.update, { id: payload.id });
    const { data } = await http.post(url, parse.snakelize(payload));
    messageNotifyUtil.setSuccessMessage({
      title: 'Update payment link',
      description: `Your payment link #${payload.id} has been updated.`,
    });
    return parse.camelize(data);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    } else {
      messageNotifyUtil.setErrorMessage({
        title: null,
        description: error?.response?.data?.message || 'Something went wrong!',
      });
    }
    return error.response;
  }
}

export async function getPaymentLink({ id }) {
  try {
    const url = generatePath(ApiPaymentLink.detail, { id });
    const { data } = await http.get(url);
    return parse.camelize(data);
  } catch (error) {
    messageNotifyUtil.setErrorMessage({
      title: null,
      description: error?.response?.data?.message || 'Something went wrong!',
    });
    return error.response;
  }
}


export async function cancelPaymentLink({ id }) {
  try {
    const url = generatePath(ApiPaymentLink.cancel, { id });
    const { data } = await http.post(url, {});
    return parse.camelize(data);
  } catch (error) {
    messageNotifyUtil.setErrorMessage({
      title: null,
      description: error?.response?.data?.message || 'Something went wrong!',
    });
    return error.response;
  }
}


export async function sendPaymentLink({ id }) {
  try {
    const url = generatePath(ApiPaymentLink.send, { id });
    const { data } = await http.post(url, {});
    messageNotifyUtil.setSuccessMessage({
      title: 'Send payment link',
      description: 'Your payment link has been sent to email.',
    });
    return parse.camelize(data);
  } catch (error) {
    const { response } = error;
    if (response?.status === 429) {
      messageNotifyUtil.setInfoMessage({
        title: 'Send payment link',
        description: 'Your request already processed, please try again later.',
      });
    } else {
      messageNotifyUtil.setErrorMessage({
        title: 'Send payment link',
        description: error?.response?.data?.message || 'Something went wrong!',
      });
    }

    return null;
  }
}
