import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Skeleton
} from 'antd';
import { useHistory } from 'react-router-dom';
import RPButton from '@/components/RPButton';

import './index.scss';

function PaymentCard({
  path,
  desc,
  content,
  labelBtn,
  loading,
}) {
  const history = useHistory();
  const openPath = () => {
    history.push(path);
  };

  return (
    <div className="dashboard__payment-card">
      {loading &&
        <>
          <Skeleton active paragraph={{ rows: 2 }} />
          <div className="dashboard__payment-card__button -custom w-30">
            <Skeleton.Button active block shape="round" />
          </div>
        </>
      }
      {!loading &&
        <>
          <p className="dashboard__payment-card__content">{content}</p>
          <Row>
            <Col span={18}>
              <p className="dashboard__payment-card__description">{desc}</p>
            </Col>
          </Row>
          <RPButton
            onClick={() => openPath()}
            className="dashboard__payment-card__button -custom"
            danger
          >
            {labelBtn}
          </RPButton>
        </>
      }
    </div>
  );
};

PaymentCard.propTypes = {
  path: PropTypes.string,
  desc: PropTypes.string,
  content: PropTypes.string,
  labelBtn: PropTypes.string,
  loading: PropTypes.bool
};

export default PaymentCard;
