import React from 'react';
import { Result } from 'antd';
import './index.scss';

function ServerErrorPage() {
  return (
    <div className="server-error-page">
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
      />
    </div>
  );
}

export default ServerErrorPage;
