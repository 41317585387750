import React from 'react';
import Company from '@/components/MyAccount/Company';
import { uploadAccountLogo } from '@/services/myAccountService';
import { getAuthUser } from '@/utils/localStorageUtil';

function CompanyContainer() {
  const uploadFile = async (file) => uploadAccountLogo(file);
  const { merchant } = getAuthUser();
  return (
    <Company
      uploadFile={uploadFile}
      defaultSrc={merchant.companyLogoUrl}
    />
  );
}

export default CompanyContainer;
