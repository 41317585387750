import React from 'react';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import './index.scss';

function RPInputOTP({ otp, handleChange, hasErrored, ...props }) {
  return (
    <div className="rp-otp">
      <OtpInput
        className="rp-otp__ele"
        containerStyle="rp-otp__container"
        value={otp}
        onChange={handleChange}
        numInputs={6}
        isInputNum
        separator={<span className="rp-otp__separator" />}
        inputStyle="rp-otp__input"
        shouldAutoFocus
        hasErrored={hasErrored}
        errorStyle={hasErrored ? 'otp-has-error' : ''}
        {...props}
      />
      {hasErrored &&
        <div className="otp-error">
          Wrong OTP
        </div>
      }
    </div>
  );
}

RPInputOTP.propTypes = {
  otp: PropTypes.string,
  handleChange: PropTypes.func,
  hasErrored: PropTypes.bool,
};

export default RPInputOTP;
