import React from 'react';

import KYCContainer from '@/containers/KYC';
import './index.scss';

function KYCPage() {
  return (
    <KYCContainer readOnly={false} />
  );
}

export default KYCPage;
