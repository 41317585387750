import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
} from 'antd';
import {
  EyeOutlined,
  EyeInvisibleOutlined
} from '@ant-design/icons';

import './index.scss';

function RPInput({
  label,
  hint,
  required,
  textPrefix,
  type,
  ...props
}) {
  const blockInvalidChar = e => ['e', 'E'].includes(e.key) && e.preventDefault();
  const [isShow, setIsShow] = useState(false);
  const [typePassword, setTypePassword] = useState('password');
  const renderInputNumber = (
    <Input
      {...props}
      type={type}
      onKeyDown={blockInvalidChar}
    />
  );

  const renderInputDefault = (
    <Input
      type={type}
      {...props}
    />
  );

  const renderInputPassword = (
    <Input
      type={typePassword}
      {...props}
    />
  );

  const toggleShow = () => {
    if (isShow) {
      setTypePassword('password');
    } else {
      setTypePassword('string');
    }
    setIsShow(!isShow);
  };

  function renderEyes(){
    if (isShow) {
      return (
        <EyeOutlined className='rp-input__eyes' onClick={toggleShow}/>
      );
    }

    return (
      <EyeInvisibleOutlined className='rp-input__eyes' onClick={toggleShow}/>
    );
  };

  const renderInput = () => {
    switch (type) {
      case 'number':
        return renderInputNumber;
      case 'password':
        return renderInputPassword;
      default:
        return renderInputDefault;
    }
  };
  return (
    <div>
      <div className={`rp-input-container${textPrefix ? ' --prefix' : ''}`}>
        {
          renderInput()
        }
        {
          type === 'password' && renderEyes()
        }
        {
          textPrefix &&
          <div className="rp-input__prefix">{textPrefix}</div>
        }
        <div className="hover-style" />
        {!!label && (
          <label htmlFor='#' className="custom-input__label">
            {label}
            {!!required && (<span className="custom-input__required">*</span>)}
          </label>
        )
        }
      </div>
      {!!hint && <p className="custom-input__hint">{hint}</p>}
    </div>
  );
};

RPInput.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  textPrefix: PropTypes.string,
  type: PropTypes.string
};

export default RPInput;
