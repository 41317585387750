import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Space } from 'antd';
import { uniqueId } from 'lodash';

import { validateEmail, validatePhoneNumber } from '@/utils/validate';
import RPInput from '@/components/RPInput';
import RPButton from '@/components/RPButton';
import kycContainIcon from '@/assets/images/kyc/kyc-contact-icon.svg';
import checkedIcon from '@/assets/images/kyc/checked-icon.svg';
import uncheckedIcon from '@/assets/images/kyc/unchecked-icon.svg';
import RPPhoneNumber from '@/components/RPPhoneNumber';

function ContactInfo({
  clickNext,
  onSaveAndExit,
  setContactForm,
  contactInfoData,
  saveAndExitLoading,
  readOnly
}) {
  const [formCheck, setFormCheck] = useState([
    { label: 'Your Name', value: false, id: 'contactName' },
    { label: 'Contact Number', value: false, id: 'contactNumber' },
    { label: 'Your Email Address', value: false, id: 'contactEmail' },
  ]);

  const onChange = (e) => {
    setContactForm({
      ...contactInfoData,
      [e.target.name]: e.target.value
    });
  };

  const isCompleted = () => formCheck.filter(f => !f.value).length > 0;

  const handleOnChangePhoneNumber = (phone) => {
    setContactForm({
      ...contactInfoData,
      contactNumber: phone
    });
  };

  const getValueFormCheck = (label, value) => {
    switch (label) {
      case 'contactEmail':
        return !!value && !!validateEmail(value);
      case 'contactNumber':
        return !!value && validatePhoneNumber(value);
      default:
        return value?.trim() !== '';
    }
  };

  useEffect(() => {
    const formChecked = formCheck.map(check => ({
      ...check,
      value: getValueFormCheck(check.id, contactInfoData[check.id])
    }));
    setFormCheck(formChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactInfoData]);

  return (
    <div>
      <div className="dashboard-kyc__card">
        <Row>
          <Col flex="50%" className="dashboard-kyc__card__left">
            <img
              src={kycContainIcon}
              alt="icon"
              width="40.32px"
              height="40.32px"
            />
            <h2 className="dashboard-kyc__card__left__title">Contact Info</h2>
            <p className="dashboard-kyc__card__left__desc">Form Checklist</p>
            {
              formCheck.map((data) => (
                <div
                  className="dashboard-kyc__card__left__desc --group"
                  key={uniqueId('form-check-')}
                >
                  <img
                    src={data.value ? checkedIcon : uncheckedIcon}
                    alt="icon"
                    width="24px"
                    height="24px"
                  />
                  {data.label}
                </div>
              ))
            }
          </Col>
          <Col flex="50%" className="dashboard-kyc__card__right">
            <Form.Item
              name={['contactInfo', 'contactName']}
              rules={[{ required: true, message: 'Please input your contact name' }]}
              wrapperCol={{ span: 24 }}
            >
              <RPInput
                name="contactName"
                className="dashboard-kyc__card__right__input --custom"
                label="Contact Name"
                placeholder="Contact Name"
                required
                onChange={onChange}
                disabled={readOnly}
              />
            </Form.Item>
            <Form.Item
              name={['contactInfo', 'contactNumber']}
              rules={[
                {
                  required: true,
                  message: 'Please input your phone'
                },
                () => ({
                  validator(_, value) {
                    if (value && !validatePhoneNumber(value)) {
                      return Promise.reject(new Error('Contact Number Invalid'));
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              wrapperCol={{ span: 24 }}
            >
              <RPPhoneNumber
                name="contactNumber"
                className="dashboard-kyc__card__right__input --custom mb-0 w-100"
                specialLabel="Phone No"
                placeholder="Phone No."
                required
                inputProps={{
                  name: 'contactNumber',
                  required: true,
                }}
                onChange={handleOnChangePhoneNumber}
                disabled={readOnly}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{ span: 24 }}
              label=""
              name={['contactInfo', 'contactEmail']}
              rules={[
                {
                  required: true,
                  message: 'Please input your email'
                },
                () => ({
                  validator(_, value) {
                    if (value && !validateEmail(value)) {
                      return Promise.reject(new Error('Email Invalid'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <RPInput
                name="contactEmail"
                className="dashboard-kyc__card__right__input --custom"
                placeholder="skfnasn@email.com"
                label="Email"
                type="email"
                required
                onChange={onChange}
                disabled={readOnly}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div className="dashboard-kyc__card__group-btn">
        <div />
        <Space>
          {
            !readOnly && (
              <RPButton
                grey
                disabled={saveAndExitLoading || isCompleted()}
                onClick={onSaveAndExit}
                loading={saveAndExitLoading}
              >
                <div className="dashboard-kyc__card__submit-btn">
                  Save & Exit
                </div>
              </RPButton>
            )
          }

          <RPButton
            gradient
            withArrowIcon
            onClick={clickNext}
            disabled={saveAndExitLoading || isCompleted()}
          >
            <div className="dashboard-kyc__card__submit-btn">
              Next
            </div>
          </RPButton>
        </Space>
      </div>
    </div>
  );
}

ContactInfo.propTypes = {
  clickNext: PropTypes.func,
  onSaveAndExit: PropTypes.func,
  saveAndExitLoading: PropTypes.bool,
  setContactForm: PropTypes.func.isRequired,
  contactInfoData: PropTypes.object.isRequired,
  readOnly: PropTypes.bool
};

export default ContactInfo;
