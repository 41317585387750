import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Skeleton,
} from 'antd';
import { useHistory } from 'react-router-dom';
import RPButton from '@/components/RPButton';
import paybuttonIcon from '@/assets/images/sidebar/paybutton-icon.svg';
import { ROUTES } from '@/constants';
import './index.scss';

function DashboardPmbutton({
  loading,
}) {
  const history = useHistory();
  const openPath = () => {
    history.push(ROUTES.APP.PAYMENT_BUTTONS.NEW);
  };

  const renderSkeletonLoading = () => (
    <Col span={10}>
      <div className="dashboard__payment-btn__title">
        <Skeleton.Avatar active />
        <Skeleton.Input active block className="card-title" />
      </div>
      <Col span={16}>
        <div className="dashboard__payment-btn__content"><Skeleton active title={false} /></div>
      </Col>
      <div className="dashboard__payment-btn__description">
        <Skeleton active title={false} />
      </div>
      <div className="dashboard__payment-btn__button -custom w-30">
        <Skeleton.Button active block shape="round" />
      </div>
    </Col>
  );

  return (
    <div className="dashboard__payment-btn">
      <Row>
        {loading && renderSkeletonLoading()}
        {!loading &&
          <Col span={10}>
            <div className="dashboard__payment-btn__title">
              {loading &&
                <>
                  <Skeleton.Avatar active />
                  <Skeleton.Input active block className="card-title" />
                </>
              }
              {!loading &&
                <>
                  <img
                    src={paybuttonIcon}
                    alt="icon"
                    width="40px"
                    height="40px"
                  />
                  <p className="mb-0">Payment Buttons</p>
                </>
              }
            </div>
            <Col span={16}>
              <p className="dashboard__payment-btn__content">A single button is all it takes.</p>
            </Col>
            <p
              className="dashboard__payment-btn__description"
            >
              Activate the payment button now to enjoy making payment transactions with just a one click!
            </p>
            <RPButton
              ghost
              className="dashboard__payment-btn__button -custom"
              onClick={() => openPath()}
            >
              Create Button
            </RPButton>
          </Col>
        }
      </Row>
    </div>
  );
}

DashboardPmbutton.propTypes = {
  loading: PropTypes.bool,
};

export default DashboardPmbutton;
