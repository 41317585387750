import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, notification } from 'antd';

import RPTextArea from '@/components/RPTextArea';
import RPCheckbox from '@/components/RPCheckbox';
import RPButton from '@/components/RPButton';
import MerchantModeBadge from '@/components/MerchantModeBadge';
import { formatter } from '@/utils';
import { DEFAULT_CURRENCY } from '@/constants';
import DetailFailed from './DetailFailed';
import DetailCaptured from './DetailCaptured';
import RPInputAmount from '../RPInputAmount';

function Detail({ data }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [refundInstantly, setRefundInstantly] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [classMask, setClassMask] = useState('');

  const onFinish = (values) => {
    console.log('Success:', values);
    setIsModalVisible(false);
    setClassMask('user-layout__mask');
    notification.success({
      message: 'Refund Issued',
      description: 'You\'ve issued refund successfully',
      placement: 'bottom',
      duration: 2,
      className: 'rp-message',
      onClose: () => {
        setClassMask('');
        setRefundInstantly(false);
      }
    });
  };

  return (
    <div className="dashboard-transactions">
      <div className={classMask} />
      <MerchantModeBadge />
      {
        data.status === 'Failed' ?
          (<DetailFailed showModal={showModal} data={data} />) :
          (<DetailCaptured showModal={showModal} data={data} />)
      }
      <Modal
        title="Refund Payment"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="rp-modal"
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          onFinish={onFinish}
          noValidate
          initialValues={{ refundAmount: data.amount }}
        >
          <Form.Item
            name="refundAmount"
            rules={[
              { required: true, message: 'Please input your refund amount' },
              () => ({
                validator(_, value) {
                  if (value && value <= 0) {
                    return Promise.reject(new Error('Refund amount has greater than 0'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            wrapperCol={{ span: 24 }}
            className="mb-0"
          >
            <RPInputAmount
              name="refundAmount"
              label="Refund Amount"
              placeholder="Enter Amount"
              type="number"
              prefix={formatter.currencySymbol(DEFAULT_CURRENCY)}
              hint="This will be a full refund. Change amount for partial refund."
              required
            />
          </Form.Item>
          <RPCheckbox
            name="refundInstantly"
            checked={refundInstantly}
            className="dashboard-transactions__checkbox --custom"
            onChange={e => {
              setRefundInstantly(e.target.checked);
            }}
          >
            Refund Instantly
            {refundInstantly &&
              <span className="dashboard-transactions__checkbox__hint">{formatter.formatCurrency(5.89)} Fee</span>
            }
          </RPCheckbox>
          {refundInstantly &&
            <p
              className="dashboard-transactions__checked-refund"
            >
              Total amount deductible: {formatter.formatCurrency(15.89)}
            </p>
          }
          <Form.Item
            name="comment"
            wrapperCol={{ span: 24 }}
          >
            <RPTextArea
              placeholder="Add Comment"
              label="Comment"
              rows={4}
              name="comment"
            />
          </Form.Item>
          <RPButton
            htmlType="submit"
            gradient
            center
          >
            Proceed with Refund
          </RPButton>
        </Form>
      </Modal>
    </div>
  );
}

Detail.propTypes = {
  data: PropTypes.object,
};

export default Detail;
