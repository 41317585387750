import { PaymentLinkStatus } from '@/constants/paymentlink';
import moment from 'moment';
import { formatter } from '.';

export const parsePayloadPaymentLink = data => {
  const payload = {
    amount: Number((parseFloat(data.amount) * 100).toFixed()),
    description: data.paymentFor,
    discountCode: data.promotionCoupon,
    customerEmail: data.email,
    customerPhone: data.phoneNo,
    note: data.note,
    refId: data.referenceId,
  };

  if (!data.noExpiryDate) {
    payload.expireDate = data.linkExpiry?.utc().format('YYYY-MM-DD HH:mm:ss');
  } else {
    delete payload.expireDate;
  }

  if (data.id) {
    payload.id = data.id;
  }

  return payload;
};

export const parseFormEditPaymentLink = data => {
  const paymentDetails = {
    amount: data.amount / 100,
    paymentFor: data.description,
    promotionCoupon: data.discountCode,
    noExpiryDate: true,
    linkExpiry: moment(),
  };
  if (data.expireDate) {
    paymentDetails.noExpiryDate = false;
    paymentDetails.linkExpiry = moment(new Date(data.expireDate));
  }
  const customerDetails = {
    email: data.customerEmail,
    phoneNo: data.customerPhone,
    note: data.note,
    referenceId: data.refId,
  };
  return { paymentDetails, customerDetails };
};

export const optsStatusPaymentLink = [
  { value: 1, label: 'Active' },
  { value: 2, label: 'Paid' },
  { value: 3, label: 'Cancelled' },
  { value: 4, label: 'Expired' },
];

export const parseDetailLink = res => {
  const reminder = res.expireDate ? 'before expiration date' : 'after creation';
  const obj = {
    id: res.id,
    linkType: '--',
    paymentFor: res.description,
    status: PaymentLinkStatus[res.status],
    amount: formatter.formatCurrency(res.amount / 100),
    amountPaid: PaymentLinkStatus[res.status] === 'Paid' ? formatter.formatCurrency(res.amount / 100) : '--',
    linkUrl: res.url,
    contactEmail: res.customerEmail,
    contactNumber: res.customerPhone ? `+${res.customerPhone}` : '',
    reminders: res.remindAfter &&
      res.remindAfter > 0 ?
      `${res.remindAfter} day${res.remindAfter > 1 ? 's' : ''} ${reminder}`
      : 'Reminders are not set for payment links.',
    referenceId: res.refId,
    createdBy: res.createdBy?.name,
    createdAt: formatter.parseDateTime(res.createdAt),
    hasExpiry: false,
    expiresOn: '--',
    notes: res.note,
    remindAfter: res.remindAfter
  };

  if (res.expireDate) {
    obj.expiresOn = formatter.parseDate(res.expireDate);
    obj.expiredAt = res.expireDate;
    obj.hasExpiry = true;
  }

  return obj;
};
