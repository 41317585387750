import React from 'react';
import Routes from '@/routes';
import { BrowserRouter as Router } from 'react-router-dom';

import ScrollToTop from '@/components/ScrollToTop';
import './App.scss';

function App() {
  return (
    <Router>
      <div id='notify-message__mask' />
      <ScrollToTop />
      <Routes />
    </Router>
  );
}

export default App;
