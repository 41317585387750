import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
} from 'antd';
import { Icon } from '@iconify/react';
import RPInput from '@/components/RPInput';
import RPButton from '@/components/RPButton';
import { hasSpecialCharacters } from '@/utils/validate';

function PPInputField({
  label,
  index,
  onCancel,
  onUpdate,
  onDelete,
  deletable,
  isEditing,
  onMoveUp,
  onMoveDown,
  editable,
  hideMoveUp = false,
  hideMoveDown = false,
}) {
  const [editing, setEditing] = useState(isEditing);
  const [newLabel, setNewLabel] = useState(label);
  const [hasError, setHasError] = useState(!label);
  const inputFieldRef = useRef();
  const [error, setError] = useState({});

  const handleOnDelete = () => {
    onDelete(index);
  };

  const handleOnClickEdit = () => {
    inputFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setEditing(true);
  };

  const onSave = () => {
    // save value to parent component
    onUpdate(newLabel, index);
    setEditing(false);
  };

  const onClickCancel = () => {
    setNewLabel(label);
    setEditing(false);
    onUpdate(label, index);
    onCancel(index);
  };

  const onChangeLabel = e => {
    const { value } = e.target;
    setNewLabel(value);

    if (!value || value.trim() === '') {
      setHasError(true);
      setError({
        message: 'can\'t be blank'
      });
    } else if (hasSpecialCharacters(value)) {
      setHasError(true);
      setError({
        message: 'shouldn\'t contain special character'
      });
    } else {
      setHasError(false);
      setError({});
    }
  };

  const showEditMode = () => {
    inputFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setEditing(true);
  };

  useEffect(() => {
    document.body.classList.toggle('disable-scroll', editing);
    document.getElementById('rp-pp-fc-right')?.classList?.toggle('disable-scroll', editing);
  }, [editing]);

  const fieldWrapperClass = () => {
    let klass = 'field-wrapper';
    klass = editing ? `${klass} --editing` : klass;
    klass = editable ? `${klass} --editable` : klass;

    return klass;
  };

  return (
    <div>
      {!!editing && <div className='user-layout__mask' />}

      <div ref={inputFieldRef} className={fieldWrapperClass()}>
        {editable &&
          <div className='field-wrapper__actions'>
            {!editing && (
              <>
                <Button
                  type="link"
                  onClick={handleOnClickEdit}
                >
                  <Icon icon="bxs:pencil" />
                </Button>

                {!hideMoveUp &&
                  <Button
                    type="link"
                    onClick={() => onMoveUp(index)}
                  >
                    <Icon icon="ep:arrow-up" />
                  </Button>
                }

                {!hideMoveDown &&
                  <Button
                    type="link"
                    onClick={() => onMoveDown(index)}
                  >
                    <Icon icon="ep:arrow-down" />
                  </Button>
                }
              </>
            )}

            {!editing && deletable &&
              <Button
                type="link"
                onClick={handleOnDelete}
              >
                <Icon icon="akar-icons:cross" />
              </Button>
            }
          </div>
        }
        <Row gutter={24}
          onClick={editable ? showEditMode : null}
        >
          <Col span={24}>
            <div className='label-custom'>
              {!editing && <span>{newLabel}</span>}
              {!!editing &&
                <>
                  <RPInput
                    required
                    status={hasError ? 'error' : ''}
                    value={newLabel}
                    label='Field Label'
                    placeholder='Field Label'
                    onChange={onChangeLabel}
                    maxLength={60}
                  />
                  {Object.keys(error).length > 0 &&
                    <span className='error-text font-neurial-grotesk'>{`Field Label ${error.message}`}</span>
                  }
                </>
              }
            </div>
            <RPInput
              placeholder='To be filled by customer'
              disabled
            />

            {!!editing &&
              <div className='payment-page__new__group-input--editing'>
                <RPButton
                  grey
                  onClick={onClickCancel}
                >
                  Cancel
                </RPButton>
                <RPButton
                  gradient
                  onClick={onSave}
                  disabled={hasError}
                >
                  Save
                </RPButton>
              </div>
            }
          </Col>
        </Row>
      </div>
    </div>
  );
}

PPInputField.propTypes = {
  label: PropTypes.string,
  hideMoveUp: PropTypes.bool,
  hideMoveDown: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isEditing: PropTypes.bool.isRequired,
  deletable: PropTypes.bool.isRequired,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  editable: PropTypes.bool,
};

export default PPInputField;
