/* eslint-disable max-len */
import React from 'react';
import { ReactComponent as DashboardSp } from '@/assets/images/dashboard/dashboard-sp.svg';
import { ROUTES } from '@/constants';
import { getAuthUser } from '@/utils/localStorageUtil';

const user = getAuthUser();

export const DashboardData = {
  StatusNew: {
    pageHeader: {
      supportElementImageNode: <DashboardSp />,
      title: 'Enjoy full access and features when you activate your Radical Pay account now.',
      titleBtn: 'Continue',
      title1: 'RadicalPay',
      title2: user?.merchant?.contactName,
      kycPath: ROUTES.APP.KYC_PATH,
    },
    cardTitle: 'Your Account',
    process01: {
      title: 'Test Mode Payments',
      description: '<p className="mb-0">View all payments received while on Test Mode <a href="#">here</a></p>',
    },
    process02: {
      title: 'Live Payments and Settlements',
      description: 'Complete your KYC details to enable full payment and settlement features.',
    },
    process03: {
      title: 'Activate account',
      description: 'Enjoy full access when your KYC submission is approved!',
    },
    buttonInfo: {
      text: 'Submit details',
      url: ROUTES.APP.KYC_PATH,
    }
  },
  NotSubmitKYC: {
    pageHeader: {
      supportElementImageNode: <DashboardSp />,
      title: 'Activate your account for our complete product features',
      titleBtn: 'Complete',
      title1: 'RadicalPay',
      title2: user?.merchant?.contactName,
      kycPath: ROUTES.APP.KYC_PATH,
    },
    cardTitle: 'Your Account Activation',
    process01: {
      title: 'Test Mode Enabled',
      description: '<p className="mb-0">View all payments received while on Test Mode <a href="#">here</a></p>',
    },
    process02: {
      title: 'Submit KYC',
      description: 'Complete your KYC details to enable full payment and settlement features.',
    },
    process03: {
      title: 'Account Activation',
      description: 'Enjoy full access when your KYC submission is approved!',
    },
    buttonInfo: {
      text: 'Submit KYC',
      url: ROUTES.APP.KYC_PATH,
    }
  },
  StatusKYCSubmitted: {
    pageHeader: {
      supportElementImageNode: <DashboardSp />,
      title: 'Your KYC form has been submitted successfully!',
      title1: 'RadicalPay',
      title2: user?.merchant?.contactName,
      description: 'Your application is now awaiting review and approval. Stay tuned!'
    },
  },
  StatusActivated: {
    pageHeader: {
      supportElementImageNode: <DashboardSp />,
      title: 'Account Activated!',
      title1: 'RadicalPay',
      title2: user?.merchant?.contactName,
      description: 'Your application has been approved!',
      titleBtn: 'Preview KYC',
      kycPath: `${ROUTES.APP.KYC_COMPLETED}?current_step=1`,
    },
  },
  StatusRejected: {
    pageHeader: {
      supportElementImageNode: <DashboardSp />,
      title: 'KYC Clarification',
      title1: 'RadicalPay',
      title2: user?.merchant?.contactName,
      titleBtn: 'Update Details',
      kycPath: ROUTES.APP.KYC_PATH,
    },
    cardTitle: 'Your Account',
    process01: {
      title: 'Test Mode Payments',
      description: '<p className="mb-0">View all payments received while on Test Mode <a href="#">here</a></p>',
    },
    process02: {
      title: 'Live Payments and Settlements',
      description: 'Complete your KYC details to enable full payment and settlement features.',
    },
    process03: {
      title: 'Activate account',
      description: 'Enjoy full access when your KYC submission is approved!',
    },
    buttonInfo: {
      text: 'Update Details',
      url: ROUTES.APP.KYC_PATH,
    }
  }
};
