import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

function ContentSettlementDetails({ data }) {
  return (
    <div className="my-account__profile__modal__settlement">
      <div>
        <p className="my-account__profile__modal__settlement__label">
          Default Payment Settlement Cycles
        </p>
        <div className="my-account__profile__modal__settlement__group">
          <p>Domestic Payments</p>
          <p>{data.domesticPayments}</p>
        </div>
        <div className="my-account__profile__modal__settlement__group">
          <p>International Payments</p>
          <p>{data.internationalPayments}</p>
        </div>
      </div>
      <div>
        <p className="my-account__profile__modal__settlement__label">
          Default Payment Settlement Cycles
        </p>
        <div className="my-account__profile__modal__settlement__group">
          <p>Refunds</p>
          <p>{data.refunds}</p>
        </div>
      </div>
      <div>
        <p className="my-account__profile__modal__settlement__hint">
          *T indicates the date of payment capture
        </p>
        <p className="my-account__profile__modal__settlement__hint">
          Note: Bank holidays are not regarded as working days.
        </p>
      </div>
    </div>
  );
}

ContentSettlementDetails.propTypes = {
  data: PropTypes.object,
};

export default ContentSettlementDetails;
