import React from 'react';

import PageHeader from '@/components/PageHeader';
import PaymentLink from '@/containers/PaymentLink';
import { ReactComponent as PaymentLinkSP } from '@/assets/images/payment-links/paylink-sp.svg';
import MerchantModeBadge from '@/components/MerchantModeBadge';

import './index.scss';

const pageHeaderData = {
  supportElementImageNode: <PaymentLinkSP />,
  title: 'Provide straightforward access to payments',
  description: 'Make payments easy and convenient for your customers with a payment link!',
  title1: 'Your Payment Links',
};

function PaymentLinkPage() {
  return (
    <>
      <PageHeader
        withImage
        {...pageHeaderData}
        className="payment-link__header"
      />
      <div
        className="user-portal-payment-link__container"
      >
        <MerchantModeBadge />
        <PaymentLink />
      </div>
    </>
  );
}

export default PaymentLinkPage;
