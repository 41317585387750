import React from 'react';
import PaymentButton from '@/containers/PaymentButton';

function PaymentButtonPage() {
  return (
    <PaymentButton />
  );
}

export default PaymentButtonPage;
