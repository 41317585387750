/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Result,
} from 'antd';
import PropTypes from 'prop-types';
import { ROUTES } from '@/constants';
import maskBg from '@/assets/images/login-register/mask.svg';
import './SentResetPWInstruction.scss';

function SentResetPWInstruction({
  email,
}) {
  return (
    <div className='reset-pw-sent'>
      <Row>
        <Col
          span={12}
          className='reset-pw-sent__left'
        >
          <div className='reset-pw-sent__left__container'>
            <div className='reset-pw-sent__left__content'>
              <div className='reset-pw-sent__left__content__title'>Password reset link sent</div>
              <div className='reset-pw-sent__left__content__desc'>
                Check your email (<span className='fw-bold'>{email}</span>) for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.
              </div>
              <Result
                status="success"
                subTitle=""
              />
            </div>
          </div>

          <div className='actions'>
            <Link to={ROUTES.LOGIN_PATH}>Return To Sign In</Link>
          </div>
        </Col>
        <Col
          span={12}
          className='reset-pw-sent__right'
        >
          <div className='reset-pw-sent__right__container'>
            <div className='reset-pw-sent__right__super-title'>Supercharge</div>
            <div className='reset-pw-sent__right__desc'>your business today</div>
          </div>
          <div className='reset-pw-sent__right__image'>
            <img
              src={maskBg}
              alt='mask bg'
              width='100%'
              height='100%'
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

SentResetPWInstruction.propTypes = {
  email: PropTypes.string,
};

export default SentResetPWInstruction;
