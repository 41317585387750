import http from '@/http';
import {
  parse,
  messageNotifyUtil,
} from '@/utils';
import { ApiSetting } from '@/constants/api';

export async function getSettingReminder() {
  try {
    const { status, data } = await http.get(ApiSetting.reminder);
    if (status === 200) {
      return {
        success: true,
        data: parse.camelize(data)
      };
    }
    return {
      success: false,
      data: null
    };
  } catch (error) {
    if (error.response.status === 404) {
      return {
        success: true,
        data: null
      };
    }
    messageNotifyUtil.setErrorMessage({
      title: error.response.data.code,
      description: error.response.data.message,
    });
    return {
      success: false,
      data: null
    };
  }
}

export async function updateSettingReminder({
  enablePaymentLink,
  remindAfterWithoutExpiryDate,
  remindAfterWithExpiryDate,
  channel
}) {
  const params = parse.snakelize({
    enablePaymentLink,
    remindAfterWithoutExpiryDate: remindAfterWithoutExpiryDate || 0,
    remindAfterWithExpiryDate: remindAfterWithExpiryDate || 0,
    channel: channel ? [channel] : null,
  });
  try {
    const { status, data } = await http.post(ApiSetting.reminder, params);
    if (status === 200) {
      return {
        success: true,
        data: parse.camelize(data)
      };
    }
    return {
      success: false,
      data: null
    };
  } catch (error) {
    if (error.response.status === 404) {
      return {
        success: true,
        data: null
      };
    }
    messageNotifyUtil.setErrorMessage({
      title: error.response.data.code,
      description: error.response.data.message,
    });
    return {
      success: false,
      data: null
    };
  }
}
