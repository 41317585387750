import React, { useState } from 'react';
import {
  Button,
} from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { uniqueId } from 'lodash';
import PageHeader from '@/components/PageHeader';
import PaymentButton from '@/components/PaymentButton';
import { getPaymentButtons } from '@/services/PaymentButtonService';
import { PaymentButtonStatus } from '@/constants/paymentButton';
import { formatCurrency, parseDateTime } from '@/utils/formatter';
import { ROUTES } from '@/constants';
import { ReactComponent as PaymentButtonSP } from '@/assets/images/payment-buttons/paybutton-sp.svg';
import MerchantModeBadge from '@/components/MerchantModeBadge';

import './index.scss';

const pageHeaderData = {
  supportElementImageNode: <PaymentButtonSP />,
  title: 'Receive payments in a click',
  description: 'Create a payment button now to provide your customers a convenient way to pay you!',
  title1: 'Your Payment Buttons',
};

function PaymentButtonContainer() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });
  const [totalPages, setTotalPage] = useState(10);
  const [selectedButton, setSelectedButton] = useState({});

  const parseItemName = items => items.map(item => <div key={uniqueId()}>{item.label}</div>);

  const fetchData = async (pagy) => {
    setLoading(true);
    const res = await getPaymentButtons(pagy);
    setData(res.data?.map(obj => ({
      ...obj,
      key: obj.id,
      itemName: parseItemName(obj.items || []),
      createdAt: parseDateTime(obj.createdAt),
      status: PaymentButtonStatus[obj.status],
      totalSales: formatCurrency(obj.totalSales / 100)
    })));
    setTotalPage(res.pagination.totalPages);
    setLoading(false);
  };

  const renderBold = (text, record) => (
    <Link
      className="text-link"
      to={`${ROUTES.APP.PAYMENT_BUTTONS.INDEX}/${record.id}`}
    >
      {text}
    </Link>
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleShowModalButtonCode = (record) => {
    setSelectedButton(record);
    setIsModalVisible(true);
  };
  const onChangeTable = (params) => {
    const obj = {
      ...filter,
      ...params,
    };
    setFilter(obj);
    fetchData(obj);
  };
  const renderButton = (_, record) =>
    <Button onClick={() => handleShowModalButtonCode(record)} className='button-custom'>Button Code</Button>;

  const renderStatus = (text) => {
    const status = text === 'Active' ? 'active' : 'failed';

    return <span className={`text-status-${status}`}>{text}</span>;
  };
  const columns = [
    {
      title: 'Button Title',
      dataIndex: 'title',
      key: 'title',
      render: renderBold,
      fixed: 'left',
    },
    {
      title: 'Total Sales',
      dataIndex: 'totalSales',
      key: 'totalSales',
    },
    {
      title: 'Item Name',
      key: 'itemName',
      dataIndex: 'itemName',
    },
    {
      title: 'Units Sold',
      key: 'unitsSold',
      dataIndex: 'unitsSold',
      width: 100
    },
    {
      title: 'Created On',
      key: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: renderStatus,
      width: 130,
      align: 'center'
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      fixed: 'right',
      render: renderButton,
      width: 145,
      align: 'center'
    },
  ];

  const search = async (values) => {
    const newFilter = filter;
    if (values.status) {
      newFilter.status = values.status;
    } else {
      delete newFilter.status;
    }

    if (values.title) {
      newFilter.title = values.title;
    } else {
      delete newFilter.title;
    }

    if (values.daterange?.length === 2) {
      const [from, to] = values.daterange;
      newFilter.createdFrom = moment(`${from.format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD hh:mm:ss').utc().format();
      newFilter.createdTo = moment(`${to.format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD hh:mm:ss').utc().format();
    } else {
      delete newFilter.createdFrom;
      delete newFilter.createdTo;
    }

    setFilter(newFilter);
    fetchData(newFilter);
  };

  return (
    <>
      <PageHeader
        withImage
        {...pageHeaderData}
        className="payment-button__header"
      />
      <div
        className="payment-button__container"
      >
        <MerchantModeBadge />
        <PaymentButton
          loading={loading}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          data={data}
          columns={columns}
          onChangeTable={onChangeTable}
          totalPages={totalPages}
          selectedButton={selectedButton}
          search={search}
        />
      </div>
    </>
  );
}

export default PaymentButtonContainer;
