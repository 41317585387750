import React, { useEffect } from 'react';
import { Form } from 'antd';
import RPInput from '@/components/RPInput';
import RPButton from '@/components/RPButton';
import PropTypes from 'prop-types';
import RPUpload from '@/components/RPUpload';
import './index.scss';

function ContentGSTDetail({
  onSubmit,
  data,
  form,
  certificate,
  setCertificate,
}) {
  const onChangeCertificate = (file) => {
    setCertificate(file);
  };

  useEffect(() => {
    console.log(certificate);
  }, [certificate]);

  return (
    <div className="my-account__profile__modal__gst-detail">
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        noValidate
        onFinish={onSubmit}
        initialValues={data}
      >
        <Form.Item
          wrapperCol={{ span: 24 }}
          label=""
          name="gstin"
          rules={[
            {
              required: true,
              message: 'Please input GSTIN',
            },
          ]}
        >
          <RPInput
            name="gstin"
            placeholder="GSTIN"
            type="gstin"
            label="GSTIN"
            hint="Note: Entered GSTIN will be applicable only from current month onwards."
          />
        </Form.Item>
        <p className="my-account__profile__modal__gst-detail__label">
          GSTIN Certificate
        </p>
        <RPUpload
          accept=".pdf, .png, .jpg"
          listType="picture"
          onChange={onChangeCertificate}
        />
        <RPButton
          className="my-account__profile__modal__submit-btn"
          gradient
          center
          htmlType="submit"
        >
          Submit
        </RPButton>
      </Form>
    </div>
  );
}

ContentGSTDetail.propTypes = {
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  form: PropTypes.any,
  certificate: PropTypes.object,
  setCertificate: PropTypes.func,
};

export default ContentGSTDetail;
