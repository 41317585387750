/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Form
} from 'antd';
import { uniqueId } from 'lodash';

import RPInput from '@/components/RPInput';
import RPButton from '@/components/RPButton';
import RPInputAmount from '@/components/RPInputAmount';
import merchantIcon from '@/assets/images/header/merchant-icon.png';
import logoBlueIcon from '@/assets/images/logo-blue.svg';
import { formatter } from '@/utils';
import { DEFAULT_CURRENCY } from '@/constants';
import { getAuthUser } from '@/utils/localStorageUtil';

import './index.scss';

function DetailViewCard({
  pageDetails,
  paymentDetails,
}) {
  const defaultTAC = 'You agree to share information entered on this page with sachin (owner of this page) and Razorpay, adhering to applicable laws.';
  const { merchant } = getAuthUser();

  const {
    title,
    description,
    supportEmail,
    termAndConditions,
    supportContactNumber,
  } = pageDetails;

  const {
    fields
  } = paymentDetails;

  const renderFieldByType = (field) => {
    const {
      label,
      amount,
    } = field;

    if (field.amountType === 'fixed-amount') {
      return (
        <Form.Item
          key={uniqueId()}
          wrapperCol={{ span: 24 }}
        >
          <RPInputAmount
            label={label}
            placeholder={label}
            required
            value={amount}
            prefix={formatter.currencySymbol(DEFAULT_CURRENCY)}
          />
        </Form.Item>
      );
    }

    return (
      <Form.Item
        key={uniqueId()}
        wrapperCol={{ span: 24 }}
      >
        <RPInput
          className="rp-paypage-detail-view-card__main__input"
          label={label}
          placeholder={label}
        />
      </Form.Item>
    );
  };

  return (
    <Form>
      <div className='rp-paypage-detail-view-card'>
        <div className='rp-paypage-detail-view-card__header'>
          <div className='rp-paypage-detail-view-card__header__logo-group'>
            <div className='rp-paypage-detail-view-card__header__logo-item --radical-pay'>
              <img
                src={logoBlueIcon}
                alt="icon"
                width="100%"
                height="100%"
              />
            </div>
            <div className='rp-paypage-detail-view-card__header__logo-item --merchant'>
              <img
                src={merchant?.companyLogoUrl || merchantIcon}
                alt="icon"
                width="100%"
                height="100%"
              />
              <p className="mb-0">{merchant?.name || 'Business Name'}</p>
            </div>
          </div>
          <div className='rp-paypage-detail-view-card__header__title'>
            {title}
          </div>
          <div className='rp-paypage-detail-view-card__header__desc'>
            {description}
          </div>
        </div>

        <div className='rp-paypage-detail-view-card__main'>
          {fields.length > 0 && fields.map(field => renderFieldByType(field))}

          <RPButton
            gradient
            withArrowIcon
            center
          >
            Pay Now
          </RPButton>
        </div>

        <div className='rp-paypage-detail-view-card__footer'>
          <div className='rp-paypage-detail-view-card__footer__tac'>
            <div className='title'>Terms & Conditions</div>
            <div className='desc'>
              {termAndConditions || defaultTAC}
            </div>
          </div>
          <div className='rp-paypage-detail-view-card__footer__contact-information'>
            <div className='title'>Contact Information</div>
            <div className='info-container'>
              <div>{supportEmail}</div>
              <div>{supportContactNumber}</div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

DetailViewCard.propTypes = {
  pageDetails: PropTypes.object.isRequired,
  paymentDetails: PropTypes.object.isRequired,
};

export default DetailViewCard;
