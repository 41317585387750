import { getKeyByValue } from './parse';

export const ButtonTypes = {
  'Default': 1,
  'Quick Pay': 2,
  'Donations': 3,
  'Buy Now': 4,
  'Custom': 5
};

export const ButtonThemes = {
  'Default': 'rdp_default',
  'Dark': 'rdp_dark',
  'Light': 'rdp_light',
  'Outline': 'rdp_outline',
  'Brand Color': 'rdp_brand_color'
};

// const PaymentItemType = {
//   'fixed-amount': 1,
//   'customers-decide-amount': 2
// };

const CustomerDetailFieldType = {
  'email': 1,
  'single-line-text': 2,
  'number': 3,
  'phone-number': 4,
  'text-area': 5,
  'alphabets': 6,
  'alphanumeric': 7,
};

export const listTypes = [
  { label: 'Single Line Text', value: 'single-line-text', type: 'string' },
  { label: 'Alphabets', value: 'alphabets', type: 'string' },
  { label: 'Alphanumeric', value: 'alphanumeric', type: 'string' },
  { label: 'Email', value: 'email', type: 'email' },
  { label: 'Phone Number', value: 'phone-number', type: 'phone' },
  { label: 'Text area', value: 'text-area', type: 'textarea' },
];

// const parseItem = (field) => {
//   const item = {
//     amount: null,
//     label: field.label,
//     type: PaymentItemType[field.value],
//     optional: field.deletable
//   };
//   if (field.id) {
//     item.id = field.id;
//   }
//   return item;
// };

const parseField = (field) => {
  const type = CustomerDetailFieldType[field.value];
  const item = {
    label: field.label,
    type,
    default: !field.deletable,
  };

  return item;
};

export const parsePaymentButton = data => {
  const customerDetail = [];
  const item = {
    amount: Number((parseFloat(data.amount) * 100).toFixed()),
    label: 'Amount',
    type: 1, // fixed amount
    optional: false,
  };
  data.customerDetail?.forEach((field, index) => {
    customerDetail.push({
      ...parseField(field),
      position: index,
    });
  });

  return {
    type: 2,
    theme: 'rdp_default',
    label: data.buttonLabel,
    title: data.title,
    items: [item],
    customerDetail,
  };
};

export const parsePaymentButtonUpdate = (data, record) => {
  const payload = parsePaymentButton(data);
  const item = {
    id: record.items[0]?.id,
    amount: Number((parseFloat(data.amount) * 100).toFixed()),
    label: 'Amount',
    type: 1, // fixed amount
    optional: false,
  };
  return {
    ...payload,
    items: [item],
    id: record.id,
  };
};

const parseCustomToFieldForm = item => {
  const value = getKeyByValue(CustomerDetailFieldType, item.type);
  const typeObj = listTypes.find(obj => obj.value === value);
  return {
    label: item.label,
    deletable: !item.default,
    type: typeObj.type,
    value,
    editing: false,
    editable: !item.default,
  };
};

export const parseEditPaymentButton = data => {
  const customerDetail = data.customerDetail?.map(parseCustomToFieldForm) || [];
  return {
    ...data,
    id: data.id,
    buttonLabel: data.label,
    amount: (data.items[0].amount / 100),
    customerDetail,
  };
};

export const optsStatusPaymentButton = [
  { value: 1, label: 'Active' },
  { value: 2, label: 'Inactive' },
];
