/* eslint-disable max-len */
const ENV = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  CHECKOUT_PAGE_BASE_URL: process.env.REACT_APP_CHECKOUT_PAGE_BASE_URL
};

const LoadingStatus = {
  idle: 'idle',
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected',
};

const StorageKey = {
  authUser: '@auth:user',
};

const ROUTES = {
  ROOT_PATH: '/',
  LOGIN_PATH: '/signin',
  SIGNUP_PATH: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  UPDATE_PASSWORD: '/update-password',
  CONFIRMATION: '/confirmation',
  APP: {
    DASHBOARD_PATH: '/app/dashboard',
    KYC_PATH: '/app/activation',
    KYC_COMPLETED: '/app/kyc-info',
    TRANSACTION_PATH: '/app/payments',
    TRANSACTION_SHOW_PATH: '/app/payments/:id',
    PAYMENT_PAGES_PATH: '/app/paymentpages',
    PAYMENT_LINKS_PATH: '/app/paymentlinks',
    PAYMENT_LINKS: {
      EMAIL: '/app/paymentlinks/email',
      INDEX: '/app/paymentlinks',
      NEW: '/app/paymentlinks/new',
      SHOW: '/app/paymentlinks/:id',
      EDIT: '/app/paymentlinks/:id/edit',
    },
    PAYMENT_PAGES: {
      SHOW: '/app/paymentpages/:id',
      INDEX: '/app/paymentpages',
      NEW: '/app/paymentpages/new',
      EDIT: '/app/paymentpages/:id/edit',
    },
    PAYMENT_BUTTONS: {
      INDEX: '/app/paymentbuttons',
      NEW: '/app/paymentbuttons/new',
      SHOW: '/app/paymentbuttons/:id',
      EDIT: '/app/paymentbuttons/:id/edit',
    },
    SETTINGS: {
      CONFIGURATIONS: '/app/settings/configurations',
      PATH: '/app/settings',
      INDEX: '/app/settings/:id',
      WEBHOOKS: {
        INDEX: '/app/settings/webhooks',
        SHOW: '/app/settings/webhooks/:id',
      },
      APIKEYS: {
        INDEX: '/app/settings/apikeys'
      },
    },
    MY_ACCOUNT: {
      PATH: '/app/myaccount',
      INDEX: '/app/myaccount/:id',
      PROFILE: '/app/myaccount/profile',
    },
  },
  SERVER_ERROR: '/unavailable-service'
};

const errorMessages = {
  invalidPassword: 'Your password must be at least 8 characters, contain at least 1 uppercase, lowercase, and special character !@#$%^&*',
  noAuth: 'You have entered an invalid username or password',
};

const PAYMENT_METHODS = {
  creditDebitCard: {
    value: 'credit-or-debit',
  },
  upi: {
    value: 'upi',
  },
  qrcode: {
    value: 'qrcode',
  },
  netbanking: {
    value: 'netbanking',
  },
  wallet: {
    value: 'wallet',
  },
  prepaidCard: {
    value: 'prepaid-card',
  },
};

const DEFAULT_CURRENCY = 'INR';
const CHECKOUT_PAGE_BASE_URL = process.env.REACT_APP_CHECKOUT_PAGE_BASE_URL;
const CHECKOUT_ROUTES = {
  PAYMENT_PAGE: {
    VIEW: `${CHECKOUT_PAGE_BASE_URL}/payment-page/:id/view`,
  }
};

const NOTIFCATION_MESSAGE_CONFIG = {
  placement: 'bottom',
  duration: 2.5,
  className: 'rp-message',
};

const AMOUNT_LIMIT = {
  MIN: 0.01,
  MAX: 50000000,
};

const PAYMENT_METHODS_AVAILABLE = {
  CREDIT_DEBIT: 'Credit Debit',
  UPI: 'UPI',
  NETBANKING: 'Netbanking',
  WALLET: 'Wallet',
  PREPAID: 'Prepaid',
};

const RDCP_MODES = {
  LIVE_MODE: 'Live',
  TEST_MODE: 'Test',
};

export {
  ENV,
  RDCP_MODES,
  LoadingStatus,
  StorageKey,
  ROUTES,
  AMOUNT_LIMIT,
  errorMessages,
  PAYMENT_METHODS,
  DEFAULT_CURRENCY,
  CHECKOUT_ROUTES,
  NOTIFCATION_MESSAGE_CONFIG,
  PAYMENT_METHODS_AVAILABLE,
};
