import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Select,
  Spin
} from 'antd';
import { uniqueId } from 'lodash';

import { SettingService } from '@/services';
import { useBeforeRender } from '@/utils';
import RPSelect from '@/components/RPSelect';
import RPRadio from '@/components/RPRadio';
import RPSwitch from '@/components/RPSwitch';
import './index.scss';


const { Option } = Select;

const channelOptions = [
  { value: 'SMS', label: 'SMS' },
  { value: 'Email', label: 'Email' },
];

const days = [1, 2, 3, 4, 5, 7, 10, 14, 21];

function Reminders() {
  const [on, setOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remindAfterWithoutExpiryDate, setRemindAfterWithoutExpiryDate] = useState(0);
  const [remindAfterWithExpiryDate, setRemindAfterWithExpiryDate] = useState(0);
  const [valueChannel, setValueChannel] = useState();
  const onSwitch = e => {
    setOn(e);
  };

  const fetchData = async () => {
    setLoading(true);
    const { success, data } = await SettingService.getSettingReminder();
    if (success && data) {
      console.log(data);
      setOn(data.enablePaymentLink);
      setRemindAfterWithoutExpiryDate(data.remindAfterWithoutExpiryDate || 0);
      setRemindAfterWithExpiryDate(data.remindAfterWithExpiryDate || 0);
      setValueChannel(data.channel ? data.channel[0] : null);
    }
    setLoading(false);
  };

  const updateSetting = async (params) => {
    await SettingService.updateSettingReminder(params);
  };

  useBeforeRender(() => fetchData(), []);

  useEffect(() => {
    if (!loading) {
      updateSetting({
        enablePaymentLink: on,
        remindAfterWithExpiryDate,
        remindAfterWithoutExpiryDate,
        channel: valueChannel
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [on, remindAfterWithoutExpiryDate, remindAfterWithExpiryDate, valueChannel]);

  const onChangeWithExpiry = (data) => {
    setRemindAfterWithExpiryDate(data);
  };

  const onChangeWithoutExpiry = (data) => {
    setRemindAfterWithoutExpiryDate(data);
  };

  const changeChannel = e => {
    setValueChannel(e.target.value);
  };

  return (
    <Spin spinning={loading}>
      <div className="settings__reminders">
        <Row gutter={20}>
          <Col md={{ span: 24 }} xl={{ span: 12 }} xxl={{ span: 11 }}>
            <div className='settings__reminders__group'>
              <p className='settings__reminders__label mb-0'>Payment Links Reminders</p>
              <RPSwitch
                defaultChecked
                onChange={onSwitch}
                label={on ? 'ON' : 'OFF'}
                checked={on}
              />
            </div>
            {on && (
              <>
                <p>For links with an expiration date</p>
                <div className="payment-link__expiry-day">
                  <RPSelect
                    value={remindAfterWithExpiryDate}
                    onChange={onChangeWithExpiry}
                    className="payment-link__expiry-day__select --custom"
                  >
                    {days.map((item) => (
                      <Option key={uniqueId('expire-date-option-')} value={item}>
                        {item}
                      </Option>
                    ))}
                  </RPSelect>
                  <p className="payment-link__expiry-day__label">days before expiration date</p>
                </div>
                <p>For links without an expiration date</p>
                <div className="payment-link__expiry-day">
                  <RPSelect
                    value={remindAfterWithoutExpiryDate}
                    onChange={onChangeWithoutExpiry}
                    className="payment-link__expiry-day__select --custom"
                  >
                    {days.map((item) => (
                      <Option key={uniqueId('without-expire-date-option-')} value={item}>
                        {item}
                      </Option>
                    ))}
                  </RPSelect>
                  <p className="payment-link__expiry-day__label">days after creation</p>
                </div>
              </>
            )}
            <div className='settings__reminders__channel'>
              <p className='settings__reminders__label'>Channel</p>
              <RPRadio
                value={valueChannel}
                onChange={changeChannel}
                options={channelOptions}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

Reminders.propTypes = {};

export default Reminders;
