import React from 'react';
import PropTypes from 'prop-types';
import RPInput from '@/components/RPInput';
import RPTextArea from '@/components/RPTextArea';
import { formatter } from '@/utils';
import { DEFAULT_CURRENCY } from '@/constants';

function PreviewInput({ type, label }) {
  const renderInput = () => {
    switch (type) {
      case 'email':
        return <EmailInput label={label} />;
      case 'phone':
        return <StringInput label={label} value="+66 053 056 1120" />;
      case 'amount':
        return <NumberInput label={label} prefix={formatter.currencySymbol(DEFAULT_CURRENCY)} value="45.00" />;
      case 'textarea':
        return (
          <TextareaInput
            label={label}
            value="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
          />
        );
      default:
        return (
          <StringInput
            label={label}
            value="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
          />
        );
    }
  };

  return renderInput();
}

PreviewInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
};

export default PreviewInput;

function EmailInput({ label }) {
  return (
    <RPInput
      className="dashboard-kyc__card__right__input --custom payment-button__new__preview__card__form__input"
      label={label}
      placeholder="Email"
      required
      value="Johndoe@gmail.com"
      disabled
    />
  );
}
EmailInput.propTypes = {
  label: PropTypes.string,
};

function NumberInput({ label, prefix, value }) {
  return (
    <RPInput
      className="dashboard-kyc__card__right__input --custom payment-button__new__preview__card__form__input"
      label={label}
      textPrefix={prefix}
      required
      value={value}
      type="number"
      disabled
    />
  );
}
NumberInput.propTypes = {
  label: PropTypes.string,
  prefix: PropTypes.string,
  value: PropTypes.string,
};

function StringInput({ label, value }) {
  return (
    <RPInput
      className="dashboard-kyc__card__right__input --custom payment-button__new__preview__card__form__input"
      label={label}
      required
      value={value}
      disabled
    />
  );
}
StringInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

function TextareaInput({ label, value }) {
  return (
    <RPTextArea
      className="payment-button__new__preview__card__form__input"
      placeholder="Business Description"
      label={label}
      rows={4}
      required
      value={value}
      name="description"
      disabled
    />
  );
}
TextareaInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};
