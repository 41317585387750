import React, { useState } from 'react';
import {
  Space,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@/constants';

import RPButton from '@/components/RPButton';
import CardComponent from '@/components/CardComponent';
import TabelComponent from '@/components/TableComponent';
import paymentPagesIcon from '@/assets/images/payment-pages/payment-pages.svg';
import FilterComponent from '@/components/Filter';
import { optsStatusPaymentPage } from '@/utils/paymentPage';
import MerchantModeBadge from '@/components/MerchantModeBadge';

import PropTypes from 'prop-types';
import './index.scss';

function List({
  data,
  columns,
  loading,
  onChangeTable,
  totalPages,
  search
}) {
  const history = useHistory();

  const handleOnClickCreatePage = () => {
    history.replace(ROUTES.APP.PAYMENT_PAGES.NEW);
  };

  const [showFilter, setShowFilter] = useState(false);
  const handleOnClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };


  return (
    <div className="rp__payment-pages__index-container">
      <MerchantModeBadge />
      <CardComponent
        icon={paymentPagesIcon}
        title="Payment Pages"
        extra={
          <Space size={[16]}>
            <RPButton onClick={handleOnClickFilter}>Filter</RPButton>
            <RPButton
              danger
              onClick={handleOnClickCreatePage}
            >
              Create Page
            </RPButton>
          </Space>
        }
      >
        <div
          className={`payment-page__filter ${showFilter ? 'd-block' : 'd-none'} `}
        >
          <FilterComponent
            statuses={optsStatusPaymentPage}
            closeFilter={handleCloseFilter}
            search={search}
            searchFieldName='Title'
          />
        </div>
        <TabelComponent
          loading={loading}
          onChangeTable={onChangeTable}
          data={data}
          columns={columns}
          scroll={{ scrollToFirstRowOnChange: true, x: 768 }}
          totalPages={totalPages}
        />
      </CardComponent>
    </div>
  );
}

List.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  onChangeTable: PropTypes.func,
  totalPages: PropTypes.number,
  search: PropTypes.func
};

export default List;
