import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Select,
  Button,
} from 'antd';
import { Icon } from '@iconify/react';
import { uniqueId } from 'lodash';
import RPInput from '@/components/RPInput';
import RPSelect from '@/components/RPSelect';
import RPButton from '@/components/RPButton';
import { listTypes } from '@/utils/paymentButton';
import { hasSpecialCharacters } from '@/utils/validate';

const { Option } = Select;
function AddInput({
  label,
  value,
  onUpdate,
  onCancel,
  index,
  onDelete,
  deletable,
  editable,
  isEditing,
}) {
  const [editing, setEditing] = useState(isEditing);
  const [newValue, setNewValue] = useState(value);
  const [newLabel, setNewLabel] = useState(label);
  const [error, setError] = useState({});
  const [hasError, setHasError] = useState(false);
  const fieldRef = useRef();
  const LABEL_MAX_CHARACTERS = 60;

  const validateLabel = (inputValue) => {
    if (!inputValue || inputValue.trim() === '') {
      setHasError(true);
      setError({
        message: 'Cannot be blank',
      });
      return;
    }

    if (hasSpecialCharacters(inputValue)) {
      setHasError(true);
      setError({
        message: 'Should not contain special character',
      });
      return;
    }

    if (inputValue.length > LABEL_MAX_CHARACTERS) {
      setHasError(true);
      setError({
        message: `Maximum ${LABEL_MAX_CHARACTERS} characters are allowed`,
      });
      return;
    }

    setHasError(false);
    setError({});
  };

  const onChangeLabel = e => {
    const { value: inputValue } = e.target;
    setNewLabel(inputValue);
    validateLabel(inputValue);
  };

  const onChangeType = e => {
    setNewValue(e);
    setHasError(false);
    setError({});
    setNewLabel();
  };

  const onClickCancel = () => {
    setNewLabel(label);
    setNewValue(value);
    setEditing(false);
    setError({});
    setHasError(false);
    onUpdate && onCancel();
  };

  const onSave = () => {
    // save value to parent component
    onUpdate(newValue, newLabel, index);
    setEditing(false);
  };

  const handleOnClickEdit = () => {
    fieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setEditing(true);
  };

  const handleOnDelete = () => {
    onDelete(index);
  };

  const showEditMode = () => {
    fieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setEditing(true);
  };

  const fieldWrapperClass = () => {
    let klass = 'field-wrapper';
    klass = editing ? `${klass} --editing` : klass;
    klass = editable ? `${klass} --editable` : klass;

    return klass;
  };

  const disableSaveBtn = () => (!!editing && !newLabel) || hasError;

  return (
    <div>
      {editing &&
        <div className='user-layout__mask' />
      }

      <div ref={fieldRef} className={fieldWrapperClass()}>
        {editable &&
          <div className='field-wrapper__actions'>
            {!editing && (
              <Button
                type="link"
                onClick={handleOnClickEdit}
              >
                <Icon icon="bxs:pencil" />
              </Button>
            )}

            {!editing && deletable &&
              <Button
                type="link"
                onClick={handleOnDelete}
              >
                <Icon icon="akar-icons:cross" />
              </Button>
            }
          </div>
        }

        <Row
          gutter={24}
          onClick={editable ? showEditMode : null}
        >
          <Col
            sm={{
              span: 12
            }}
          >
            <RPSelect
              placeholder="Field Type"
              label="Field Type"
              name="type"
              className="dashboard-kyc__card__right__input --custom w-100"
              showSearch
              required
              disabled={!editing}
              value={newValue}
              onChange={onChangeType}
            >
              {listTypes.map((data) => (
                <Option key={uniqueId('business-type-option-')} value={data.value}>
                  {data.label}
                </Option>
              ))}
            </RPSelect>
          </Col>
          <Col
            sm={{
              span: 12
            }}
            className="payment-button__new__group-input__group-edit"
          >
            <RPInput
              name="label"
              className="dashboard-kyc__card__right__input --custom"
              label="Field Label"
              placeholder="Field Label"
              required
              disabled={!editing}
              value={newLabel}
              onChange={onChangeLabel}
              status={hasError ? 'error' : ''}
            />
            {Object.keys(error).length > 0 &&
              <span className='error-text'>{error.message}</span>
            }
          </Col>
        </Row>
        <div
          className={
            `${editing ? 'payment-button__new__group-input--editing' : 'payment-button__new__group-input--no-editing'}`
          }
        >
          <RPButton
            grey
            onClick={onClickCancel}
          >
            Cancel
          </RPButton>
          <RPButton
            gradient
            onClick={onSave}
            disabled={disableSaveBtn()}
          >
            Save
          </RPButton>
        </div>
      </div>
    </div>
  );
}

AddInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onUpdate: PropTypes.func,
  index: PropTypes.number,
  onCancel: PropTypes.func,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func,
  editable: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default AddInput;
