import React from 'react';
import PropTypes from 'prop-types';
import RPHeader from '@/components/Header';
import { getAuthUser } from '@/utils/localStorageUtil';
import { AuthService, userService } from '@/services';

function HeaderContainer({
  headerType
}) {
  const user = getAuthUser();

  const handleOnChangeMode = ({ mode }) => userService.switchMode({ mode });

  const handleGetCurrentKycStatus = async () => {
    const res = await AuthService.getProfile();
    if (res.success) {
      return res.data?.kycStatus;
    }
    return null;
  };

  return (
    <RPHeader
      type={headerType}
      user={user}
      onChangeMode={handleOnChangeMode}
      getCurrentKycStatus={handleGetCurrentKycStatus}
    />
  );
}

HeaderContainer.propTypes = {
  headerType: PropTypes.string.isRequired,
};

export default HeaderContainer;
