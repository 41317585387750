/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Button
} from 'antd';
import PropTypes from 'prop-types';
import { ROUTES } from '@/constants';
import maskBg from '@/assets/images/login-register/mask.svg';
import './resend.scss';

function Confirmation({ resendEmail, email }) {
  return (
    <div className='resend-page'>
      <Row>
        <Col
          span={12}
          className='resend-page__left'
        >
          <div className='resend-page__left__container'>
            <div className='resend-page__left__content'>
              <div className='resend-page__left__content__title'>Sign Up Succesfully</div>
              <div className='resend-page__left__content__desc'>We have sent email to {email} to confirm the validity.</div>
              <div className='resend-contaner'>
                <p className='mb-0 desc_text'>Please follow the link provided to complete your registration.</p>
                <p className='mb-0 desc_text'>Didn&rsquo;t receive the email?</p>
                <div>
                  Click
                  <Button
                    type="link"
                    className='btn-link-custom'
                    onClick={() => resendEmail()}
                  >
                    here
                  </Button>
                  to resend.
                </div>
              </div>
            </div>
          </div>
          <div className='actions'>
            <div>Back to login?</div>
            <Link to={ROUTES.LOGIN_PATH}>Login</Link>
          </div>
        </Col>
        <Col
          span={12}
          className='resend-page__right'
        >
          <div className='resend-page__right__container'>
            <div className='resend-page__right__super-title'>Supercharge</div>
            <div className='resend-page__right__desc'>your business today</div>
          </div>
          <div className='resend-page__right__image'>
            <img
              src={maskBg}
              alt='mask bg'
              width='100%'
              height='100%'
            />
          </div>
        </Col>
      </Row>

    </div>
  );
}

Confirmation.propTypes = {
  resendEmail: PropTypes.func,
  email: PropTypes.string
};

export default Confirmation;
