import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import moment from 'moment';
import RPButton from '@/components/RPButton';
import RPPhoneNumber from '@/components/RPPhoneNumber';
import RPInputOTP from '@/components/RPInputOTP';
import { validatePhoneNumber } from '@/utils/validate';
import './index.scss';
import { generateOTP, validateOTP } from '@/services/myAccountService';
import { OTPActions, OTPDeliveryMethod } from '@/constants/account';

function ContentUpdatePhone({
  onSubmit,
  data,
  form,
  isSubmitOTP,
  setIsSubmitOTP,
  dataOTP,
  handleChange,
}) {
  const [otpToken, setOtpToken] = useState('');
  const [otpExpiredAt, setOtpExpiredAt] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [showResendOTP, setShowResendOTP] = useState(false);
  const [reachedExpiresOtpTime, setReachedExpiresOtpTime] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const sendOTP = async () => {
    generateOTP({
      action: OTPActions.OTPActionChangeContactNumber,
      method: OTPDeliveryMethod.OTPDeliveryMethodEmail,
    }).then(({ status, data: dataResult }) => {
      if (status === 200 && dataResult?.expiredTime) {
        setOtpExpiredAt(dataResult.expiredTime);
      };
    });
  };

  useEffect(() => {
    sendOTP();
  }, []);

  useEffect(() => {
    setHasErrored(false);
    setDisabledSubmit(dataOTP.length !== 6);
  }, [dataOTP]);

  useEffect(() => {
    if (otpExpiredAt) {
      const showResendAfter = moment(otpExpiredAt).diff(moment().add(1, 'second'), 'miliseconds');

      setTimeout(() => {
        setShowResendOTP(true);
        setReachedExpiresOtpTime(true);
      }, showResendAfter);
    }
  }, [otpExpiredAt]);

  const handleUpdatePhone = (values) => {
    onSubmit({
      otpToken,
      contactNumber: values.contactNumber,
    });
  };

  const handleResendOTP = async (e) => {
    e.preventDefault();
    setShowResendOTP(false);
    setReachedExpiresOtpTime(false);
    await sendOTP();
  };

  const renderInputPhoneNumber = (
    <div className="my-account__profile__modal">
      <p>Please enter your phone number</p>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        noValidate
        onFinish={handleUpdatePhone}
      >
        <Form.Item
          name="contactNumber"
          rules={[
            {
              required: true,
              message: 'Please input your phone number',
            },
            () => ({
              validator(_, value) {
                if (value && !validatePhoneNumber(value)) {
                  return Promise.reject(new Error('Support Phone Number Invalid'));
                }

                return Promise.resolve();
              },
            }),
          ]}
          wrapperCol={{ span: 24 }}
        >
          <RPPhoneNumber
            name="contactNumber"
            className="rp-payment-pages__form__card__right__input --custom"
            specialLabel="Phone No."
            placeholder="Phone No."
            required
            inputProps={{
              name: 'contactNumber',
              required: true,
            }}
          />
        </Form.Item>
        <RPButton
          className="my-account__profile__modal__submit-btn"
          gradient
          center
          htmlType="submit"
        >
          Submit
        </RPButton>
      </Form>
    </div>
  );

  const handleSubmit = async () => {
    const res = await validateOTP({
      action: OTPActions.OTPActionChangeContactNumber,
      otpCode: dataOTP
    });
    if (res.status === 200) {
      setOtpToken(res.data?.otpToken);
      setIsSubmitOTP(true);
    } else {
      setHasErrored(true);
    }
  };

  const rendererTimerCoundown = ({ formatted: { minutes, seconds } }) => <span>{minutes}:{seconds}</span>;

  const renderInputOTP = (
    <div className="my-account__profile__modal__verification">
      <p>
        Changing mobile number requires you to enter OTP sent over to your registered email address&nbsp;
        <span className='fw-bold'>{data.contactEmail}</span>
      </p>
      <p>
        {!reachedExpiresOtpTime &&
          <>
            The OTP will expire in&nbsp;
            <Countdown
              key={otpExpiredAt}
              renderer={rendererTimerCoundown}
              zeroPadTime={2}
              date={new Date(otpExpiredAt)}
            />
          </>
        }
      </p>
      <div className="my-account__profile__modal__verification__otp">
        <RPInputOTP
          otp={dataOTP}
          hasErrored={hasErrored}
          handleChange={handleChange}
        />
        {!!showResendOTP &&
          <p className="my-account__profile__modal__verification__otp__text">
            Didn&apos;t receive the OTP?
            <br />
            Click <a href="##" onClick={handleResendOTP}>here</a> to resend.
          </p>
        }
      </div>
      <RPButton
        disabled={disabledSubmit}
        className="my-account__profile__modal__submit-btn"
        gradient
        onClick={handleSubmit}
        center
      >
        {!hasErrored ? 'Confirm' : 'Retry'}
      </RPButton>
    </div>
  );

  return isSubmitOTP ? renderInputPhoneNumber : renderInputOTP;
}

ContentUpdatePhone.propTypes = {
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  form: PropTypes.any,
  isSubmitOTP: PropTypes.bool,
  setIsSubmitOTP: PropTypes.func,
  dataOTP: PropTypes.string,
  handleChange: PropTypes.func,
};

export default ContentUpdatePhone;
