import React, { useState } from 'react';
import RPButton from '@/components/RPButton';
import CardComponent from '@/components/CardComponent';
import TableComponent from '@/components/TableComponent';
import {
  Space,
} from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTES } from '@/constants';
import paybuttonGreyIcon from '@/assets/images/sidebar/paybutton-grey-icon.svg';
import FilterComponent from '@/components/Filter';
import { optsStatusPaymentButton } from '@/utils/paymentButton';
import ModalButtonCode from './ModalButtonCode';
import './index.scss';

function PaymentButton({
  loading,
  isModalVisible,
  data,
  columns,
  setIsModalVisible,
  onChangeTable,
  totalPages,
  selectedButton,
  search
}) {
  const history = useHistory();
  const [showFilter, setShowFilter] = useState(false);
  const handleOnClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  return (
    <>
      <CardComponent
        icon={paybuttonGreyIcon}
        title="Payment Buttons"
        extra={
          <Space size={[16]}>
            <RPButton onClick={handleOnClickFilter}>Filter</RPButton>
            <RPButton
              onClick={() => history.replace(ROUTES.APP.PAYMENT_BUTTONS.NEW)}
              danger
            >
              Create Button
            </RPButton>
          </Space>
        }
      >
        <div
          className={`payment-button__filter ${showFilter ? 'd-block' : 'd-none'} `}
        >
          <FilterComponent
            statuses={optsStatusPaymentButton}
            closeFilter={handleCloseFilter}
            search={search}
            searchFieldName='Title'
          />
        </div>
        <TableComponent
          loading={loading}
          onChangeTable={onChangeTable}
          data={data}
          columns={columns}
          scroll={{ scrollToFirstRowOnChange: true, x: 768 }}
          totalPages={totalPages}
        />
      </CardComponent>
      <ModalButtonCode
        buttonData={selectedButton}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
}

PaymentButton.propTypes = {
  isModalVisible: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  setIsModalVisible: PropTypes.func,
  onChangeTable: PropTypes.func,
  totalPages: PropTypes.number,
  loading: PropTypes.bool,
  selectedButton: PropTypes.object,
  search: PropTypes.func
};

export default PaymentButton;
