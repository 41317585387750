import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'antd';
// import { uniqueId } from 'lodash';
// import { ButtonThemes, ButtonTypes } from '@/utils/paymentButton';
import RPInputAmount from '@/components/RPInputAmount';
import RPInput from '@/components/RPInput';
import RPButton from '@/components/RPButton';
// import RPSelect from '@/components/RPSelect';
import { formatter } from '@/utils';
import { AMOUNT_LIMIT, DEFAULT_CURRENCY } from '@/constants';
import { isValidAmount } from '@/utils/validate';
import Preview from './PreviewButtonDetail';

// const { Option } = Select;
// const buttonTypes = Object.keys(ButtonTypes);
// const buttonThemes = Object.keys(ButtonThemes);
function StepCreateButton({
  form,
  setForm,
  onSubmit,
  setShowExitPrompt,
}) {
  const [formInstance] = Form.useForm();

  const [currentAmount, setCurrentAmount] = useState();
  const [hasErrorAmount, setHasErrorAmount] = useState(false);

  const onFinish = (values) => {
    setForm({
      ...values,
      amount: currentAmount
    });
    onSubmit();
  };

  const onChange = (e) => {
    setShowExitPrompt(true);
    if(e.target.name === 'amount') {
      return;
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (form.id) {
      setCurrentAmount(form.amount);
      formInstance.setFieldsValue(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleOnChangeAmount = value => {
    setShowExitPrompt(true);
    setCurrentAmount(value);

    if (isValidAmount(value)) {
      setHasErrorAmount(false);
    } else {
      setHasErrorAmount(true);
    }
  };


  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      onFinish={onFinish}
      onChange={onChange}
      initialValues={form}
      noValidate
      form={formInstance}
    >
      <div className="dashboard-kyc__card">
        <Row>
          <Col
            flex="50%"
            className="dashboard-kyc__card__left payment-button__new__preview"
          >
            <Preview label={form.buttonLabel} />
          </Col>
          <Col flex="50%" className="dashboard-kyc__card__right">
            <Form.Item
              name="title"
              rules={[{ required: true, message: 'Please input your title' }]}
              wrapperCol={{ span: 24 }}
            >
              <RPInput
                name="title"
                className="dashboard-kyc__card__right__input --custom"
                label="Title"
                placeholder="Title"
                required
              />
            </Form.Item>
            {/* <Form.Item
              name="buttonType"
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: 'Please input your button type' },
              ]}
            >
              <RPSelect
                placeholder="Button Type"
                label="Button Type"
                name="buttonType"
                className="dashboard-kyc__card__right__input --custom w-100"
                showSearch
                required
              >
                {buttonTypes.map((type) => (
                  <Option key={uniqueId('business-type-option-')} value={type}>
                    {type}
                  </Option>
                ))}
              </RPSelect>
            </Form.Item> */}
            <Form.Item
              name="amount"
              rules={[
                { required: true, message: 'Please input your amount' },
                () => ({
                  validator(_, value) {
                    if (value !== null && value <= 0) {
                      return Promise.reject(
                        new Error('Amount has greater than 0'),
                      );
                    }

                    if (value && !isValidAmount(value)) {
                      return Promise.reject(
                        new Error(`Amount cannot be greater than ${AMOUNT_LIMIT.MAX}`),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              wrapperCol={{ span: 24 }}
            >
              <RPInputAmount
                name="amount"
                label="Amount"
                placeholder="Amount"
                prefix={formatter.currencySymbol(DEFAULT_CURRENCY)}
                required
                onChange={handleOnChangeAmount}
                value={currentAmount}
                status={hasErrorAmount && currentAmount ? 'error' : ''}
              />
            </Form.Item>
            <Form.Item
              name="buttonLabel"
              rules={[
                { required: true, message: 'Please input your button label' },
                () => ({
                  validator(_, value) {
                    if (value.length > 16) {
                      return Promise.reject(
                        new Error('Maximum 16 characters are allowed'),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              wrapperCol={{ span: 24 }}
            >
              <RPInput
                name="buttonLabel"
                className="dashboard-kyc__card__right__input --custom"
                label="Button Label"
                placeholder="Button Label"
                required
              />
            </Form.Item>
            {/* <Form.Item
              name="buttonTheme"
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: 'Please input your button theme' },
              ]}
            >
              <RPSelect
                placeholder="Button Theme"
                label="Button Theme"
                name="buttonTheme"
                className="dashboard-kyc__card__right__input --custom w-100"
                showSearch
                required
              >
                {buttonThemes.map((type) => (
                  <Option key={uniqueId('business-type-option-')} value={type}>
                    {type}
                  </Option>
                ))}
              </RPSelect>
            </Form.Item> */}
          </Col>
        </Row>
      </div>
      <RPButton right gradient withArrowIcon htmlType="submit">
        <div className="dashboard-kyc__card__submit-btn">Continue</div>
      </RPButton>
    </Form>
  );
}

StepCreateButton.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  onSubmit: PropTypes.func,
  setShowExitPrompt: PropTypes.func,
};

export default StepCreateButton;
