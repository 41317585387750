/* eslint-disable max-len */
import React from 'react';
import { Button, Row, Col, Layout } from 'antd';

import emailBg from '@/assets/images/payment-links/bg-email.svg';
import twitterIcon from '@/assets/images/payment-links/twitter.svg';
import facebookIcon from '@/assets/images/payment-links/facebook.svg';
import linkedinIcon from '@/assets/images/payment-links/linkedin.svg';
import merchantIcon from '@/assets/images/header/merchant-icon.png';
import logoWhite from '@/assets/images/logo-white.svg';

import './email.scss';
import { formatter } from '@/utils';

const { Content } = Layout;

function PaymentLinkPage() {
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
      className="user-layout"
    >
      <div className="payment-link__email__header pb-0">
        <header>
          <img
            src={logoWhite}
            alt="Radical Pay"
            width="117px"
            height="32px"
          />
          <div className="payment-link__email__header__group">
            <img
              src={merchantIcon}
              alt="icon"
              width="32px"
              height="32px"
            />
            <p className="mb-0 fw-bold">Flipkart Pvt Ltd</p>
          </div>
        </header>
      </div>
      <Content className="payment-link__email__content">
        <div
          className="page-header__container"
          style={{
            '--bg-header': `url(${emailBg})`,
          }}
        >
          <div className="payment-link__email__header__content">
            <p className="payment-link__email__header__title">
              You’ve just received a payment link for
            </p>
            <p className="payment-link__email__header__amount">{formatter.formatCurrency(45)}</p>
            <Button className="page-header__button__white -custom payment-link__email__button">
              <div className="mb-0 page-header__button__text">Pay Now</div>
              <div className="mb-0 page-header__button__icon" />
            </Button>
          </div>
        </div>
        <div className="payment-link__email__container">
          <div className="payment-link__email__card">
            <h1 className="payment-link__email__card__title">Payment Details</h1>
            <p className="payment-link__email__card__desc">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum velit felis, ornare in imperdiet quis.
            </p>
            <Row gutter={20} className="payment-link__email__card__data --divide">
              <Col xs={{ span: 8 }}>
                <p className="payment-link__email__card__data__label mb-0">Sender</p>
              </Col>
              <Col xs={{ span: 16 }}>
                <p className="payment-link__email__card__data__value mb-0">Matthew Tan</p>
              </Col>
            </Row>
            <Row gutter={20} className="payment-link__email__card__data --divide">
              <Col xs={{ span: 8 }}>
                <p className="payment-link__email__card__data__label mb-0">Payment For</p>
              </Col>
              <Col xs={{ span: 16 }}>
                <p className="payment-link__email__card__data__value mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum velit felis, ornare in imperdiet quis.</p>
              </Col>
            </Row>
            <Row gutter={20} className="payment-link__email__card__data --divide">
              <Col xs={{ span: 8 }}>
                <p className="payment-link__email__card__data__label mb-0">Receipt No</p>
              </Col>
              <Col xs={{ span: 16 }}>
                <p className="payment-link__email__card__data__value mb-0">TIB-4590-90</p>
              </Col>
            </Row>
            <Row gutter={20} className="payment-link__email__card__data --divide">
              <Col xs={{ span: 8 }}>
                <p className="payment-link__email__card__data__label mb-0">Issued To</p>
              </Col>
              <Col xs={{ span: 16 }}>
                <p className="payment-link__email__card__data__value mb-0">Johndoe@email.com</p>
              </Col>
            </Row>
            <Row gutter={20} className="payment-link__email__card__data">
              <Col xs={{ span: 8 }}>
                <p className="payment-link__email__card__data__label mb-0">Amount</p>
              </Col>
              <Col xs={{ span: 16 }}>
                <p className="payment-link__email__card__data__value mb-0">{formatter.formatCurrency(45)}</p>
              </Col>
            </Row>
          </div>
          <Row className="payment-link__email__info">
            <Col sm={{ span: 14}} xs={{span: 24}}>
              <p className="payment-link__email__info__label">Sign Up with RadicalPay</p>
              <p className="payment-link__email__info__value">You agree to share information entered on this page with sachin (owner of this page) and Razorpay, adhering to applicable laws.</p>
            </Col>
            <Col sm={{span: 8, offset: 2}} xs={{ span: 24}}>
              <div className="payment-link__email__group-icon">
                <img
                  src={twitterIcon}
                  alt="icon"
                  width="24px"
                  height="24px"
                />
                <img
                  src={facebookIcon}
                  alt="icon"
                  width="24px"
                  height="24px"
                />
                <img
                  src={linkedinIcon}
                  alt="icon"
                  width="24px"
                  height="24px"
                />
              </div>
            </Col>
          </Row>
        </div>
        <footer className="payment-link__email__footer">
          <div className="payment-link__email__footer__content">
            <p className="payment-link__email__footer__text">Copyright © 2020</p>
            <p className="payment-link__email__footer__text">RadicalPay Int Pvt Ltd</p>
            <p className="payment-link__email__footer__text">A better company begins with a personalised employee experience.</p>
          </div>
        </footer>
      </Content>
    </Layout>
  );
}

export default PaymentLinkPage;
