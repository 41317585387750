import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { selectAuth } from '@/store/auth/authSelector';
import { oauthLogin, login, resendEmailConfirm, getProfile } from '@/services/authService';
import Login from '@/components/Auth/Login';
import { ROUTES } from '@/constants';
import { Spin } from 'antd';
import ResendEmail from '@/components/Auth/ResendSignIn';

function LoginPage() {
  const auth = useSelector(selectAuth);
  const [loading, setLoading] = useState(false);
  const [resend, setResend] = useState(false);
  const [email, setEmail] = useState('');
  const handleLogin = async (credentials) => {
    setLoading(true);
    const { success, data } = await login(credentials);
    setEmail(credentials.email);
    if (success) {
      getProfile().then(response => {
        if (response.success) {
          window.location.href = ROUTES.APP.DASHBOARD_PATH;
        }
      });
    } else {
      if (data.code === 'ACCOUNT_INACTIVE') {
        setResend(true);
        setLoading(false);
        return;
      }

      setLoading(false);
    }
  };

  const resendEmail = async () => {
    await resendEmailConfirm({ email });
  };

  const backLogin = () => {
    setResend(false);
  };

  const loginOauth = async params => {
    const res = await oauthLogin(params);
    if (res.status === 200) {
      getProfile().then(response => {
        if (response.success) {
          window.location.href = ROUTES.APP.DASHBOARD_PATH;
        }
      });
    }
  };

  const responseFacebook = async response => {
    setLoading(true);
    if (response?.accessToken) {
      const params = {
        provider: 'facebook',
        accessToken: response.accessToken
      };
      await loginOauth(params);
    }
    setLoading(false);
  };

  const responseGoogle = async response => {
    setLoading(true);
    if (response?.accessToken) {
      const params = {
        provider: 'google',
        accessToken: response.accessToken
      };
      await loginOauth(params);
    }
    setLoading(false);
  };

  if (auth.user) {
    return <Redirect to='/' />;
  }

  return (
    <Spin spinning={loading}>
      {
        resend ? (<ResendEmail
          resendEmail={resendEmail}
          backLogin={backLogin}
          email={email}
        />) :
          (<Login
            loading={loading}
            handleLogin={handleLogin}
            responseFacebook={responseFacebook}
            responseGoogle={responseGoogle}
            auth={auth}
          />)
      }
    </Spin>
  );
}

export default LoginPage;
