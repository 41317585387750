import React from 'react';
import PageHeader from '@/components/PageHeader';
import { Tabs } from 'antd';
// import Configurations from '@/components/Settings/Configurations';
// import Webhooks from '@/components/Settings/Webhooks';
// import ApiKeys from '@/components/Settings/ApiKeys';
import Reminders from '@/components/Settings/Reminders';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { ROUTES } from '@/constants';

import './index.scss';

const { TabPane } = Tabs;

function Settings() {
  const history = useHistory();
  const onChange = (key) => {
    history.replace(`${ROUTES.APP.SETTINGS.PATH}/${key}`);
  };
  const pageHeaderData = {
    title: 'Settings',
    description:
      'All of the preferences below are directly associated with your business account.',
  };
  const { id } = useParams();

  return (
    <div className='settings'>
      <PageHeader className='settings__page-header' {...pageHeaderData} />
      <div className="dashboard__container">
        <Tabs
          defaultActiveKey={id || 'configurations'}
          className="settings__tabs"
          onChange={onChange}
        >
          {/* <TabPane tab="Configurations" key="configurations">
            <div className='settings__container'>
              <Configurations />
            </div>
          </TabPane> */}
          {/* <TabPane tab="Webhooks" key="webhooks">
            <div className='settings__container'>
              <Webhooks />
            </div>
          </TabPane>
          <TabPane tab="API Keys" key="apikeys">
            <div className='settings__container'>
              <ApiKeys />
            </div>
          </TabPane> */}
          <TabPane tab="Reminders" key="reminders">
            <div className='settings__container'>
              <Reminders />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default Settings;
