import React, { useState } from 'react';
import RPButton from '@/components/RPButton';
import CardComponent from '@/components/CardComponent';
import TableComponent from '@/components/TableComponent';
import {
  Space,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@/constants';
import paylinkGreyIcon from '@/assets/images/sidebar/paylink-grey-icon.svg';
import PropTypes from 'prop-types';
import FilterComponent from '@/components/Filter';
import { optsStatusPaymentLink } from '@/utils/paymentLink';
import './index.scss';

function PaymentLink({
  data,
  columns,
  loading,
  onChangeTable,
  totalPages,
  search
}) {
  const history = useHistory();

  const [showFilter, setShowFilter] = useState(false);
  const handleOnClickFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  return (
    <CardComponent
      icon={paylinkGreyIcon}
      title="Payment Links"
      extra={
        <Space size={[16]}>
          <RPButton onClick={handleOnClickFilter}>Filter</RPButton>
          <RPButton
            onClick={() => history.replace(ROUTES.APP.PAYMENT_LINKS.NEW)}
            danger
          >
            Create Link
          </RPButton>
        </Space>
      }
    >
      <div
        className={`payment-link__filter ${showFilter ? 'd-block' : 'd-none'} `}
      >
        <FilterComponent
          statuses={optsStatusPaymentLink}
          closeFilter={handleCloseFilter}
          search={search}
          searchFieldName='Customer Email'
        />
      </div>
      <TableComponent
        data={data}
        columns={columns}
        scroll={{ scrollToFirstRowOnChange: true, x: 768 }}
        loading={loading}
        onChangeTable={onChangeTable}
        totalPages={totalPages}
      />
    </CardComponent>
  );
}

PaymentLink.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  onChangeTable: PropTypes.func,
  totalPages: PropTypes.number,
  search: PropTypes.func
};

export default PaymentLink;
