import React from 'react';
import { Input } from 'antd';
import searchIcon from '@/assets/images/settings/search.svg';
import './index.scss';

function RPSwitch({
  ...props
}) {
  return (
    <div className="rp-search">
      <Input
        size="large"
        placeholder="Search"
        prefix={<img
          src={searchIcon}
          alt="icon"
          width="18px"
          height="18px"
        />}
        {...props} />
    </div>
  );
}

RPSwitch.propTypes = {
};

export default RPSwitch;
