import React, { useEffect } from 'react';
import {
  useHistory
} from 'react-router-dom';

import { useAuth } from '@/hooks';
import { ROUTES } from '@/constants';
import Login from '@/containers/Auth/Login';

function LoginPage() {
  const { isAuth } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (isAuth) {
      history.push(ROUTES.APP.DASHBOARD_PATH);
    }
  }, [history, isAuth]);

  return (
    <Login />
  );
}

export default LoginPage;
