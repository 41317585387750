import { RDCP_MODES } from '@/constants';
import { localStorageUtil } from '@/utils';
import React from 'react';

import './index.scss';

function MerchantModeBadge() {
  const user = localStorageUtil.getAuthUser();
  const currentMode = user?.workMode;
  const isLiveMode = currentMode === RDCP_MODES.LIVE_MODE;

  return (
    isLiveMode ? null :
    <div className="merchant-mode-badge__container">
      <span className="merchant-mode-badge__status" />
      You are currently in test mode, activate your account to make transactions and more!
    </div>
  );
}

export default MerchantModeBadge;
