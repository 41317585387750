import React from 'react';
import {
  Tree
} from 'antd';
import './index.scss';


function RPTree({
  ...props
}) {

  return (
    <div className="rp-tree">
      <Tree
        {...props}
      />
    </div>
  );
}

RPTree.propTypes = {
};

export default RPTree;
