import React from 'react';
import {
  useParams,
} from 'react-router-dom';

import Transaction from '@/containers/Transaction';
import './show.scss';

function TransactionsPage() {
  const { id } = useParams();

  return (
    <div className='transaction-show'>
      <Transaction id={id} />
    </div>
  );
}

export default TransactionsPage;
