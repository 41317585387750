/* eslint-disable max-len */
import { AMOUNT_LIMIT } from '@/constants';
import {
  parsePhoneNumberFromString
} from 'libphonenumber-js/max';

export const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export const validatePhoneNumber = phoneNumber => {
  const phone = parsePhoneNumberFromString(`+${phoneNumber}`);
  return phone && phone.isValid();
};

export const validatePassword = password => String(password)
  .match(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
  );
export const validateUpiId = upiId => String(upiId).match(/^\w.+@\w+$/);

export const isValidHttpUrl = (string) => {
  // eslint-disable-next-line no-useless-escape
  const pattern = /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{0,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  return pattern.test(string);
};

export const hasSpecialCharacters = (string) => /[^\w\s]/gi.test(string);

export const isValidAmount = value => value !== null && value !== undefined && !Number.isNaN(value) && value > 0 && value <= AMOUNT_LIMIT.MAX;

export const isSlug = (str) => {
  const charsetRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

  return (charsetRegex.test(str));
};

export const validateEmails = (emails) => {
  const list = emails.split(',');
  return list.filter(email => !validateEmail(email)).length === 0;
};
