import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';

import RPSwitch from '@/components/RPSwitch';
import { ModalProfileTypes } from '@/constants/Profile';
import {
  messageNotifyUtil,
  parse,
  useBeforeRender,
} from '@/utils';
import { logout } from '@/store/auth/authSlice';
import { OTPActions, OTPDeliveryMethod } from '@/constants/account';
import { setAuthUser, getAuthUser } from '@/utils/localStorageUtil';
import MerchantInfo from '@/components/MyAccount/Profile/MerchantInfo';
import Support from '@/components/MyAccount/Profile/Support';
import ModalUpdate from '@/components/MyAccount/Profile/ModalUpdate';
// import GSTDetail from '@/components/Profile/GSTDetail';
// import SettlementDetails from '@/components/Profile/SettlementDetails';

import './index.scss';

function MyProfile({
  update2FA,
  requestOTP,
  validateOTP,
  changeDisplayName,
  getAccountInformation,
  updateAccountPassword,
  changeAccountSupportDetail,
  updateAccountContactNumber,
}) {
  const dispatch = useDispatch();
  const user = getAuthUser();
  const [isUpdate, setIsUpdate] = useState(false);
  const [typeModal, setTypeModal] = useState();
  const showModal = (data) => {
    setTypeModal(data);
    setIsUpdate(true);
  };
  const [pageLoading, setPageLoading] = useState(true);
  const [accountInformation, setAccountInformation] = useState({});
  const [enable2step, setEnable2step] = useState(false);
  const [otpExpiredAt, setOtpExpiredAt] = useState();
  const [otpValid, setOtpValid] = useState(undefined);

  const initData = async () => {
    const { status, data } = await getAccountInformation();

    if (status === 200) {
      setPageLoading(false);
      const result = parse.camelize(data);
      setAccountInformation(result);
      setEnable2step(result.isMfaEnabled);
    }
  };

  useBeforeRender(() => initData(), []);

  const submitUpdatePhone = async (data) => {
    const response = await updateAccountContactNumber(data);

    if (response.status === 200) {
      setIsUpdate(false);
      setAccountInformation({
        ...accountInformation,
        contactNumber: data.contactNumber
      });
      messageNotifyUtil.setSuccessMessage({
        title: 'Mobile Number Updated',
        description: 'Your phone number has been updated.',
      });
    }
  };

  const submitUpdatePassword = async ({ confirmNewPassword, currentPassword, newPassword }) => {
    const params = {
      newPassword,
      oldPassword: currentPassword,
      confirmPassword: confirmNewPassword,
    };
    const response = await updateAccountPassword(params);

    if (response.status === 200) {
      setIsUpdate(false);

      messageNotifyUtil.setSuccessMessage({
        title: 'Password Changed',
        description: 'Your password has been changed',
        onClose: () => {
          dispatch(logout());
        },
      });
    }
  };

  const submitUpdateDisplayName = async ({ displayName }) => {
    const response = await changeDisplayName({ displayName });
    if (response.status === 200) {
      setIsUpdate(false);
      setAccountInformation({
        ...accountInformation,
        displayName,
      });

      messageNotifyUtil.setSuccessMessage({
        title: 'Display Name Changed',
        description: 'Your display name has been changed',
      });
    }
  };

  const handleOnChange2FA = async () => {
    requestOTP({
      action: OTPActions.OTPActionChangeMFA,
      method: OTPDeliveryMethod.OTPDeliveryMethodEmail,
    }).then(({ status, data }) => {
      if (status === 200) {
        const result = parse.camelize(data);
        if (result.expiredTime) {
          setOtpExpiredAt(result.expiredTime);
        }

        showModal(ModalProfileTypes.twoFactorAuthentication);
      }
    });
  };

  const handleCancel = () => {
    setIsUpdate(false);
    setTypeModal();
  };

  const submitUpdateSupportDetail = async ({
    supportEmail,
    supportWebsiteUrl,
    supportPhoneNumber,
  }) => {
    const params = {
      supportEmail,
      supportWebsiteUrl,
      supportPhoneNumber,
    };
    const response = await changeAccountSupportDetail(params);

    if (response.status === 200) {
      setIsUpdate(false);
      setAccountInformation({
        ...accountInformation,
        supportEmail,
        supportWebsiteUrl,
        supportPhoneNumber,
      });
      setAuthUser({
        ...user,
        supportEmail,
        supportPhoneNumber,
      });
      messageNotifyUtil.setSuccessMessage({
        title: 'Support Details Updated',
        description: 'Your support detail has been updated',
      });
    }
  };

  const submitChangeMFA = ({ otpCode }) => {
    validateOTP({
      otpCode,
      action: OTPActions.OTPActionChangeMFA,
    }).then(async ({ status, data }) => {
      if (status === 200) {
        const result = parse.camelize(data);
        await update2FA({ enable: !enable2step, otpToken: result.otpToken });
        setIsUpdate(false);
        setTypeModal();
        setEnable2step(!enable2step);
      } else {
        setOtpValid(false);
      }
    });
  };

  const handleResendOTP = ({ action, method }) => {
    requestOTP({ action, method }).then((res) => {
      const result = parse.camelize(res);
      if (result.data.expiredTime) {
        setOtpExpiredAt(result.data.expiredTime);
      }
    });
  };

  const onSubmit = (data) => {
    switch (typeModal) {
      case ModalProfileTypes.displayName:
        submitUpdateDisplayName({ displayName: data.displayName });
        break;
      case ModalProfileTypes.twoFactorAuthentication:
        submitChangeMFA(data);
        break;
      case ModalProfileTypes.updatePhone:
        submitUpdatePhone(data);
        break;
      case ModalProfileTypes.changePassword:
        submitUpdatePassword(data);
        break;
      case ModalProfileTypes.supportDetail:
        submitUpdateSupportDetail(data);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Spin
        className='loading-spiner'
        spinning={pageLoading}
      />
      {!pageLoading &&
        <div className="my-account__profile">
          <div className="my-account__profile__verification">
            <RPSwitch checked={enable2step} onChange={handleOnChange2FA} />
            <div className="my-account__profile__verification__group-title">
              <p className="my-account__profile__verification__title">
                Two-step Verification
              </p>
              <p className="my-account__profile__verification__subtitle">
                Configure a second login step to keep your account extra secure.
              </p>
            </div>
          </div>
          <MerchantInfo
            showModal={showModal}
            data={accountInformation}
          />
          <Support
            showModal={showModal}
            data={accountInformation}
          />
          {/* <GSTDetail showModal={showModal} />
          <SettlementDetails showModal={showModal} /> */}
          <ModalUpdate
            destroyOnClose
            isModalVisible={isUpdate}
            onSubmit={onSubmit}
            typeModal={typeModal}
            info={accountInformation}
            handleCancel={handleCancel}
            resendOTP={handleResendOTP}
            otpExpiredAt={otpExpiredAt}
            otpValid={otpValid}
            setOtpValid={setOtpValid}
          />
        </div>
      }
    </>
  );
}

MyProfile.propTypes = {
  update2FA: PropTypes.func.isRequired,
  requestOTP: PropTypes.func.isRequired,
  validateOTP: PropTypes.func.isRequired,
  changeDisplayName: PropTypes.func.isRequired,
  getAccountInformation: PropTypes.func.isRequired,
  updateAccountPassword: PropTypes.func.isRequired,
  changeAccountSupportDetail: PropTypes.func.isRequired,
  updateAccountContactNumber: PropTypes.func.isRequired,
};

export default MyProfile;
