import React from 'react';
import UpdatePassword from '@/containers/Auth/UpdatePassword';

function UpdatePasswordPage() {
  return (
    <UpdatePassword />
  );
}

export default UpdatePasswordPage;
