import React from 'react';
import { Row, Col } from 'antd';
import RPButton from '@/components/RPButton';
import PropTypes from 'prop-types';
import { ModalProfileTypes } from '@/constants/Profile';
import { KYCStatusesMapping } from '@/constants/kyc';
import { formatter } from '@/utils';
import './index.scss';

function MerchantInfo({
  data,
  showModal,
}) {
  const {
    merchantId,
    contactName,
    displayName,
    contactEmail,
    contactNumber,
    businessName,
    businessType,
    createdAt,
    registeredBy,
    status,
    limiPerTransaction,
  } = data;

  const statusText = (statusNum) => Object.keys(KYCStatusesMapping).find(key => KYCStatusesMapping[key] === statusNum);

  return (
    <div className="my-account__profile__card">
      <Row gutter={[12, 24]}>
        <Col span={12} className="my-account__profile__card__title">
          Merchant ID
        </Col>
        <Col span={12} className="my-account__profile__card__title --blue">
          {merchantId || '---'}
        </Col>
        <Col span={12}>Contact Name</Col>
        <Col span={12}>{contactName || '---'}</Col>
        <Col span={12}>Display Name</Col>
        <Col span={12}>{displayName || '---'}</Col>
        <Col span={12} offset={12}>
          <RPButton
            grey
            onClick={() => showModal(ModalProfileTypes.displayName)}
          >
            Change Display Name
          </RPButton>
        </Col>
        <Col span={12}>Contact Email</Col>
        <Col span={12}>{contactEmail || '---'}</Col>
        <Col span={12}>Password</Col>
        <Col span={12}>**************</Col>
        <Col span={12} offset={12}>
          <RPButton
            grey
            onClick={() => showModal(ModalProfileTypes.changePassword)}
          >
            Change Password
          </RPButton>
        </Col>
        <Col span={12}>Contact Number</Col>
        <Col span={12}>{contactNumber ? `+${contactNumber}` : '---'}</Col>
        <Col span={12} offset={12}>
          <RPButton
            grey
            onClick={() => showModal(ModalProfileTypes.updatePhone)}
          >
            Update Phone Number
          </RPButton>
        </Col>
        <Col span={12}>Business Name</Col>
        <Col span={12}>{businessName || '---'}</Col>
        <Col span={12}>Business Type</Col>
        <Col span={12}>{businessType?.name || '---'}</Col>
        <Col span={12}>Registration Date</Col>
        <Col span={12}>{formatter.parseDateTime(createdAt)}</Col>
        <Col span={12}>Registered By</Col>
        <Col span={12}>{registeredBy || '---'}</Col>
        <Col span={12}>Account Activation</Col>
        <Col span={12}>{status ? statusText(status) : '---'}</Col>
        <Col span={12}>Limit Per Transaction</Col>
        <Col span={12}>{limiPerTransaction || '---'}</Col>
      </Row>
    </div>
  );
}

MerchantInfo.propTypes = {
  data: PropTypes.object,
  showModal: PropTypes.func,
};

export default MerchantInfo;
