import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col
} from 'antd';
import { formatter } from '@/utils';
import RPButton from '@/components/RPButton';


function DetailFailed({ data, showModal }) {
  const {
    amount,
    status,
    refund,
    paymentMethod,
    createdAt,
    email,
    contact,
    totalFee,
    radicalPayFee,
    gstFee,
    orderId,
    settlementDetails
  } = data;

  return (
    <div className="detail__card">
      <Row>
        <Col flex="50%" className="detail__card__left">
          <Row gutter={[12, 12]}>
            <Col flex="50%" className="detail__card__label">Amount</Col>
            <Col flex="50%">{formatter.formatCurrency(Number(amount))}</Col>
            <Col flex="50%" className="detail__card__label">Status</Col>
            <Col flex="50%" className="detail__card__error">{status}</Col>
            <Col flex="50%" className="detail__card__label">Refund</Col>
            <Col flex="50%">
              {refund}
              <RPButton onClick={() => showModal()} className="mt-3">Issue Refund</RPButton>
            </Col>
            <Col flex="50%" className="detail__card__label">Payment Method</Col>
            <Col flex="50%">{paymentMethod}</Col>
            <Col flex="50%" className="detail__card__label">Created At</Col>
            <Col flex="50%">{createdAt}</Col>
            <Col flex="50%" className="detail__card__label">Settlement Details</Col>
            <Col flex="50%">{settlementDetails}</Col>
          </Row>
        </Col>
        <Col flex="50%" className="detail__card__right">
          <Row gutter={[12, 12]}>
            <Col flex="50%" className="detail__card__label">Customer</Col>
            <Col flex="50%">
              <p className="mb-0">{email}</p>
              <p className="mb-0">{contact}</p>
            </Col>
            <Col flex="50%" className="detail__card__label">Total Fee</Col>
            <Col flex="50%">
              <p className="mb-0">{formatter.formatCurrency(Number(totalFee))}</p>
              <p className="mb-0 detail__card__text-fee">
                RadicalPay Fee - {formatter.formatCurrency(Number(radicalPayFee))}
              </p>
              <p className="mb-0 detail__card__text-fee">GST - {formatter.formatCurrency(Number(gstFee))}</p>
            </Col>
            <Col flex="50%" className="detail__card__label">Order ID</Col>
            <Col flex="50%">
              <p className="mb-0">{orderId}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

DetailFailed.propTypes = {
  data: PropTypes.object,
  showModal: PropTypes.func,
};

export default DetailFailed;
