import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import { uniqueId } from 'lodash';
import { listTypes } from '@/utils/paymentButton';
import RPButton from '@/components/RPButton';
import logo from '@/assets/images/logo-white.svg';
import { formatter } from '@/utils';
import AddInput from './AddInput';
import PreviewInput from './PreviewInput';

const StepCustomerDetails = React.memo(({
  setData,
  data,
  onSubmit,
  clickBack,
  amount,
  setShowExitPrompt,
}) => {
  const [isAdding, setIsAdding] = useState(false);

  const addInput = () => {
    setIsAdding(true);
  };

  const onUpdate = (value, label, index) => {
    const obj = listTypes.find(o => o.value === value);
    let deletable = true;
    if (data[index] !== undefined) {
      deletable = data[index].deletable;
    }
    data[index] = { label, type: obj?.type || 'string', editing: false, value, deletable, editable: true };
    setData([...data]);
    setIsAdding(false);
  };

  const onCancelAdd = () => {
    setIsAdding(false);
  };

  const handleOnDeleteAdditionalField = (index) => {
    setShowExitPrompt(true);
    data.splice(index, 1);
    setData([...data]);
  };

  return (
    <div>
      <div className="payment-button__new__main-content__card">
        <Row>
          <Col
            flex="50%"
            className="payment-button__new__main-content__card__left payment-button__new__preview"
          >
            <div className="payment-button__new__preview__card">
              <div className="payment-button__new__preview__card__header">
                <img src={logo} alt="icon" width="83px" height="23px" />
                <p className='payment-button__new__preview__card__header--hint'>
                  You&apos;ve received a payment request for
                </p>
                <p className='mb-0'>
                  {formatter.formatCurrency(Number(amount || 0))}
                </p>
                <Button className="page-header__button__white -custom payment-link__email__button">
                  <div className="mb-0 page-header__button__text">Pay Now</div>
                  <div className="mb-0 page-header__button__icon" />
                </Button>
              </div>
              <div className='payment-button__new__preview__card__form'>
                {
                  data.map(obj => (
                    <PreviewInput
                      key={uniqueId('--preview-input')}
                      type={obj.type}
                      label={obj.label}
                    />
                  ))
                }
              </div>
            </div>
          </Col>
          <Col flex="50%" className="payment-button__new__main-content__card__right payment-button__new__right">
            <p className="title">
              Customers will be required to fill this form before making the payment
            </p>
            {
              data.map((obj, key) => (
                <AddInput
                  key={uniqueId('--editable-field')}
                  value={obj.value}
                  label={obj.label}
                  index={key}
                  deletable={obj.deletable}
                  onUpdate={onUpdate}
                  onCancel={() => { }}
                  onDelete={handleOnDeleteAdditionalField}
                  editable={obj.editable}
                />
              ))
            }
            {
              isAdding && (
                <AddInput
                  key={uniqueId('--editable-field')}
                  index={data.length}
                  value='email'
                  onUpdate={onUpdate}
                  onCancel={onCancelAdd}
                  isEditing
                />
              )
            }
            <div className='add-field-container'>
              <RPButton className='payment-button__new__btn-add' grey onClick={addInput}>
                <p className="payment-button__new__add-input">
                  Add another input field
                </p>
              </RPButton>
            </div>
          </Col>
        </Row>
      </div>
      <div className="rp-payment-pages__form__card__group-btn">
        <RPButton
          grey
          onClick={clickBack}
        >
          Back
        </RPButton>
        <RPButton
          gradient
          withArrowIcon
          onClick={onSubmit}
        >
          <div className="payment-button__new__main-content__card__submit-btn">
            Continue
          </div>
        </RPButton>
      </div>
    </div>
  );
});

StepCustomerDetails.propTypes = {
  setData: PropTypes.func,
  onSubmit: PropTypes.func,
  clickBack: PropTypes.func,
  data: PropTypes.array,
  amount: PropTypes.number,
  setShowExitPrompt: PropTypes.func,
};

export default StepCustomerDetails;
