/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
} from 'antd';
import PropTypes from 'prop-types';
import { ROUTES } from '@/constants';
import RPButton from '@/components/RPButton';
import maskBg from '@/assets/images/login-register/mask.svg';
import './forgotPassword.scss';

function ForgotPassword({ handleResetPassword, loading }) {
  return (
    <div className='forgot-password'>
      <Row>
        <Col
          span={12}
          className='forgot-password__left'
        >
          <div className='forgot-password__left__container'>
            <div className='forgot-password__left__content'>
              <div className='forgot-password__left__content__title'>Forgot Password</div>
              <div className='forgot-password__left__content__desc'>Enter your email to reset password</div>
            </div>
            <div className='form'>
              <Form
                name='reset-password'
                layout='vertical'
                onFinish={handleResetPassword}
                autoComplete='off'
              >
                <Form.Item
                  name='email'
                  rules={[
                    { type: 'email', message: 'Email Address is not valid' },
                    { required: true, message: 'Email Address is required' },
                  ]}
                  label={
                    <div className='form-item-label'>
                      <div>Email Address</div>
                    </div>
                  }
                >
                  <Input
                    placeholder='Email'
                    className='form-item-input'
                    role='presentation'
                  />
                </Form.Item>
                <div className='form-btn-login'>
                  <RPButton
                    danger
                    htmlType='submit'
                    className='btn-submit'
                    loading={loading}
                  >
                    Reset Password
                  </RPButton>
                </div>
              </Form>
            </div>
          </div>

          <div className='actions'>
            <div>Already have an account?</div>
            <Link to={ROUTES.LOGIN_PATH}>Sign In</Link>
          </div>
        </Col>
        <Col
          span={12}
          className='forgot-password__right'
        >
          <div className='forgot-password__right__container'>
            <div className='forgot-password__right__super-title'>Supercharge</div>
            <div className='forgot-password__right__desc'>your business today</div>
          </div>
          <div className='forgot-password__right__image'>
            <img
              src={maskBg}
              alt='mask bg'
              width='100%'
              height='100%'
            />
          </div>
        </Col>
      </Row>

    </div>
  );
}
ForgotPassword.propTypes = {
  handleResetPassword: PropTypes.func,
  loading: PropTypes.bool
};

export default ForgotPassword;
