/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import { errorMessages, ROUTES } from '@/constants';
import RPButton from '@/components/RPButton';
import maskBg from '@/assets/images/login-register/mask.svg';
import facebookLogo from '@/assets/images/login-register/facebook-logo.svg';
import googleLogo from '@/assets/images/login-register/google-logo.svg';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { validatePassword } from '@/utils/validate';
import './signup.scss';
import { gapi } from 'gapi-script';

function SignUpPage({
  handleSignup,
  signupForm,
  loading,
  responseFacebook,
  responseGoogle,
}) {

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_ID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  // Hide For Now
  const hideFacebookLogin = true;

  return (
    <div className='signup-page' id='signup-page'>
      <Row>
        <Col
          span={12}
          className='signup-page__left'
        >
          <div className='signup-page__left__container'>
            <div className='signup-page__left__content'>
              <div className='signup-page__left__content__title'>Welcome!</div>
              <div className='signup-page__left__content__desc'>Create an account and enjoy our full services.</div>
              <div className='signup-page__left__content__via-social'>
                <Row gutter={[36, 0]}>
                  {!hideFacebookLogin &&
                    <Col span={12}>
                      <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_ID}
                        callback={responseFacebook}
                        render={renderProps => (
                          <Button
                            onClick={() => renderProps.onClick()}
                            shape="round"
                            size='large'
                            className='btn btn-login-via-facebook'
                            icon={<img src={facebookLogo} alt='facebook logo' width='29' height='29' />}
                          >Sign up with Facebook</Button>
                        )}
                      />
                    </Col>
                  }
                  <Col>
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_ID}
                      render={renderProps => (
                        <Button
                          onClick={() => renderProps.onClick()}
                          shape="round"
                          size='large'
                          className='btn btn-login-via-google'
                          icon={<img src={googleLogo} alt='google logo' width='22' height='22' />}
                        >Sign up with Google</Button>
                      )}
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      isSignedIn={false}
                    />

                  </Col>
                </Row>
              </div>
            </div>
            <div className='form'>
              <Form
                form={signupForm}
                name='signup'
                layout='vertical'
                onFinish={handleSignup}
              >
                <Form.Item
                  name='email'
                  rules={[
                    { type: 'email', message: 'Email Address is not valid' },
                    { required: true, message: 'Email Address is required' },
                  ]}
                  label={
                    <div className='form-item-label'>
                      <div>Email Address</div>
                    </div>
                  }
                >
                  <Input
                    placeholder='Email'
                    className='form-item-input'
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <div className='form-item-label --custom'>
                      <div>Password</div>
                    </div>
                  }
                  name='password'
                  rules={[
                    { required: true, message: 'Password is required' },
                    () => ({
                      validator(_, value) {
                        if (value && !validatePassword(value)) {
                          return Promise.reject(new Error(errorMessages.invalidPassword));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder='Password Confirmation'
                    className='form-item-input-password'
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <div className='form-item-label --custom'>
                      <div>Confirm Password</div>
                    </div>
                  }
                  name='password_confirmation'
                  rules={[
                    { required: true, message: 'Confirm Password is required' },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Confirm Password do not match with Password!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder='Confirm Password'
                    className='form-item-input-password'
                  />
                </Form.Item>
                <div className='form-btn-login'>
                  <RPButton
                    danger
                    htmlType='submit'
                    className='btn-submit'
                    loading={loading}
                  >
                    Sign Up
                  </RPButton>
                </div>
              </Form>
            </div>
          </div>

          <div className='actions'>
            <div>Already have an account?</div>
            <Link to={ROUTES.LOGIN_PATH}>Sign in</Link>
          </div>
        </Col>
        <Col
          span={12}
          className='signup-page__right'
        >
          <div className='signup-page__right__container'>
            <div className='signup-page__right__super-title'>Supercharge</div>
            <div className='signup-page__right__desc'>your business today</div>
          </div>
          <div className='signup-page__right__image'>
            <img
              src={maskBg}
              alt='mask bg'
              width='100%'
              height='100%'
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

SignUpPage.propTypes = {
  handleSignup: PropTypes.func,
  signupForm: PropTypes.object,
  loading: PropTypes.bool,
  responseFacebook: PropTypes.func,
  responseGoogle: PropTypes.func,
};

export default SignUpPage;
