import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'antd';
import { validateEmail } from '@/utils/validate';
import RPInput from '@/components/RPInput';
import PropTypes from 'prop-types';
import RPButton from '@/components/RPButton';
import RPCheckbox from '@/components/RPCheckbox';
import RPSearch from '@/components/RPSearch';
import RPTree from '../RPTree';

const renderTitle = title => <p className='settings__webhooks__modal__title'>{title}</p>;
const paymentEvents = [
  {
    title: renderTitle('Payment Events'),
    key: 'payment-events',
    children: [
      {
        title: renderTitle('payment.authorized'),
        key: 'payment.authorized',
      },
      {
        title: renderTitle('payment.pending'),
        key: 'payment.pending',
      },
      {
        title: renderTitle('payment.failed'),
        key: 'payment.failed',
      },
    ],
  },
  {
    title: renderTitle('Order Events'),
    key: 'order-events',
    children: [
      {
        title: renderTitle('order.paid'),
        key: 'order.paid',
      }
    ],
  },
  {
    title: renderTitle('Invoice Events'),
    key: 'invoice-events',
  }
];

function ModalWebhooks({ setIsModalVisible, isModalVisible, data }) {
  const [form] = Form.useForm();
  const isEdit = !!data;
  const [events, setEvents] = useState(data ? data.activeEvents : []);
  const [isClear, setIsClear] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onCheck = (checkedKeys, info) => {
    console.log('onCheck', checkedKeys, info);
    setEvents([...checkedKeys]);
  };

  const onClear = e => {
    setIsClear(e.target.checked);
    setEvents([]);
  };

  useEffect(() => {
    if (events.length > 0) {
      setIsClear(false);
    }
  }, [events]);

  const onFinish = (values) => {
    console.log('Success: ', values);
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Webhook Setup"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="rp-modal settings__webhooks__modal"
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        noValidate
        onFinish={onFinish}
        initialValues={data}
      >
        <p className='settings__webhooks__modal__label'>Webhook URL</p>
        <Form.Item
          name="webhookUrl"
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input webhook url' }]}
        >
          <RPInput
            placeholder="HTTPS URL is recommended"
            name="webhookUrl"
          />
        </Form.Item>
        <p className='settings__webhooks__modal__label'>Secret</p>
        <Form.Item
          name="secretAvailability"
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please input secret' }]}
        >
          <RPInput
            placeholder="Secret"
            name="secret"
            hint='We strongly recommend adding secrets for security'
          />
        </Form.Item>
        <p className='settings__webhooks__modal__label'>Alert Email</p>
        <Form.Item
          wrapperCol={{ span: 24 }}
          label=""
          name="email"
          rules={[
            { required: true, message: 'Please input alert email' },
            () => ({
              validator(_, value) {
                if (value && !validateEmail(value)) {
                  return Promise.reject(new Error('Please input the valid email'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <RPInput
            name="email"
            placeholder="Email@email.com"
            type="email"
            required
            hint='Receive email alerts for webhook failures'
          />
        </Form.Item>
        <p className='settings__webhooks__modal__label'>Active Events</p>
        <div className='settings__webhooks__modal__events'>
          <RPSearch className='settings__webhooks__modal__events__search' />
          <RPTree
            checkable
            onCheck={onCheck}
            treeData={paymentEvents}
            defaultExpandAll
            selectable={false}
            checkedKeys={events}
          />
          <RPCheckbox
            className='settings__webhooks__modal__events__clear'
            onChange={onClear}
            checked={isClear}
          >
            Clear All
          </RPCheckbox>
        </div>
        <RPButton gradient center htmlType='submit'>
          {isEdit ? 'Update Webhook' : 'Create Webhook'}
        </RPButton>
      </Form>
    </Modal>
  );
}
ModalWebhooks.propTypes = {
  setIsModalVisible: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  data: PropTypes.object
};

export default ModalWebhooks;
