import React from 'react';
import Confirmation from '@/containers/Auth/Confirmation';

function ConfirmationPage() {
  return (
    <Confirmation/>
  );
}

export default ConfirmationPage;
