import React, { useEffect, useState } from 'react';
import PageHeader from '@/components/PageHeader';
import Step from '@/components/PaymentButton/Step';
import StepCreateButton from '@/components/PaymentButton/Steps/StepCreateButton';
import StepCustomerDetails from '@/components/PaymentButton/Steps/StepCustomerDetails';
import StepPreview from '@/components/PaymentButton/Steps/StepPreview';
import { parseEditPaymentButton, parsePaymentButtonUpdate } from '@/utils/paymentButton';
import { updatePaymentButtonsDetail, getPaymentButtonsDetail } from '@/services/PaymentButtonService';
import { useHistory, Prompt } from 'react-router-dom';
import { ROUTES } from '@/constants';
import PropType from 'prop-types';
import useExitPrompt from '@/hooks/useExitPrompt';

const pageHeaderData = {
  title: 'Edit Payment Button',
  description: '',
  headerType: 'white',
};

function PaymentEditButton({
  id,
}) {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({});
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const [data, setData] = useState([
    { label: 'Email', type: 'email', editing: false, value: 'email', deletable: false, editable: false },
    { label: 'Phone Number', type: 'phone', editing: false, value: 'phone-number', deletable: false, editable: false },
  ]);

  const [dataEdit, setDataEdit] = useState();

  const onSubmit = async () => {
    if (step === 3) {
      const payload = parsePaymentButtonUpdate({
        ...form,
        customerDetail: data
      }, dataEdit);
      const res = await updatePaymentButtonsDetail(payload);
      if (res.status === 200) {
        setShowExitPrompt(false);
        history.replace(`${ROUTES.APP.PAYMENT_BUTTONS.INDEX}/${res.data.id}`);
      }
      return;
    }
    setStep(step + 1);
  };

  const clickBack = () => {
    if (step <= 1) return;
    setStep(step - 1);
  };

  const initData = async () => {
    const res = await getPaymentButtonsDetail({ id });
    const payload = parseEditPaymentButton(res);
    setDataEdit(payload);
    setForm({
      title: payload.title,
      amount: payload.amount,
      buttonLabel: payload.buttonLabel,
      id: payload.id
    });
    setData(payload.customerDetail);
  };

  useEffect(() => {
    id && initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Prompt
        when={showExitPrompt}
        message="Do you want to leave this page and discard your changes?"
      />

      <div className='payment-button__new'>
        <PageHeader {...pageHeaderData} />
        <div
          className="payment-button__new__main-content"
        >
          <Step step={step} />
          {step === 1 &&
            <StepCreateButton
              form={form}
              setForm={setForm}
              onSubmit={onSubmit}
              setShowExitPrompt={setShowExitPrompt}
            />
          }
          {step === 2 &&
            <StepCustomerDetails
              data={data}
              amount={form.amount}
              setData={setData}
              onSubmit={onSubmit}
              clickBack={clickBack}
              setShowExitPrompt={setShowExitPrompt}
            />
          }
          {step === 3 &&
            <StepPreview
              data={data}
              amount={form.amount}
              onSubmit={onSubmit}
              clickBack={clickBack}
              labelButton={form.buttonLabel}
              isEdit
            />
          }
        </div>
      </div>
    </>
  );
}

PaymentEditButton.propTypes = {
  id: PropType.string
};

export default PaymentEditButton;
