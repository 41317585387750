import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Space,
  Skeleton,
} from 'antd';
import { uniqueId } from 'lodash';

import './index.scss';

function CardComponent({
  icon,
  title,
  extra,
  loading,
  children,
  ...props
}) {
  const renderTitle = () => {
    if (loading) {
      return (
        <div className="w-50">
          <Skeleton.Avatar active />
          <Skeleton.Input active block className="card-title" />
        </div>
      );
    }

    return (
      <>
        {!!icon &&
          <img
            src={icon}
            alt="card icon"
            width={40}
            height={40}
          />
        }
        <span className="card-title">{title}</span>
      </>
    );
  };

  const renderExtra = () => {
    if (loading && extra) {

      if (extra.props.children.length > 0) {
        return (
          <Space size={[16]}>
            {extra.props.children.map(() => <Skeleton.Button key={uniqueId()} active block shape="round" />)}
          </Space>
        );
      }

      return (
        <Space size={[16]}>
          <Skeleton.Button key={uniqueId()} active block shape="round" />
        </Space>
      );

    }

    return extra;
  };

  return (
    <Card
      {...props}
      extra={renderExtra()}
      title={renderTitle()}
      className="card-component"
    >
      {children}
    </Card>
  );
}

CardComponent.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
  extra: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
};


export default CardComponent;
