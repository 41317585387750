import React from 'react';
import { useParams } from 'react-router-dom';

import EditContainer from '@/containers/PaymentButton/edit';

import './index.scss';

function Edit() {
  const { id } = useParams();
  return(
    <EditContainer id={id} />
  );
}

export default Edit;
