import React from 'react';
import { useParams } from 'react-router-dom';

import PaymentButtonDetail from '@/containers/PaymentButton/Detail';

import './show.scss';

function Show() {
  const { id } = useParams();

  return (
    <div
      className="payment-button__container"
    >
      <PaymentButtonDetail id={id} />
    </div>
  );
}

export default Show;
