import React from 'react';
import PropTypes from 'prop-types';
import {
  InputNumber
} from 'antd';

import './index.scss';

function RPInputAmount({
  label,
  hint,
  required,
  name,
  value,
  ...props
}) {
  const renderAmount = () => (
    <InputNumber
      className={`${value ? '--has-value' : ''}`}
      formatter={(e) => `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(e) => e.replace(/\$\s?|(,*)/g, '')}
      controls={false}
      value={value}
      name={name}
      {...props}
    />
  );

  return (
    <div>
      <div className='rp-input-number-container'>
        {
          renderAmount()
        }
        <div className="hover-style" />
        {!!label && (
          <label htmlFor='#' className="custom-input__label">
            {label}
            {!!required && (<span className="custom-input__required">*</span>)}
          </label>
        )
        }
      </div>
      {!!hint && <p className="custom-input__hint">{hint}</p>}
    </div>
  );
};

RPInputAmount.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number
};

export default RPInputAmount;
