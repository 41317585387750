import React, { useEffect, useState } from 'react';

import PageHeader from '@/components/PageHeader';
import Step from '@/components/PaymentPages/Step';
import PageDetails from '@/components/PaymentPages/Step/PageDetails';
import PaymentDetails from '@/components/PaymentPages/Step/PaymentDetails/index';
import ReceiptsAndSettings from '@/components/PaymentPages/Step/ReceiptsAndSettings';
import SuccessPage from '@/components/PaymentPages/Step/SuccessPage';
import { parseDataForm, parseParamsPaymentPage } from '@/utils/paymentPage';
import { createPaymentPage, getPaymentPagesDetail, updatePaymentPagesDetail } from '@/services/PaymentPageService';
import PropType from 'prop-types';
import { useHistory, Prompt } from 'react-router-dom';
import { ROUTES } from '@/constants';
import { Spin } from 'antd';
import { getAuthUser } from '@/utils/localStorageUtil';
import useExitPrompt from '@/hooks/useExitPrompt';

function FormContainer({ isEdit, id }) {
  const user = getAuthUser();
  const history = useHistory();
  const pageHeaderData = {
    title: isEdit ? 'Edit payment page' : 'Create a New Payment Page',
    description: 'Complete this form to accept payments easily.',
    headerType: 'white'
  };
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    pageDetails: {
      title: null,
      description: null,
      supportEmail: user?.supportEmail,
      supportContactNumber: user?.supportPhoneNumber,
      termAndCondition: null,
    },
    paymentDetails: {
      fields: [
        {
          label: 'Amount Type',
          dataType: 'string',
          amountType: '',
          amount: undefined,
          editing: false,
          deletable: false,
          key: 0,
          editable: true,
        },
        {
          label: 'Email',
          dataType: 'email',
          editing: false,
          deletable: false,
          type: 'email',
          key: 1,
          editable: false,
        },
        {
          label: 'Contact Number',
          dataType: 'string',
          editing: false,
          deletable: false,
          type: 'phone-number',
          key: 2,
          editable: false,
        }
      ],
    },
    paymentReceiptsAndPageSettings: {
      sendReceiptsAutomatically: true,
      showCustomerInformationOnReceipt: false,
      enableCustomUrl: false,
      customUrl: null,
      expiryDate: null,
      noExpiry: true,
    },
  });
  const [formPageDetails, setFormPageDetails] = useState(data.pageDetails);
  const [formReceiptsAndSettings, setFormReceiptsAndSettings] = useState(data.paymentReceiptsAndPageSettings);
  const [typeOfInformation, setTypeOfInformation] = useState([]);
  const [paymentPageCreatedId, setPaymentPageCreatedId] = useState(null);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const clickBack = () => {
    if (step <= 1) return;
    setStep(step - 1);
  };

  const onSubmit = () => {
    if (step > 4) return;
    setStep(step + 1);
  };
  const onSubmitStep3 = async values => {
    setLoading(true);
    const params = parseParamsPaymentPage(
      {
        ...data,
        paymentReceiptsAndPageSettings: { ...values }
      },
      user.workMode,
    );

    let res;
    if (isEdit && id) {
      res = await updatePaymentPagesDetail({
        ...params,
        id,
      });
    } else {
      res = await createPaymentPage(params);
    }

    setLoading(false);
    if (res?.status === 201) {
      setShowExitPrompt(false);
      setPaymentPageCreatedId(res.data.id);
      setStep(4);
    };

    if (res?.status === 200) {
      setShowExitPrompt(false);
      history.push(`${ROUTES.APP.PAYMENT_PAGES_PATH}/${id}`);
    }
  };
  const fetchData = async () => {
    const res = await getPaymentPagesDetail({ id });
    const dataParsed = parseDataForm(res);
    setData(dataParsed);
    setFormPageDetails(dataParsed.pageDetails);
    setFormReceiptsAndSettings(dataParsed.paymentReceiptsAndPageSettings);
  };
  useEffect(() => {
    if (isEdit && id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, id]);

  useEffect(() => {
    let fields = data.paymentDetails?.fields || [];
    if (fields.length > 0) {
      fields = fields.filter(field => !field.amountType);
      setTypeOfInformation(fields.map((item) => ({ label: item.label, value: item.key, deletable: item.deletable })));
    }
  }, [data]);
  return (
    <>
      <Prompt
        when={showExitPrompt}
        message="Do you want to leave this page and discard your changes?"
      />
      <Spin spinning={loading}>
        <div className='rp-payment-pages__new-container'>
          <PageHeader {...pageHeaderData} />

          <div className='rp-payment-pages__new__form'>
            <Step step={step} />

            {step === 1 &&
              <PageDetails
                data={data}
                setData={setData}
                form={formPageDetails}
                setForm={setFormPageDetails}
                onSubmit={onSubmit}
                setShowExitPrompt={setShowExitPrompt}
              />
            }

            {step === 2 &&
              <PaymentDetails
                onSubmit={onSubmit}
                clickBack={clickBack}
                data={data}
                setData={setData}
                setShowExitPrompt={setShowExitPrompt}
              />
            }
            {step === 3 &&
              <ReceiptsAndSettings
                isEdit={isEdit}
                data={data}
                setData={setData}
                onSubmit={onSubmitStep3}
                clickBack={clickBack}
                form={formReceiptsAndSettings}
                setForm={setFormReceiptsAndSettings}
                typeOfInformation={typeOfInformation}
                setShowExitPrompt={setShowExitPrompt}
              />
            }
            {step === 4 &&
              <SuccessPage
                data={data}
                paymentPageCreatedId={paymentPageCreatedId}
              />
            }
          </div>
        </div>
      </Spin>
    </>
  );
}

FormContainer.propTypes = {
  isEdit: PropType.bool,
  id: PropType.string
};

export default FormContainer;
