/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Result
} from 'antd';
import PropTypes from 'prop-types';
import { ROUTES } from '@/constants';
import maskBg from '@/assets/images/login-register/mask.svg';
import { LoadingOutlined } from '@ant-design/icons';
import './confirmation.scss';

function Confirmation({ loading, success }) {
  return (
    <div className='confirmation-page'>
      <Row>
        <Col
          span={12}
          className='confirmation-page__left'
        >
          <div className='confirmation-page__left__container'>
            <div className='confirmation-page__left__content'>
              <div className='confirmation-page__left__content__title'>Registration Completed!</div>
              <div className='confirmation-page__left__content__desc'>Your registration process is completed. You can login and fully enjoy our services.</div>
              {
                loading ? <LoadingOutlined /> : (
                  <div>
                    {
                      success ?
                        <Result
                          status="success"
                          subTitle=""
                        /> :
                        <Result
                          status="error"
                          subTitle="Token is invalid or has been expired"
                        />
                    }
                  </div>
                )
              }
            </div>
          </div>
          <div className='actions'>
            <div>Back to login?</div>
            <Link to={ROUTES.LOGIN_PATH}>Login</Link>
          </div>
        </Col>
        <Col
          span={12}
          className='confirmation-page__right'
        >
          <div className='confirmation-page__right__container'>
            <div className='confirmation-page__right__super-title'>Supercharge</div>
            <div className='confirmation-page__right__desc'>your business today</div>
          </div>
          <div className='confirmation-page__right__image'>
            <img
              src={maskBg}
              alt='mask bg'
              width='100%'
              height='100%'
            />
          </div>
        </Col>
      </Row>

    </div>
  );
}

Confirmation.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.bool
};

export default Confirmation;
