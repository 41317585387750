/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useState } from 'react';
import Cropper from 'react-cropper';
import RPButton from '@/components/RPButton';
import { Upload, message, Button, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getAuthUser, setAuthUser } from '@/utils/localStorageUtil';
import PropTypes from 'prop-types';
import 'cropperjs/dist/cropper.css';
import './index.scss';

function UploadImage({ uploadFile, defaultSrc }) {
  const [imageUploaded, setImageUploaded] = useState();
  const [loading, setLoading] = useState(false);
  const [cropData, setCropData] = useState();
  const [cropper, setCropper] = useState();
  const user = getAuthUser();
  const [showCrop, setShowCrop] = useState(false);

  const urlToFile = async (url) => {
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return new File([buf], 'logo', { type: 'image/png' });
  };

  const getCropData = async () => {
    if (typeof cropper !== 'undefined') {
      const base64 = cropper.getCroppedCanvas().toDataURL();
      const newFile = await urlToFile(base64);
      const res = await uploadFile(newFile);
      if (res.status === 200) {
        user.merchant.companyLogoUrl = res.data?.companyLogoUrl;
        setAuthUser(user);
        setShowCrop(false);
        setCropData(base64);
        setImageUploaded(base64);
      }
    }
  };

  const customRequest = async (event) => {
    event.onSuccess('ok');
  };

  const props = {
    name: 'file',
    accept: '.png, .jpeg',
    onChange(info) {
      setLoading(true);

      if (info.file.status === 'done') {
        setLoading(false);
        const reader = new FileReader();
        reader.onload = () => {
          setImageUploaded(reader.result);
        };
        reader.readAsDataURL(info.file.originFileObj);
        setShowCrop(true);
      }
    },
    beforeUpload: async (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }

      const isLt3M = file.size / 1024 / 1024 < 3;

      if (!isLt3M) {
        message.error('Image must smaller than 3MB!');
      }

      return isJpgOrPng && isLt3M;
    },
    customRequest,
    showUploadList: false,
  };

  const showEdit = () => {
    setShowCrop(true);
  };

  const handleOk = () => {
    setShowCrop(false);
  };

  const handleCancel = () => {
    setShowCrop(false);
  };

  return (
    <div className="rp-upload-image">
      <div className='rp-upload-image__group-upload'>
        {loading ? (
          <div className="rp-upload-image__preview --loading">
            <LoadingOutlined />
          </div>)
          : (
            <img src={cropData || defaultSrc} className='rp-upload-image__preview' alt='preview' />
          )
        }
        <Upload {...props}>
          <div className="rp-upload-image__group-btn">
            <Button className="rp-upload-image__button">Upload File</Button>
            <p className="rp-upload-image__label">
              The uploaded image needs to meet the minimum requirement of 255 x
              255 pixels.
            </p>
          </div>
        </Upload>
      </div>
      {(cropData || defaultSrc) && (
        <button
          type="button"
          className="rp-upload-image__edit"
          onClick={showEdit}
        >
          Edit
        </button>
      )}
      <Modal
        title="Edit Company Logo"
        visible={showCrop}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
        keyboard={false}
        maskClosable={false}
        className="rp-modal rp-upload-image__modal-edit"
      >
        <Cropper
          style={{ height: 400, width: '100%' }}
          initialAspectRatio={1}
          src={imageUploaded || defaultSrc}
          viewMode={1}
          minCropBoxHeight={256}
          minCropBoxWidth={256}
          aspectRatio={1}
          background={false}
          responsive
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides
          checkCrossOrigin={false}
          crossOrigin="anonymous"
        />
        <RPButton
          onClick={getCropData}
          gradient
          center
          className='rp-upload-image__modal-edit__btn-crop'
        >
          Submit
        </RPButton>
      </Modal>
    </div>
  );
}

UploadImage.propTypes = {
  uploadFile: PropTypes.func,
  defaultSrc: PropTypes.string
};

export default UploadImage;
