import React from 'react';
import PageHeader from '@/components/PageHeader';
import Transactions from '@/components/Transactions/index';
import './index.scss';

const pageHeaderData = {
  title: 'Transactions',
  description: 'Recent transactional activities on your account',
};

function TransactionsPage() {
  return (
    <>
      <PageHeader {...pageHeaderData} />
      <div
        className="user-portal-transaction__container"
      >
        <Transactions />
      </div>
    </>
  );
}

export default TransactionsPage;
