import React, { useState } from 'react';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import PageHeader from '@/components/PageHeader';
import RPButton from '@/components/RPButton';
import Webhooks from '@/containers/Webhooks';
import { ROUTES } from '@/constants';

function WebhookShow() {
  const history = useHistory();
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const onEdit = () => {
    setIsEdit(true);
  };

  const onDelete = () => {
    setIsDelete(true);
  };

  const pageHeaderData = {
    title: 'Webhook Details',
    leftBtns: (
      <RPButton
        grey
        onClick={() => history.replace(ROUTES.APP.SETTINGS.WEBHOOKS.INDEX)}
      >
        Back
      </RPButton>
    ),
    rightBtns: (
      <div className='webhooks-detail__group-btn'>
        <RPButton
          grey
          onClick={() => onDelete()}
        >
          Delete
        </RPButton>
        <RPButton
          grey
          onClick={() => onEdit()}
        >
          Edit
        </RPButton>
      </div>
    )
  };

  return (
    <>
      <PageHeader
        {...pageHeaderData}
      />
      <div
        className="dashboard__container settings__container"
      >
        <Webhooks
          id={id}
          isEdit={isEdit}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          setIsEdit={setIsEdit}
        />
      </div>
    </>
  );
}

export default WebhookShow;
