import http from '@/http';
import { ApiUser } from '@/constants/api';
import {
  parse,
  messageNotifyUtil,
} from '@/utils';
import { getLocalAccessToken } from '@/utils/localStorageUtil';
import { getProfile } from './authService';

export async function getAccountInformation() {
  try {
    const res = await http.get(ApiUser.getAccountInformation);

    return parse.camelize(res);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }

    return error.response;
  }
}

export async function updateDisplayName({ displayName }) {
  try {
    const res = await http.post(ApiUser.updateAccountDisplayName, parse.snakelize({ displayName }));

    return parse.camelize(res);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function updatePassword({
  oldPassword,
  newPassword,
  confirmPassword,
}) {
  const payload = parse.snakelize({
    oldPassword,
    newPassword,
    confirmPassword,
  });

  try {
    const res = await http.post(ApiUser.updateAccountPassword, payload);

    return parse.camelize(res);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function changeAccountSupportDetail({
  supportEmail,
  supportWebsiteUrl,
  supportPhoneNumber,
}) {
  const payload = parse.snakelize({
    supportEmail,
    supportWebsiteUrl,
    supportPhoneNumber,
  });

  try {
    const res = await http.post(ApiUser.changeAccountSupportDetail, payload);
    await getProfile();
    return parse.camelize(res);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function updateContactNumber(data) {
  const payload = parse.snakelize(data);

  try {
    const res = await http.post(ApiUser.updateAccountContactNumber, payload);

    return parse.camelize(res);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }

    return error.response;
  }
}

export async function generateOTP({ action, method }) {
  try {
    const token = getLocalAccessToken();
    const payload = parse.snakelize({
      token,
      action,
      method,
    });
    const res = await http.post(ApiUser.generateOTP, payload);
    return parse.camelize(res);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }

    return error.response;
  }
}

export async function validateOTP({ action, otpCode }) {
  try {
    const payload = parse.snakelize({
      action,
      otpCode,
    });
    const res = await http.post(ApiUser.validateOTP, payload);
    return parse.camelize(res);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }

    return error.response;
  }
}

export async function uploadAccountLogo(file) {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const res = await http.post(ApiUser.uploadAccountLogo, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return parse.camelize(res);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function update2FA({ enable, otpToken }) {
  try {
    const payload = parse.snakelize({ isMfaEnabled: enable, otpToken });
    const res = await http.post(ApiUser.changeMfa, payload);
    messageNotifyUtil.setSuccessMessage({
      title: 'Two-step Verification',
      description: 'Two-step Verification has been updated.',
    });

    return parse.camelize(res);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function updateThemeColor({ themeColor }) {
  try {
    const payload = parse.snakelize({ themeColor });
    const res = await http.post(ApiUser.updateCompanyDetail, payload);

    return parse.camelize(res);
  } catch (error) {
    return error.response;
  }
}

export async function getCompanyDetail() {
  try {
    const res = await http.get(ApiUser.getCompanyDetail);

    return parse.camelize(res);
  } catch (error) {
    return error.response;
  }
}

export async function updateConfigurations({ emails, sms, refundSpeed }) {
  try {
    const payload = parse.snakelize({ emails, sms, refundSpeed });
    const { status } = await http.post(ApiUser.changeConfigurations, payload);
    if (status === 200) {
      messageNotifyUtil.setSuccessMessage({
        title: 'Update Setting Configurations',
        description: 'You\'ve successfully updated configurations',
      });
      await getProfile();
      return {
        success: true
      };
    }
    return {
      success: false
    };
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }

    return {
      success: false
    };
  }
}
