import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import List from '@/components/PaymentLink';
import { ROUTES } from '@/constants';
import { formatCurrency, parseDateTime } from '@/utils/formatter';
import { PaymentLinkStatus } from '@/constants/paymentlink';
import { getPaymentLinks } from '@/services/PaymentLinkService';
import moment from 'moment';

function PaymentLink() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });
  const [totalPages, setTotalPage] = useState(10);
  const renderLinkID = (text, record) => (
    <Link
      className="text-link"
      to={`${ROUTES.APP.PAYMENT_LINKS_PATH}/${record.id}`}
    >
      {text}
    </Link>
  );

  const renderShortLink = (link) => link ? (
    <a className="text-link" href={link} rel="noreferrer" target='_blank'>{link}</a>
  ) : '--';

  const renderStatus = (text) => {
    let status;
    switch (text) {
      case 'Paid':
        status = 'pending';
        break;
      case 'Cancelled':
        status = 'failed';
        break;
      case 'Expired':
        status = 'expired';
        break;
      default:
        status = 'active';
        break;
    }

    return <span className={`text-status-${status}`}>{text}</span>;
  };
  const columns = [
    {
      title: 'Link ID',
      dataIndex: 'linkId',
      key: 'linkId',
      render: renderLinkID,
      fixed: 'left',
    },
    {
      title: 'Reference ID',
      dataIndex: 'referenceId',
      key: 'referenceId',
      width: 160
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      width: 130,
    },
    {
      title: 'Payment Link',
      key: 'paymentLink',
      dataIndex: 'paymentLink',
      render: renderShortLink,
      ellipsis: true,
    },
    {
      title: 'Customer',
      key: 'email',
      dataIndex: 'email',
      ellipsis: true,
    },
    {
      title: 'Created On',
      key: 'createdOn',
      dataIndex: 'createdOn',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: renderStatus,
      align: 'center',
      width: 130
    },
  ];

  const fetchData = async (pagy) => {
    setLoading(true);
    const res = await getPaymentLinks(pagy);
    setData(res.data?.map(obj => ({
      id: obj.id,
      key: obj.id,
      linkId: obj.id,
      referenceId: obj.refId,
      amount: formatCurrency(obj.amount ? obj.amount / 100 : 0),
      createdOn: parseDateTime(obj.createdAt),
      status: PaymentLinkStatus[obj.status],
      email: obj.customerEmail,
      paymentLink: obj.url,
    })));
    setTotalPage(res.pagination.totalPages);
    setLoading(false);
  };

  const onChangeTable = (params) => {
    const obj = {
      ...filter,
      ...params,
    };
    setFilter(obj);
    fetchData(obj);
  };

  const search = async (values) => {
    const newFilter = filter;
    if (values.status) {
      newFilter.status = values.status;
    } else {
      delete newFilter.status;
    }

    if (values.customerEmail) {
      newFilter.customerEmail = values.customerEmail;
    } else {
      delete newFilter.customerEmail;
    }

    if (values.daterange?.length === 2) {
      const [from, to] = values.daterange;
      newFilter.createdFrom = moment(`${from.format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD hh:mm:ss').utc().format();
      newFilter.createdTo = moment(`${to.format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD hh:mm:ss').utc().format();
    } else {
      delete newFilter.createdFrom;
      delete newFilter.createdTo;
    }

    setFilter(newFilter);
    fetchData(newFilter);
  };

  return (
    <List
      loading={loading}
      data={data}
      columns={columns}
      onChangeTable={onChangeTable}
      totalPages={totalPages}
      search={search}
    />
  );
}

export default PaymentLink;
