/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import RPButton from '@/components/RPButton';

function DetailCard({ data, showModal, cancelLink, changeExpiry, changeRefID }) {
  const status = data.status?.toLowerCase();
  const hasInfoContact = data.contactEmail || data.contactNumber;
  return (
    <div className="detail__card">
      <Row>
        <Col flex="50%" className="detail__card__left">
          <Row gutter={[12, 12]}>
            <Col flex="50%" className="detail__card__label">
              Link Type
            </Col>
            <Col flex="50%">{data.linkType}</Col>
            <Col flex="50%" className="detail__card__label">
              Payment For
            </Col>
            <Col flex="50%">{data.paymentFor || '--'}</Col>
            <Col flex="50%" className="detail__card__label">
              Status
            </Col>
            <Col flex="50%" className={`payment-link__group payment-link__status --${status}`}>
              {data.status}
              {status === 'active' && (
                <a
                  href="#"
                  onClick={() => cancelLink()}
                  className="detail__card__link"
                >
                  Cancel Link
                </a>
              )}
            </Col>
            <Col flex="50%" className="detail__card__label">
              Amount
            </Col>
            <Col flex="50%">{data.amount}</Col>
            <Col flex="50%" className="detail__card__label">
              Amount Paid
            </Col>
            <Col flex="50%">
              <p className="mb-0">{data.amountPaid}</p>
              {status === 'paid' && (
                <>
                  <p className="mb-0 payment-link__text-small payment-link__text-blue">
                    {data.paymentId}
                  </p>
                  <p className="mb-0 payment-link__text-small">{data.paidOn}</p>
                </>
              )}
            </Col>
            <Col flex="50%" className="detail__card__label">
              Link URL
            </Col>
            <Col flex="50%">{
              data.linkUrl ? (
                <a href={data.linkUrl} rel="noreferrer" target='_blank'>{data.linkUrl}</a>
              ) : '--'
            }</Col>
            <Col flex="50%" className="detail__card__label">
              Customer Detail
            </Col>
            <Col flex="50%">
              <p className="mb-0">{data.contactEmail || '--'}</p>
              <p className="mb-0">{data.contactNumber || '--'}</p>
            </Col>
            <Col flex="50%" className="detail__card__label">
              Reminders
            </Col>
            <Col flex="50%">
              {
                !hasInfoContact && (
                  'No contact details present for reminders to be sent'
                )
              }
              {hasInfoContact && data.reminders}
              {
                hasInfoContact && (
                  <RPButton onClick={() => showModal()} className="mt-3" disabled={status === 'paid'}>
                    Set Up Reminder
                  </RPButton>
                )
              }
            </Col>
            <Col flex="50%" className="detail__card__label">
              Reference ID
            </Col>
            <Col flex="50%" className={data.referenceId ? '' : 'payment-link__group'}>
              {data.referenceId || '--'}
              <a
                href="#"
                onClick={() => changeRefID()}
                className="detail__card__link"
              >
                Change
              </a>
            </Col>
            {status === 'active' && (
              <>
                <Col flex="50%" className="detail__card__label">
                  Created By
                </Col>
                <Col flex="50%">{data.createdBy}</Col>
              </>
            )}
          </Row>
        </Col>
        <Col flex="50%" className="detail__card__right">
          <Row gutter={[12, 12]}>
            {status !== 'active' && (
              <>
                <Col flex="50%" className="detail__card__label">
                  Created By
                </Col>
                <Col flex="50%">{data.createdBy}</Col>
              </>
            )}
            <Col flex="50%" className="detail__card__label">
              Created At
            </Col>
            <Col flex="50%">{data.createdAt}</Col>
            <Col flex="50%" className="detail__card__label">
              Expires On
            </Col>
            <Col flex="50%" className='payment-link__group'>
              {data.expiresOn}
              <a
                href="#"
                onClick={() => changeExpiry()}
                className="detail__card__link"
              >
                Change
              </a>
            </Col>
            <Col flex="50%" className="detail__card__label">
              Notes
            </Col>
            <Col flex="50%">{data.notes || '--'}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

DetailCard.propTypes = {
  data: PropTypes.object,
  showModal: PropTypes.func,
  cancelLink: PropTypes.func,
  changeExpiry: PropTypes.func,
  changeRefID: PropTypes.func
};

export default DetailCard;
