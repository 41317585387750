import React, { useState } from 'react';
import { resetPassword } from '@/services/authService';
import ForgotPassword from '@/components/Auth/ForgotPassword';
import SentResetPWInstruction from '@/components/Auth/SentResetPWInstruction';


function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [sendResetDone, setSendResetDone] = useState(false);
  const [emailInputted, setEmailInputted] = useState();

  const handleResetPassword = async (values) => {
    setLoading(true);
    const { email } = values;
    setEmailInputted(email);
    const res = await resetPassword({ email });

    if (res.status === 200) {
      setSendResetDone(true);
    }

    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  return (
    <>
      {!sendResetDone &&
        <ForgotPassword
          handleResetPassword={handleResetPassword}
          loading={loading}
        />
      }
      {sendResetDone &&
        <SentResetPWInstruction
          email={emailInputted}
        />
      }
    </>
  );
}

export default ForgotPasswordPage;
