import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Radio,
} from 'antd';
import {
  uniqueId
} from 'lodash';
import './index.scss';

function RPRadio({
  options,
  childrens,
  ...props
}) {
  return (
    <div className="rp-radio-container">
      <Radio.Group
        {...props}
      >
        {(!childrens || childrens.length === 0) &&
          options.map((opt) => (
            <Radio key={uniqueId('rp-radio-')} value={opt.value}>
              {opt.label}
              {
                opt.hint && <span className='rp-radio-container__hint'>{opt.hint}</span>
              }
            </Radio>
          ))
        }
        {childrens?.length > 0 &&
          options.map((opt, index) => (
            <Fragment key={uniqueId('rp-radio-')}>
              <Radio value={opt.value}>{opt.label}</Radio>
              {childrens[index]}
            </Fragment>
          ))
        }
      </Radio.Group>
    </div>
  );
}

RPRadio.propTypes = {
  options: PropTypes.array,
  childrens: PropTypes.array,
};

export default RPRadio;
