import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, message } from 'antd';

import { uploadFile } from '@/services/uploadFileService';

import RPButton from '@/components/RPButton';
import { formatBytes } from '@/utils';
import PdfIcon from '@/assets/images/fileupload/pdf-icon.svg';
import UploadDone from './Done';
import UploadError from './Error';
import UploadProcessing from './Processing';

import './index.scss';

const { Dragger } = Upload;

function RPUpload({
  action,
  accept,
  onChange,
  maxCount = 1,
  uploadClassName = 'd-block',
  ...props
}) {
  const [uploadKlassName, setUploadKlassName] = useState(uploadClassName);
  const [uploadProgres, setUploadProgres] = useState(0);

  const itemRender = (originNode, file, fileList, actions) => {
    const { name, status, size } = file;
    const { remove } = actions;
    return (
      <div className="ant-upload-list ant-upload-list-picture">
        {status === 'done' && (
          <UploadDone
            name={name}
            remove={remove}
            file={file}
            fileSize={size ? formatBytes(size) : null}
          />
        )}

        {status === 'error' && (
          <UploadError
            name={name}
            remove={remove}
            fileSize={size ? formatBytes(size) : null}
          />
        )}

        {status === 'uploading' &&
          <UploadProcessing
            percent={uploadProgres}
          />
        }
      </div>
    );
  };

  const handleCustomRequest = (options) => {
    const { file, onSuccess, onError, onProgress } = options;
    const data = new FormData();
    data.append('file', file);

    const config = {
      onUploadProgress: e => {
        onProgress({ percent: (parseFloat(e.loaded / e.total).toFixed(2) * 100) });
      }
    };

    uploadFile({ url: options.action, data, config })
      .then(res => {
        setUploadProgres(100);
        return onSuccess(res.data, file);
      })
      .catch(err => onError(err));
  };

  const handleOnChange = ({ file, fileList }) => {
    if (file.status === 'uploading') {
      const percent = parseFloat(file.percent.toFixed(2));
      if (percent > 5) {
        setUploadProgres(percent - 5);
      } else {
        setUploadProgres(percent);
      }
    }

    if (maxCount === fileList.length) {
      setUploadKlassName('d-none');
    }

    const { status } = file;

    if (status === 'done') {
      onChange && onChange(file);
      message.success(`${file.name} file uploaded successfully.`);
    }

    if (status === 'error') {
      onChange && onChange(undefined);
      message.error(`${file.name} file upload failed.`);
    }
  };

  const handleOnRemove = () => {
    setUploadKlassName('d-block');
    onChange && onChange(undefined);
  };

  const renderTextHintFileSupport = () => {
    const filesSupport = accept.replaceAll('.', '').toUpperCase();
    if (accept.split(',').length > 1) {
      return (`Files Supported: ${filesSupport} (Max 3mb)`);
    }
    return (`File Supported: ${filesSupport} (Max 3mb)`);
  };

  return (
    <div className="rp-upload">
      <Dragger
        {...props}
        accept={accept}
        action={action}
        className={uploadKlassName}
        itemRender={itemRender}
        onChange={handleOnChange}
        onRemove={handleOnRemove}
        customRequest={handleCustomRequest}
      >
        <div className="ant-upload-drag-icon">
          <img src={PdfIcon} alt="upload icon" width={31} height={39} />
        </div>
        <p className="ant-upload-text">
          Drag and Drop file here
          <br />
          {renderTextHintFileSupport()}
        </p>
        <RPButton
          grey
          center
          className="btn-browser-file"
        >
          Browse File
        </RPButton>
      </Dragger>
    </div>
  );
}

RPUpload.propTypes = {
  accept: PropTypes.string,
  onChange: PropTypes.func,
  maxCount: PropTypes.number,
  uploadClassName: PropTypes.string,
  action: PropTypes.string.isRequired,
};

export default RPUpload;
