import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col
} from 'antd';
import './index.scss';

function DetailCaptured({ data }) {
  const {
    webhookUrl,
    secretAvailability,
    activeEvents,
    email,
    lastUpdated,
    status,
    createdAt
  } = data;

  return (
    <div className="detail__card webhooks-detail">
      <Row>
        <Col flex="50%" className="detail__card__left">
          <Row gutter={[12, 12]}>
            <Col span={12} className="detail__card__label">Webhook URL</Col>
            <Col span={12} className='text-break'>{webhookUrl}</Col>
            <Col span={12} className="detail__card__label">Status</Col>
            <Col span={12}>
              <div className='webhooks-detail__group-status'>
                {status}
                <p className='webhooks-detail__group-status__link'>Enable</p>
              </div>

            </Col>
            <Col span={12} className="detail__card__label">Secret Availability</Col>
            <Col span={12}>
              {secretAvailability}
            </Col>
            <Col span={12} className="detail__card__label">Active Events</Col>
            <Col span={12}>
              {`${activeEvents.length} active events`}
              <br/>
              <span className='webhooks-detail__event'>{activeEvents.join(', ')}</span>
            </Col>
            <Col span={12} className="detail__card__label">Notification email to</Col>
            <Col span={12}>{email}</Col>
            <Col span={12} className="detail__card__label">Last Updated By</Col>
            <Col span={12}>
              {email}
              <br/>
              {lastUpdated}
            </Col>
            <Col span={12} className="detail__card__label">Created By</Col>
            <Col span={12}>
              {email}
              <br/>
              {createdAt}
            </Col>
          </Row>
        </Col>
        <Col flex="50%" className="detail__card__right"/>
      </Row>
    </div>
  );
}

DetailCaptured.propTypes = {
  data: PropTypes.object,
};

export default DetailCaptured;
