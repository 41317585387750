import React from 'react';
import { useParams } from 'react-router-dom';

import PaymentPageDetail from '@/containers/PaymentPage/Detail';

import './show.scss';

function Show() {
  const { id } = useParams();

  return (
    <div
      className="payment-pages__container"
    >
      <PaymentPageDetail id={id} />
    </div>
  );
}

export default Show;
