import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Select } from 'antd';
import { uniqueId } from 'lodash';

import RPRadio from '@/components/RPRadio';
import RPSelect from '@/components/RPSelect';
import RPButton from '@/components/RPButton';
import './modal.scss';

const { Option } = Select;

const radioOptions = [
  { value: true, label: 'For links with an expiration date' },
  { value: false, label: 'For links without an expiration date' },
];

const days = [1, 2, 3, 4, 5, 7, 10, 14, 21];

function ModalReminder({ isModalVisible, setIsModalVisible, form, hasExpiry, onSubmit, reminder }) {
  const [day, setDay] = useState(reminder);

  const onChangeReminder = (e) => {
    setDay(e);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = () => {
    onSubmit(day);
    setIsModalVisible(false);
  };

  useEffect(() => {
    setDay(reminder);
  }, [reminder]);

  const data = radioOptions.filter(op => op.value === hasExpiry);
  const withExpiryDay = (
    <div className="payment-link__expiry-day">
      <RPSelect
        defaultValue={day}
        onChange={onChangeReminder}
        className="payment-link__expiry-day__select --custom"
      >
        {days.map((item) => (
          <Option key={uniqueId('expire-date-option-')} value={item}>
            {item}
          </Option>
        ))}
      </RPSelect>
      <p className="payment-link__expiry-day__label">days before expiration date</p>
    </div>
  );

  const withoutExpiryDay = (
    <div className="payment-link__expiry-day">
      <RPSelect
        defaultValue={day}
        onChange={onChangeReminder}
        className="payment-link__expiry-day__select --custom"
      >
        {days.map((item) => (
          <Option key={uniqueId('without-expire-date-option-')} value={item}>
            {item}
          </Option>
        ))}
      </RPSelect>
      <p className="payment-link__expiry-day__label">days after creation</p>
    </div>
  );

  return (
    <Modal
      title="Set Up Reminder"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="rp-modal payment-link__modal-reminder"
      centered
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        onFinish={onFinish}
        noValidate
        initialValues={{ withExpiry: hasExpiry }}
      >
        <Form.Item
          rules={[{ required: true, message: 'Please set up reminder' }]}
          wrapperCol={{ span: 24 }}
          name="withExpiry"
        >
          <RPRadio
            options={data}
            name="withExpiry"
            childrens={hasExpiry ? [withExpiryDay] : [withoutExpiryDay]}
          />
        </Form.Item>
        <RPButton htmlType="submit" gradient center>
          Update
        </RPButton>
      </Form>
    </Modal>
  );
}

ModalReminder.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  form: PropTypes.object,
  hasExpiry: PropTypes.bool,
  onSubmit: PropTypes.func,
  reminder: PropTypes.number
};

export default ModalReminder;
