import { maxBy, minBy } from 'lodash';
import moment from 'moment';

const GetXAxisDataWeekly = (data, startDate, endDate) => {
  const startDateClone = startDate.clone();
  const dates = [];

  while (startDateClone.isSameOrBefore(endDate)) {
    dates.push(startDateClone.format('YYYY-MM-DD'));
    startDateClone.add(1, 'week').startOf('isoWeek');
  }
  return dates;
};

// startDate, endDate is a moment value
// group: [day, week, month]
const GetXAxisData = (data, startDate, endDate, group) => {
  const startDateClone = startDate.clone();
  let dates = [];
  while (startDateClone.isSameOrBefore(endDate)) {
    dates.push(startDateClone.format('YYYY-MM-DD'));
    startDateClone.add(1, group);
  }
  if (group === 'week') {
    dates = GetXAxisDataWeekly(data, startDate, endDate);
  }
  return dates;
};

export const calculateCurrentPaymentPercent = (data) => {
  const minDefaultPercent = 0.1; // 10%
  const numberOfStatus = 3;

  let { activeAmount, paidAmount, pendingAmount, totalAmount } = data;
  activeAmount = Number(activeAmount);
  paidAmount = Number(paidAmount);
  pendingAmount = Number(pendingAmount);
  totalAmount = Number(totalAmount);

  // paidAmount == activeAmount == pendingAmount
  if (paidAmount === activeAmount && activeAmount === pendingAmount) {
    const percentage = 100 / 3;
    return ({
      paid: `${percentage}%`,
      active: `${percentage}%`,
      pending: `${percentage}%`,
    });
  }
  let numberOfStatusEqualZero = 0;
  if (paidAmount === 0) {
    numberOfStatusEqualZero += 1;
  }
  if (activeAmount === 0) {
    numberOfStatusEqualZero += 1;
  }
  if (pendingAmount === 0) {
    numberOfStatusEqualZero += 1;
  }
  const calculatePercent = (amount) => {
    if (amount === 0) {
      return minDefaultPercent * 100;
    }
    // eslint-disable-next-line max-len
    return (amount / totalAmount - numberOfStatusEqualZero * minDefaultPercent / (numberOfStatus - numberOfStatusEqualZero)) * 100;
  };
  return ({
    paid: `${calculatePercent(paidAmount)}%`,
    active: `${calculatePercent(activeAmount)}%`,
    pending: `${calculatePercent(pendingAmount)}%`,
  });
};

export const parsePIChartData = ({ data, groupBy, filterRange }) => {
  const minDateBaseOnData = minBy(data, obj => obj.timestamp).timestamp;
  const maxDateBaseOnData = maxBy(data, obj => obj.timestamp).timestamp;
  const startDate = moment(filterRange[0] || minDateBaseOnData);
  const endDate = moment(filterRange[1] || maxDateBaseOnData);
  const dateList = GetXAxisData(data, startDate, endDate, groupBy);
  return dateList.map(date => {
    let name = moment(date).format('MMM DD');
    if (groupBy === 'month') {
      name = moment(date).format('MMM');
    }
    const obj = data.find(item => moment(item.timestamp).format('YYYY-MM-DD') === date);

    if (obj) {
      return {
        name,
        sales: Number(obj.sale || 0) / 100,
        paymentVolumn: Number(obj.paymentMethod || 0) / 100,
      };
    }

    return {
      name,
      sales: 0,
      paymentVolumn: 0,
    };
  });
};
