/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import {
  Row,
  Col,
  Form,
  Space,
  Select,
} from 'antd';
import RPCheckbox from '@/components/RPCheckbox';
import RPUpload from '@/components/RPUpload';
import RPInput from '@/components/RPInput';
import RPButton from '@/components/RPButton';
import RPSelect from '@/components/RPSelect';
import PreviewPdf from '@/components/PreviewPdf';
import kycBoverIcon from '@/assets/images/kyc/kyc-bover-icon.svg';
import checkedIcon from '@/assets/images/kyc/checked-icon.svg';
import uncheckedIcon from '@/assets/images/kyc/unchecked-icon.svg';
import uploadSuccessIcon from '@/assets/images/fileupload/pdf-icon.svg';

import { ApiKYC } from '@/constants/api';
import { ENV } from '@/constants';
import {
  StateList
} from '@/constants/kyc';

const { Option } = Select;

function BusinessDetail({
  clickBack,
  onSaveAndExit,
  submitLoading,
  setbusinessDetail,
  businessDetailData,
  saveAndExitLoading,
  readOnly
}) {
  const kycDocumentUpload = `${ENV.API_BASE_URL}${ApiKYC.uploadKycDocument}`;
  const [formCheck, setFormCheck] = useState([
    { label: 'PAN Details', value: false, id: 'pan' },
    { label: 'Billing Details', value: false, id: 'billing' },
    { label: 'Upload document', value: false, id: 'upload' },
  ]);
  const [pan, setPan] = useState({
    businessPan: businessDetailData.businessPan,
    panHoldername: businessDetailData.panHoldername,
    panAuthorisedSignatory: businessDetailData.panAuthorisedSignatory,
    panOwnerName: businessDetailData.panOwnerName,
    cin: businessDetailData.cin,
  });
  const [billing, setBilling] = useState({
    billingLabel: businessDetailData.billingLabel,
    billingAddress: businessDetailData.billingAddress,
    billingPinCode: businessDetailData.billingPinCode,
    billingCity: businessDetailData.billingCity,
    billingState: businessDetailData.billingState,
  });
  const [certificate, setCertificate] = useState(businessDetailData.companyRegistrationCertificate);
  const [cheque, setCheque] = useState(businessDetailData.cancelledCheque);

  const isFilled = (object) => {
    for (const property in object) {
      if (typeof object[property] !== 'boolean' && !object[property]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const formChecked = [...formCheck];
    const objIndex = formChecked.findIndex((obj => obj.id === 'upload'));

    formChecked[objIndex].value = (!!certificate.uid && !!cheque.uid) ||
      (!!certificate.url && !!cheque.url) ||
      (!!certificate.url && !!cheque.uid) ||
      (!!certificate.uid && !!cheque.url);

    setFormCheck(formChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificate, cheque]);

  useEffect(() => {
    const formChecked = [...formCheck];
    const objIndex = formChecked.findIndex((obj => obj.id === 'pan'));
    formChecked[objIndex].value = isFilled(pan);
    setFormCheck(formChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pan]);

  useEffect(() => {
    const formChecked = [...formCheck];
    const objIndex = formChecked.findIndex((obj => obj.id === 'billing'));
    formChecked[objIndex].value = isFilled(billing);
    setFormCheck(formChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billing]);

  const onChangeCertificate = (file) => {
    setCertificate({
      uid: file?.uid,
      url: null,
      name: file?.name,
    });

    if (file && file.status === 'done') {
      setbusinessDetail({
        ...businessDetailData,
        companyRegistrationCertificate: {
          ...businessDetailData.companyRegistrationCertificate,
          uid: file.response.id,
        },
      });
    } else {
      setbusinessDetail({
        ...businessDetailData,
        companyRegistrationCertificate: {
          ...businessDetailData.companyRegistrationCertificate,
          uid: null,
        },
      });
    }
  };

  const onChangeCheque = file => {
    setCheque({
      uid: file?.uid,
      url: null,
      name: file?.name,
    });

    if (file && file.status === 'done') {
      setbusinessDetail({
        ...businessDetailData,
        cancelledCheque: {
          ...businessDetailData.cancelledCheque,
          uid: file.response.id,
        },
      });
    } else {
      setbusinessDetail({
        ...businessDetailData,
        cancelledCheque: {
          ...businessDetailData.cancelledCheque,
          uid: null,
        },
      });
    }
  };

  const onChange = (e) => {
    let { value } = e.target;

    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }

    if (Object.keys(pan).includes(e.target.name)) {
      setPan({
        ...pan,
        [e.target.name]: value
      });
      setbusinessDetail({
        ...businessDetailData,
        [e.target.name]: value,
      });
    } else {
      setBilling({
        ...billing,
        [e.target.name]: value
      });
      setbusinessDetail({
        ...businessDetailData,
        [e.target.name]: value,
      });
    }
  };

  const onChangeBillingState = value => {
    setbusinessDetail({
      ...businessDetailData,
      billingState: value,
    });
    setBilling({
      ...billing,
      billingState: value,
    });
  };

  const onChangeOperationState = value => {
    setbusinessDetail({
      ...businessDetailData,
      operationState: value,
    });

    setBilling({
      ...billing,
      operationState: value,
    });
  };

  const [urlPdf, setUrlPdf] = useState('');
  const [namePDF, setNamePDF] = useState('');
  const [visible, setVisible] = useState(false);
  const openFile = (url, name) => {
    setUrlPdf(url);
    setNamePDF(name);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const isCompleted = () => formCheck.filter(f => !f.value).length > 0;

  return (
    <div>
      <div className="dashboard-kyc__card">
        <Row>
          <Col flex="50%" className="dashboard-kyc__card__left">
            <img
              src={kycBoverIcon}
              alt="icon"
              width="34.5px"
              height="42px"
            />
            <h2 className="dashboard-kyc__card__left__title">Business Details</h2>
            <p className="dashboard-kyc__card__left__desc">Form Checklist</p>
            {
              formCheck.map((data) => (
                <div
                  key={uniqueId('form-check-')}
                  className="dashboard-kyc__card__left__desc --group"
                >
                  <img
                    src={data.value ? checkedIcon : uncheckedIcon}
                    alt="icon"
                    width="24px"
                    height="24px"
                  />
                  {data.label}
                </div>
              ))
            }
          </Col>
          <Col flex="50%" className="dashboard-kyc__card__right">
            <p className="dashboard-kyc__card__right__title">PAN Details</p>
            <Form.Item
              label=""
              name={['businessDetail', 'businessPan']}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your business' }]}
            >
              <RPInput
                name="businessPan"
                className="dashboard-kyc__card__right__input --custom"
                placeholder="Business PAN"
                label="Business PAN"
                required
                onChange={onChange}
                disabled={readOnly}
              />
            </Form.Item>
            <Form.Item
              label=""
              name={['businessDetail', 'panHoldername']}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your holdername' }]}
            >
              <RPInput
                className="dashboard-kyc__card__right__input --custom"
                placeholder="Business Name"
                label="Business Name"
                name="panHoldername"
                required
                onChange={onChange}
                disabled={readOnly}
              />
            </Form.Item>
            <Form.Item
              label=""
              name={['businessDetail', 'panAuthorisedSignatory']}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your authorised signatory' }]}
            >
              <RPInput
                className="dashboard-kyc__card__right__input --custom"
                placeholder="Authorised Signatory PAN"
                label="Authorised Signatory PAN"
                name="panAuthorisedSignatory"
                required
                onChange={onChange}
                disabled={readOnly}
              />
            </Form.Item>
            <Form.Item
              label=""
              name={['businessDetail', 'panOwnerName']}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your owner name' }]}
            >
              <RPInput
                className="dashboard-kyc__card__right__input --custom"
                placeholder="PAN Owner’s Name"
                label="PAN Owner’s Name"
                name="panOwnerName"
                required
                onChange={onChange}
                disabled={readOnly}
              />
            </Form.Item>
            <Form.Item
              label=""
              name={['businessDetail', 'cin']}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your CIN' }]}
            >
              <RPInput
                className="dashboard-kyc__card__right__input --custom"
                placeholder="CIN"
                label="CIN"
                name="cin"
                required
                onChange={onChange}
                disabled={readOnly}
              />
            </Form.Item>
            <p className="dashboard-kyc__card__right__title mt-3">Billing Details</p>
            <Form.Item
              label=""
              name={['businessDetail', 'billingLabel']}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your billing label' }]}
            >
              <RPInput
                className="dashboard-kyc__card__right__input --custom"
                placeholder="Billing Label"
                label="Billing Label"
                name="billingLabel"
                required
                onChange={onChange}
                disabled={readOnly}
              />
            </Form.Item>
            <Form.Item
              label=""
              name={['businessDetail', 'billingAddress']}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your address' }]}
            >
              <RPInput
                className="dashboard-kyc__card__right__input --custom"
                placeholder="Address"
                label="Address"
                name="billingAddress"
                required
                onChange={onChange}
                disabled={readOnly}
              />
            </Form.Item>
            <Form.Item
              label=""
              name={['businessDetail', 'billingPinCode']}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your PIN code' }]}
            >
              <RPInput
                className="dashboard-kyc__card__right__input --custom"
                placeholder="PIN Code"
                label="PIN Code"
                name="billingPinCode"
                required
                onChange={onChange}
                disabled={readOnly}
              />
            </Form.Item>
            <Form.Item
              label=""
              name={['businessDetail', 'billingCity']}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your city' }]}
            >
              <RPInput
                className="dashboard-kyc__card__right__input --custom"
                placeholder="City"
                label="City"
                name="billingCity"
                required
                onChange={onChange}
                disabled={readOnly}
              />
            </Form.Item>

            <Form.Item
              label=""
              name={['businessDetail', 'billingState']}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please select your state' }]}
            >
              <RPSelect
                placeholder="State"
                label="State"
                name="billingState"
                className="dashboard-kyc__card__right__input --custom w-100"
                showSearch
                required
                onChange={onChangeBillingState}
                disabled={readOnly}
              >
                {
                  StateList.map(({ text, value }) => (
                    <Option
                      key={uniqueId('state-option-')}
                      value={value}
                    >
                      {text}
                    </Option>
                  ))
                }
              </RPSelect>
            </Form.Item>
            <Form.Item
              label=""
              name={['businessDetail', 'isOperationSameBilling']}
              valuePropName="checked"
            >
              <RPCheckbox
                name="isOperationSameBilling"
                onChange={onChange}
                disabled={readOnly}
              >
                Operational Address same as above
              </RPCheckbox>
            </Form.Item>
            {!businessDetailData.isOperationSameBilling &&
              <>
                <p className="dashboard-kyc__card__right__title mt-3">Operational Details</p>
                <Form.Item
                  label=""
                  name={['businessDetail', 'operationAddress']}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message: 'Please input your address' }]}
                >
                  <RPInput
                    className="dashboard-kyc__card__right__input --custom"
                    placeholder="Address"
                    label="Address"
                    name="operationAddress"
                    required
                    onChange={onChange}
                    disabled={readOnly}
                  />
                </Form.Item>
                <Form.Item
                  label=""
                  name={['businessDetail', 'operationPinCode']}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message: 'Please input your PIN code' }]}
                >
                  <RPInput
                    className="dashboard-kyc__card__right__input --custom"
                    placeholder="PIN Code"
                    label="PIN Code"
                    name="operationPinCode"
                    required
                    onChange={onChange}
                    disabled={readOnly}
                  />
                </Form.Item>
                <Form.Item
                  label=""
                  name={['businessDetail', 'operationCity']}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message: 'Please input your city' }]}
                >
                  <RPInput
                    className="dashboard-kyc__card__right__input --custom"
                    placeholder="City"
                    label="City"
                    name="operationCity"
                    required
                    onChange={onChange}
                    disabled={readOnly}
                  />
                </Form.Item>
                <Form.Item
                  label=""
                  name={['businessDetail', 'operationState']}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message: 'Please select your operation state' }]}
                >
                  <RPSelect
                    placeholder="State"
                    label="State"
                    className="dashboard-kyc__card__right__input --custom w-100"
                    showSearch
                    name="operationState"
                    required
                    onChange={onChangeOperationState}
                    disabled={readOnly}
                  >
                    {
                      StateList.map(({ text, value }) => (
                        <Option
                          key={uniqueId('state-option-')}
                          value={value}
                        >
                          {text}
                        </Option>
                      ))
                    }
                  </RPSelect>
                </Form.Item>
              </>
            }

            <p className="dashboard-kyc__card__right__title --spacing">Company Registration Certificate</p>
            {
              readOnly && certificate.url ? (
                <img
                  className='cursor-pointer'
                  src={uploadSuccessIcon}
                  alt="upload icon"
                  width={48}
                  height={48}
                  onClick={() => openFile(certificate.url, 'Company Registration Certificate')}
                  aria-hidden="true"
                />
              ) : (
                <RPUpload
                  accept=".pdf"
                  listType='picture'
                  action={kycDocumentUpload}
                  onChange={onChangeCertificate}
                  defaultFileList={(!!certificate.uid || !!certificate.url) && [certificate]}
                  uploadClassName={(!!certificate.uid || !!certificate.url) ? 'd-none' : ''}
                />
              )
            }
            <p className="dashboard-kyc__card__right__title --spacing">Cancelled Cheque</p>
            {
              readOnly && cheque.url ? (
                <img
                  className='cursor-pointer'
                  src={uploadSuccessIcon}
                  alt="upload icon"
                  width={48}
                  height={48}
                  onClick={() => openFile(cheque.url, 'Cancelled Cheque')}
                  aria-hidden="true"
                />
              ) : (
                <RPUpload
                  accept=".pdf"
                  listType='picture'
                  action={kycDocumentUpload}
                  onChange={onChangeCheque}
                  defaultFileList={(!!cheque.uid || !!cheque.url) && [cheque]}
                  uploadClassName={(!!cheque.uid || !!cheque.url) ? 'd-none' : ''}
                />
              )
            }
            {!readOnly &&
              <div className="dashboard-kyc__card__right__term">
                By submitting the form, I here by agree to RadicalPay’s {' '}
                <a
                  href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
                  target="_blank"
                  className="dashboard-kyc__card__right__term --link"
                  rel="noreferrer"
                >Term and Conditions</a>
              </div>
            }
          </Col>
        </Row>
      </div>
      <div className="dashboard-kyc__card__group-btn">
        <RPButton
          grey
          onClick={clickBack}
          disabled={saveAndExitLoading || submitLoading}
        >
          Back
        </RPButton>
        {
          !readOnly && (
            <Space>

              <RPButton
                grey
                disabled={saveAndExitLoading || submitLoading || isCompleted()}
                onClick={onSaveAndExit}
                loading={saveAndExitLoading}
              >
                <div className="dashboard-kyc__card__submit-btn">
                  Save & Exit
                </div>
              </RPButton>

              <RPButton
                gradient
                withArrowIcon
                htmlType="submit"
                disabled={saveAndExitLoading || submitLoading || isCompleted()}
                loading={submitLoading}
              >
                <div className="dashboard-kyc__card__submit-btn">
                  Submit & Verify
                </div>
              </RPButton>
            </Space>
          )
        }
      </div>
      {readOnly &&
        <PreviewPdf
          urlPdf={urlPdf}
          name={namePDF}
          visible={visible}
          handleCancel={handleCancel}
        />
      }
    </div>
  );
}

BusinessDetail.propTypes = {
  clickBack: PropTypes.func,
  onSaveAndExit: PropTypes.func,
  submitLoading: PropTypes.bool,
  saveAndExitLoading: PropTypes.bool,
  setbusinessDetail: PropTypes.func.isRequired,
  businessDetailData: PropTypes.object.isRequired,
  readOnly: PropTypes.bool
};

export default BusinessDetail;
