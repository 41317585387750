import React from 'react';
import ForgotPassword from '@/containers/Auth/ForgotPassword';

function ForgotPasswordPage() {
  return (
    <ForgotPassword />
  );
}

export default ForgotPasswordPage;
