import React from 'react';
import { Modal, Form, message } from 'antd';
import RPTextArea from '@/components/RPTextArea';
import PropTypes from 'prop-types';
import RPButton from '@/components/RPButton';
import './modal.scss';

function ModalButtonCode({
  buttonData,
  isModalVisible,
  setIsModalVisible,
}) {
  const [form] = Form.useForm();
  const data = `<form>
      <script
        async
        src="${process.env.REACT_APP_CHECKOUT_PAGE_BASE_URL}/v1/payment-button.js"
        data-payment_button_id="${buttonData.id}"
      ></script>
    </form>
  `;

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClick = () => {
    setIsModalVisible(false);
    navigator.clipboard.writeText(data);
    message.success('Your button code was copied to clipboard');
  };
  return (
    <Modal
      title="Button Embed Code"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="rp-modal payment-button__modal-custom"
    >
      <p className='payment-button__modal-custom__desc'>
        Copy and paste this code into your website
      </p>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        noValidate
        initialValues={{ code: data }}
      >
        <Form.Item name="code" wrapperCol={{ span: 24 }}>
          <RPTextArea
            placeholder="Your Button Embed Code"
            label="Your Button Embed Code"
            rows={4}
            name="code"
            disabled
          />
        </Form.Item>
        <p className='payment-button__modal-custom__hint'>Not sure how to use this code? Click
          <a href="##">here</a>.
        </p>
        <RPButton gradient center onClick={handleClick}>
          Copy To Clipboard
        </RPButton>
      </Form>
    </Modal>
  );
}
ModalButtonCode.propTypes = {
  buttonData: PropTypes.object.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
};

export default ModalButtonCode;
