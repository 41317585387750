/* eslint-disable max-len */
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '@/constants';
import RPButton from '@/components/RPButton';
import completeIcon from '@/assets/images/kyc/completed-icon.svg';

function Complete() {
  const history = useHistory();

  const handleOnClick = () => {
    history.replace(ROUTES.APP.DASHBOARD_PATH);
  };

  return (
    <div className="dashboard-kyc__complete">
      <div className="dashboard-kyc__complete__top" />
      <div className="dashboard-kyc__complete__content">
        <img
          src={completeIcon}
          alt="icon"
          width="80px"
          height="80px"
        />
        <h1 className="dashboard-kyc__complete__title">Awesome! That’s Great</h1>
        <p
          className="dashboard-kyc__complete__desc"
        >
          Your RadicalPay KYC Form has been submitted Completed! We will let you know when the KYC Form has been approved!
        </p>
        <RPButton
          center
          gradient
          withArrowIcon
          onClick={handleOnClick}
          className="dashboard-kyc__complete__btn-back-to-dashboard"
        >
          <div className="dashboard-kyc__card__submit-btn">
            Back to Dashboard
          </div>
        </RPButton>
      </div>
      <div className="dashboard-kyc__complete__bottom" />
    </div>
  );
}

export default Complete;
