import React, { useEffect } from 'react';
import { Form } from 'antd';
import RPInput from '@/components/RPInput';
import RPButton from '@/components/RPButton';
import PropTypes from 'prop-types';
import RPPhoneNumber from '@/components/RPPhoneNumber';
import { validateEmail, isValidHttpUrl, validatePhoneNumber } from '@/utils/validate';
import './index.scss';

function ContentSupportDetail({ onSubmit, data, form }) {
  useEffect(() => {
    form.setFieldsValue(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div className="my-account__profile__modal__support">
      <p className="mb-0">
        Let your customers know how to reach you for any queries.
      </p>
      <p className="my-account__profile__modal__support__hint">
        Note: These details will be shared with customer in transaction emails.
      </p>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        noValidate
        onFinish={onSubmit}
        initialValues={data}
      >
        <Form.Item
          name="supportPhoneNumber"
          rules={[
            {
              required: true,
              message: 'Please input your phone number',
            },
            () => ({
              validator(_, value) {
                if (value && !validatePhoneNumber(value)) {
                  return Promise.reject(new Error('Support Phone Number Invalid'));
                }

                return Promise.resolve();
              },
            }),
          ]}
          wrapperCol={{ span: 24 }}
        >
          <RPPhoneNumber
            name="supportPhoneNumber"
            className="rp-payment-pages__form__card__right__input --custom"
            specialLabel="Phone No."
            placeholder="Phone No."
            required
            inputProps={{
              name: 'supportPhoneNumber',
              required: true,
            }}
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 24 }}
          label=""
          name="supportEmail"
          rules={[
            { required: true, message: 'Please input your email' },
            () => ({
              validator(_, value) {
                if (value && !validateEmail(value)) {
                  return Promise.reject(
                    new Error('Please input the valid email'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <RPInput
            name="supportEmail"
            placeholder="Support Email ID"
            label="Support Email ID"
            type="email"
            required
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 24 }}
          label=""
          name="supportWebsiteUrl"
          rules={[
            { required: true, message: 'Please input your url' },
            () => ({
              validator(_, value) {
                if (value && !isValidHttpUrl(value)) {
                  return Promise.reject(new Error('Please input the valid url'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <RPInput
            name="supportWebsiteUrl"
            placeholder="Support URL"
            type="Support URL"
            label="Support URL"
          />
        </Form.Item>
        <RPButton
          className="my-account__profile__modal__submit-btn"
          gradient
          center
          htmlType="submit"
        >
          Update
        </RPButton>
      </Form>
    </div>
  );
}

ContentSupportDetail.propTypes = {
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  form: PropTypes.any,
};

export default ContentSupportDetail;
