import React from 'react';

import FormContainer from '@/containers/PaymentPage/FormContainer';

import './new.scss';
import { useParams } from 'react-router-dom';

function Edit() {
  const { id } = useParams();
  return(
    <FormContainer isEdit id={id} />
  );
}

export default Edit;
