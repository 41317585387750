import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import PageHeader from '@/components/PageHeader';
import RPButton from '@/components/RPButton';
import PaymentLink from '@/components/PaymentLink/Detail';
import { ROUTES } from '@/constants';
import { cancelPaymentLink, getPaymentLink, updatePaymentLink, sendPaymentLink } from '@/services/PaymentLinkService';
import { parseDetailLink } from '@/utils/paymentLink';
import { useBeforeRender } from '@/utils';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

function PaymentLinkContainer({
  id
}) {
  const history = useHistory();
  const sendLink = async () => {
    await sendPaymentLink({ id });
  };

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const pageHeaderData = {
    title: `Payment Link ID ${id}`,
    leftBtns: (
      <RPButton
        grey
        onClick={() => history.replace(ROUTES.APP.PAYMENT_LINKS.INDEX)}
      >
        Back
      </RPButton>
    ),
    rightBtns: (
      data.status === 'Active' && (data.contactEmail || data.contactNumber) ? <RPButton
        grey
        onClick={sendLink}
      >
        Send Link
      </RPButton> : null
    )
  };

  const fetchData = async () => {
    const res = await getPaymentLink({ id });
    if (res && res.id) {
      const detail = parseDetailLink(res);
      setData(detail);
    }
    setLoading(false);
  };

  const handleCancelLink = async () => {
    await cancelPaymentLink({ id });
    await fetchData();
  };

  const handleReminder = async (e) => {
    const res = await updatePaymentLink({ id, remindAfter: e });
    if (res.id) {
      fetchData();
    }
  };

  const handleUpdate = async (payload) => {
    const res = await updatePaymentLink({ id, ...payload });
    if (res.id) {
      await fetchData();
    }
  };

  useBeforeRender(() => fetchData(), []);

  return (
    <Spin spinning={loading}>
      <PageHeader
        {...pageHeaderData}
        className="payment-link__header"
      />
      <div
        className="dashboard__container user-portal-payment-link__container"
      >
        <PaymentLink
          data={data}
          handleCancelLink={handleCancelLink}
          handleReminder={handleReminder}
          handleUpdate={handleUpdate}
        />
      </div>
    </Spin>
  );
}

PaymentLinkContainer.propTypes = {
  id: PropTypes.string
};

export default PaymentLinkContainer;
