import http from '@/http';

export async function uploadFile({ url, data, config }) {
  try {
    const configurate = {
      'headers': {
        'content-type': 'multipart/form-data'
      },
      ...config,
    };
    const res = await http.post(url, data, configurate);

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
}
