import React, { useState } from 'react';
import {
  Form,
  Radio,
  Space,
} from 'antd';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import RPButton from '@/components/RPButton';
import ModalComponent from '@/components/ModalComponent';

import {
  availableAdditionalInputFields,
  availableAdditionalPriceFields
} from '@/components/PaymentPages/Step/AvailableAdditionalFields';

function AddFieldModal({
  visible,
  onAddField,
  hideModalAddField,
  ...props
}) {
  const [form] = Form.useForm();
  const [isCompleted, setIsCompleted] = useState('');

  const handleOnChange = (event) => {
    setIsCompleted(!!event.target.value);
  };

  const handleOnFinish = (values) => {
    onAddField(values);
    setIsCompleted(false);
    form.resetFields();
  };

  return (
    <ModalComponent
      title="Add New Field"
      centered
      visible={visible}
      footer={null}
      onCancel={hideModalAddField}
      {...props}
    >
      <Form
        form={form}
        onChange={handleOnChange}
        onFinish={handleOnFinish}
      >
        <div className='modal-body-content'>
          <div className='select-field-group'>
            <Form.Item
              name='additionalField'
            >
              <div>
                <Radio.Group
                  className='select-field-group__options'
                >
                  <div className='select-field-group__item --heading'>
                    <div className='select-field-group__item__icon'>
                      <Icon icon='akar-icons:text-align-justified' />
                    </div>
                    <div className='select-field-group__item__name'>Input Field</div>
                  </div>
                  {availableAdditionalInputFields.length > 0 && availableAdditionalInputFields.map((option) => (
                    <Radio.Button
                      key={uniqueId()}
                      value={option.key}
                      className='select-field-group__item-container'
                    >
                      <div className='select-field-group__item'>
                        <div className='select-field-group__item__icon'>
                          <Icon icon={option.icon} />
                        </div>
                        <div className='select-field-group__item__name'>{option.label}</div>
                      </div>
                    </Radio.Button>
                  ))}

                  <div className='select-field-group__item --heading'>
                    <div className='select-field-group__item__icon'>
                      <Icon icon='ri:price-tag-3-line' />
                    </div>
                    <div className='select-field-group__item__name'>Price Field</div>
                  </div>
                  {availableAdditionalPriceFields.length > 0 && availableAdditionalPriceFields.map((option) => (
                    <Radio.Button
                      key={uniqueId()}
                      value={option.key}
                      className='select-field-group__item-container'
                    >
                      <div className='select-field-group__item'>
                        <div className='select-field-group__item__icon'>
                          <Icon icon={option.icon} />
                        </div>
                        <div className='select-field-group__item__name'>{option.label}</div>
                      </div>
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </div>
            </Form.Item>
          </div>

          <div className='modal-action-btn'>
            <Space align='end' size={24}>
              <RPButton
                grey
                onClick={hideModalAddField}
              >
                Cancel
              </RPButton>
              <RPButton
                gradient
                htmlType='submit'
                disabled={!isCompleted}
              >
                Add
              </RPButton>
            </Space>
          </div>
        </div>
      </Form>
    </ModalComponent>
  );
}

AddFieldModal.propTypes = {
  visible: PropTypes.bool,
  onAddField: PropTypes.func,
  hideModalAddField: PropTypes.func,
};

export default AddFieldModal;
