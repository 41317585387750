/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Detail from '@/components/Webhooks/Detail';
import ModalDelete from '@/components/Webhooks/ModalDelete';
import ModalWebhooks from '@/components/Settings/ModalWebhooks';

const data = {
  id: '1',
  webhookUrl: 'https://rzp.io/l/DYfqKM2B',
  secretAvailability: 'Secret was provided during webhook setup',
  activeEvents: ['payment.authorized'],
  email: 'sachin.tiwari@radicalpay.in',
  lastUpdated: '25 Apr 2022, 11:11 AM',
  status: 'disabled',
  createdAt: '07 Mar 2022, 01:25 PM',
};

function Transactions({
  isEdit,
  isDelete,
  setIsEdit,
  setIsDelete
}) {
  return (
    <div>
      <Detail
        data={data}
      />
      <ModalDelete
        isModalVisible={isDelete}
        setIsModalVisible={setIsDelete}
      />
      <ModalWebhooks
        isModalVisible={isEdit}
        setIsModalVisible={setIsEdit}
        data={data}
      />
    </div>
  );
}

Transactions.propTypes = {
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  setIsEdit: PropTypes.func,
  setIsDelete: PropTypes.func,
};

export default Transactions;
