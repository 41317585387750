import { useState, useEffect } from 'react';

const initBeforeUnLoad = (showExitPrompt) => {
  if (showExitPrompt) {
    window.onbeforeunload = (event) => {
      const e = event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    };
  } else {
    window.onbeforeunload = null;
  }
};

export default function useExitPrompt(bool) {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);

  window.onload = () => {
    initBeforeUnLoad(showExitPrompt);

    return () => {
      window.onbeforeunload = null;
    };
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);

    return () => {
      window.onbeforeunload = null;
    };
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt];
}
