import React, { useEffect, useState } from 'react';
import { useHistory, Prompt } from 'react-router-dom';

import { ROUTES } from '@/constants';
import PageHeader from '@/components/PageHeader';
import FormPaymentLink from '@/components/PaymentLink/FormPaymentLink';
import { parseFormEditPaymentLink, parsePayloadPaymentLink } from '@/utils/paymentLink';
import { createPaymentLink, getPaymentLink, updatePaymentLink } from '@/services/PaymentLinkService';
import useExitPrompt from '@/hooks/useExitPrompt';
import { Spin } from 'antd';
import PropType from 'prop-types';

function FormContainer({ isEdit, id }) {
  const history = useHistory();
  const [paymentDetails, setPaymentDetails] = useState({
    amount: undefined,
    paymentFor: undefined,
    promotionCoupon: undefined,
    linkExpiry: null,
    noExpiryDate: true
  });
  const [customerDetails, setCustomerDetails] = useState({
    email: undefined,
    phoneNo: undefined,
    note: undefined,
    referenceId: undefined
  });
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const [loading, setLoading] = useState(false);

  const pageHeaderData = {
    title: isEdit ? 'Edit Payment Link' : 'Create a New Payment Link',
    description: 'Complete this form to generate a new payment link.',
    headerType: 'white',
  };

  const onSubmit = async (values) => {
    setLoading(true);
    if (isEdit && id) {
      const payload = parsePayloadPaymentLink({ ...values, id });
      const res = await updatePaymentLink(payload);
      if (res.status === 200) {
        setShowExitPrompt(false);
        history.replace(ROUTES.APP.PAYMENT_LINKS.INDEX);
      }
      setLoading(false);
      return;
    }
    const payload = parsePayloadPaymentLink(values);
    const res = await createPaymentLink(payload);
    if (res.status === 201) {
      setShowExitPrompt(false);
      history.replace(ROUTES.APP.PAYMENT_LINKS.INDEX);
    }
    setLoading(false);
  };

  const clickBack = () => {
    history.replace(ROUTES.APP.PAYMENT_LINKS.INDEX);
  };

  const fetchData = async () => {
    const res = await getPaymentLink({ id });
    const data = parseFormEditPaymentLink(res);
    setPaymentDetails({
      ...data.paymentDetails
    });
    setCustomerDetails({
      ...data.customerDetails
    });
  };

  useEffect(() => {
    if (isEdit && id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, id]);

  return (
    <>
      <Prompt
        when={showExitPrompt}
        message="Do you want to leave this page and discard your changes?"
      />
      <Spin spinning={loading}>
        <PageHeader {...pageHeaderData} />
        <div
          className="user-portal-payment-link__new__container"
        >
          <FormPaymentLink
            setShowExitPrompt={setShowExitPrompt}
            paymentDetails={paymentDetails}
            setPaymentDetails={setPaymentDetails}
            customerDetails={customerDetails}
            setCustomerDetails={setCustomerDetails}
            clickBack={clickBack}
            onSubmit={onSubmit}
            isEdit={isEdit}
          />
        </div>
      </Spin>
    </>
  );
}

FormContainer.propTypes = {
  isEdit: PropType.bool,
  id: PropType.string
};

export default FormContainer;
