import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';

function Step({ step }) {
  const status = (index) => {
    switch (index) {
      case 1:
        if (step === 1) {
          return '--processing';
        }

        if (step > 1) {
          return '--done';
        }

        return '';
      case 2:
        if (step === 2) {
          return '--processing';
        }

        if (step === 1) {
          return '';
        }

        return '--done';
      case 3:
        if (step === 3) {
          return '--processing';
        }

        if (step < 3) {
          return '';
        }

        return '--done';
      default:
        return null;
    }
  };
  return (
    <div>
      <Row className="dashboard-kyc__process">
        <Col flex="33%" className={`dashboard-kyc__process__item ${status(1)}`} />
        <Col flex="33%" className={`dashboard-kyc__process__item ${status(2)}`} />
        <Col flex="33%" className={`dashboard-kyc__process__item ${status(3)}`} />
      </Row>
      <Row className="dashboard-kyc__group">
        <Col flex="33%" className={`dashboard-kyc__group__item ${status(1)}`}>
          <p className="mb-0 dashboard-kyc__group__item__number">1</p>
          <p className="mb-0">Page Details</p>
        </Col>
        <Col flex="33%" className={`dashboard-kyc__group__item ${status(2)}`}>
          <p className="mb-0 dashboard-kyc__group__item__number">2</p>
          <p className="mb-0">Payment Details</p>
        </Col>
        <Col flex="33%" className={`dashboard-kyc__group__item ${status(3)}`}>
          <p className="mb-0 dashboard-kyc__group__item__number">3</p>
          <p className="mb-0">Payment Receipts and Page Settings</p>
        </Col>
      </Row>
    </div>
  );
}

Step.propTypes = {
  step: PropTypes.number,
};

export default Step;
