import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'antd';

import PropTypes from 'prop-types';
import { ModalProfileTypes } from '@/constants/Profile';
import ContentDisplayName from '../ContentModalProfile/ContentDisplayName';
import ContentTwoFactorAuthentication from '../ContentModalProfile/ContentTwoFactorAuthentication';
import ContentUpdatePhone from '../ContentModalProfile/ContentUpdatePhone';
import ContentChangePassword from '../ContentModalProfile/ContentChangePassword';
import ContentGSTDetail from '../ContentModalProfile/ContentGSTDetail';
import ContentSupportDetail from '../ContentModalProfile/ContentSupportDetail';
import ContentSettlementDetails from '../ContentModalProfile/ContentSettlementDetails';

function ModalUpdate({
  isModalVisible,
  onSubmit,
  typeModal,
  info,
  handleCancel,
  resendOTP,
  otpExpiredAt,
  otpValid,
  setOtpValid,
}) {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    onSubmit(values);
  };

  const onCancel = () => {
    handleCancel();
  };

  const listTitle = {
    [ModalProfileTypes.displayName]: 'Set Display Name',
    [ModalProfileTypes.twoFactorAuthentication]: 'Two-step Verification',
    [ModalProfileTypes.updatePhone]: 'Phone Number Update',
    [ModalProfileTypes.changePassword]: 'Change Password',
    [ModalProfileTypes.addGSTDetail]: 'Add GST Details',
    [ModalProfileTypes.supportDetail]: 'Support Details',
    [ModalProfileTypes.settlementDetails]: 'Settlement Cycle',
  };

  const [title, setTitle] = useState(listTitle[typeModal]);
  useEffect(() => {
    setTitle(listTitle[typeModal]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeModal]);
  // display name

  const DisplayName = (
    <ContentDisplayName onSubmit={onFinish} data={info} form={form} />
  );

  // 2 step verification
  const [otp, setOTP] = useState('');
  const handleChange = (value) => {
    setOTP(value);
    setOtpValid(undefined);
  };
  const TwoFactorAuthentication = (
    <ContentTwoFactorAuthentication
      onSubmit={onFinish}
      dataOTP={otp}
      handleChange={handleChange}
      resendOTP={resendOTP}
      otpExpiredAt={otpExpiredAt}
      otpValid={otpValid}
    />
  );

  // update phone
  const [isSubmitOTP, setIsSubmitOTP] = useState(false);

  const UpdatePhone = (
    <ContentUpdatePhone
      onSubmit={onFinish}
      data={info}
      form={form}
      isSubmitOTP={isSubmitOTP}
      setIsSubmitOTP={setIsSubmitOTP}
      dataOTP={otp}
      handleChange={handleChange}
    />
  );

  // update password
  const [isVerified, setIsVerified] = useState(false);

  const ChangePassword = (
    <ContentChangePassword
      form={form}
      onSubmit={onFinish}
    />
  );

  // GST detail
  const [certificate, setCertificate] = useState(undefined);
  const GSTDetail = (
    <ContentGSTDetail
      onSubmit={onFinish}
      data={info}
      form={form}
      certificate={certificate}
      setCertificate={setCertificate}
    />
  );

  // SupportDetail

  const SupportDetail = (
    <ContentSupportDetail onSubmit={onFinish} data={info} form={form} />
  );

  // SettlementDetails

  const SettlementDetails = <ContentSettlementDetails data={info} />;

  useEffect(() => {
    if (isVerified) {
      setTitle('Change Password');
    }
  }, [isVerified]);

  const resetModalUpdatePhone = () => {
    setIsSubmitOTP(false);
    setOTP('');
  };

  const resetModalUpdatePassword = () => {
    setIsVerified(false);
    setOTP('');
    form.resetFields();
    setTitle(listTitle[typeModal]);
  };

  const resetGSTDetail = () => {
    setCertificate(undefined);
    form.resetFields();
  };

  const resetForm = () => {
    switch (typeModal) {
      case ModalProfileTypes.displayName:
        form.resetFields();
        break;
      case ModalProfileTypes.twoFactorAuthentication:
        setOTP('');
        break;
      case ModalProfileTypes.updatePhone:
        resetModalUpdatePhone();
        break;
      case ModalProfileTypes.changePassword:
        resetModalUpdatePassword();
        break;
      case ModalProfileTypes.addGSTDetail:
        resetGSTDetail();
        break;
      case ModalProfileTypes.supportDetail:
        form.resetFields();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    resetForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  const renderContentModal = {
    [ModalProfileTypes.displayName]: DisplayName,
    [ModalProfileTypes.twoFactorAuthentication]: TwoFactorAuthentication,
    [ModalProfileTypes.updatePhone]: UpdatePhone,
    [ModalProfileTypes.changePassword]: ChangePassword,
    [ModalProfileTypes.addGSTDetail]: GSTDetail,
    [ModalProfileTypes.supportDetail]: SupportDetail,
    [ModalProfileTypes.settlementDetails]: SettlementDetails,
  };

  const handleAfterCloseModal = () => form.resetFields();

  return (
    <Modal
      destroyOnClose
      title={title}
      visible={isModalVisible}
      onCancel={onCancel}
      footer={null}
      keyboard={false}
      maskClosable={false}
      className="rp-modal my-account__profile__modal"
      afterClose={handleAfterCloseModal}
    >
      {typeModal && renderContentModal[typeModal]}
    </Modal>
  );
}

ModalUpdate.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  typeModal: PropTypes.oneOf(Object.values(ModalProfileTypes)),
  info: PropTypes.object,
  handleCancel: PropTypes.func,
  resendOTP: PropTypes.func,
  otpExpiredAt: PropTypes.string,
  otpValid: PropTypes.bool,
  setOtpValid: PropTypes.func,
};

export default ModalUpdate;
