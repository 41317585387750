import http from '@/http';
import { ApiPaymentButton } from '@/constants/api';
import { generatePath } from 'react-router-dom';
import {
  parse,
  messageNotifyUtil,
} from '@/utils';

export async function getPaymentButtons(filter) {
  try {
    const params = parse.snakelize(filter);
    const { data } = await http.get(ApiPaymentButton.index, { params });
    return parse.camelize(data, { deep: true });
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function createPaymentButton(payload) {
  try {
    const res = await http.post(ApiPaymentButton.new, parse.snakelize(payload));
    messageNotifyUtil.setSuccessMessage({
      title: 'Payment Button Created',
      description: `You've successfully created new payment button (${res.data.id})!`,
    });

    return res;
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function getPaymentButtonsDetail({ id }) {
  try {
    const url = generatePath(ApiPaymentButton.detail, { id });
    const { data } = await http.get(url);
    return parse.camelize(data);
  } catch (error) {
    messageNotifyUtil.setErrorMessage({
      title: null,
      description: error?.response?.data?.message || 'Something went wrong!',
    });
    return error.response;
  }
}

export async function updatePaymentButtonsDetail(payload) {
  try {
    const url = generatePath(ApiPaymentButton.update, { id: payload.id });
    const res = await http.put(url, parse.snakelize(payload));
    messageNotifyUtil.setSuccessMessage({
      title: 'Update payment button',
      description: `Your payment button #${payload.id} has been updated.`,
    });
    return res;
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function publishPaymentButton({ id }) {
  try {
    const url = generatePath(ApiPaymentButton.publish, { id });
    const res = await http.post(url);
    return res;
  } catch (error) {
    messageNotifyUtil.setErrorMessage({
      title: null,
      description: error?.response?.data?.message || 'Something went wrong!',
    });
    return error.response;
  }
}

export async function unpublishPaymentButton({ id }) {
  try {
    const url = generatePath(ApiPaymentButton.unpublish, { id });
    const res = await http.post(url);
    return res;
  } catch (error) {
    messageNotifyUtil.setErrorMessage({
      title: null,
      description: error?.response?.data?.message || 'Something went wrong!',
    });
    return error.response;
  }
}
