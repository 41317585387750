import http from '@/http';
import {
  generatePath,
} from 'react-router-dom';
import {
  parse,
  messageNotifyUtil,
} from '@/utils';
import { ApiPaymentPage } from '@/constants/api';

export async function getPaymentPages(filter) {
  try {
    const params = parse.snakelize(filter);
    const { data } = await http.get(ApiPaymentPage.index, { params });
    return parse.camelize(data);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function getPaymentPagesDetail({ id }) {
  try {
    const url = generatePath(ApiPaymentPage.detail, { id });
    const { data } = await http.get(url);
    return parse.camelize(data);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function createPaymentPage(payload) {
  try {
    const res = await http.post(ApiPaymentPage.new, parse.snakelize(payload));

    if (res?.status === 201) {
      messageNotifyUtil.setSuccessMessage({
        title: 'Create payment page',
        description: 'Your payment page has been created.',
      });
    }
    return res;
  } catch (error) {
    if (error.response?.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function updatePaymentPagesDetail(payload) {
  try {
    const url = generatePath(ApiPaymentPage.update, { id: payload.id });
    const res = await http.put(url, parse.snakelize(payload));
    messageNotifyUtil.setSuccessMessage({
      title: 'Update payment page',
      description: `Your payment page #${payload.id} has been updated.`,
    });
    return res;
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function publishPaymentPage({ id }) {
  try {
    const url = generatePath(ApiPaymentPage.publish, { id });
    const res = await http.post(url);
    return res;
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function unpublishPaymentPage({ id }) {
  try {
    const url = generatePath(ApiPaymentPage.unpublish, { id });
    const res = await http.post(url);
    return res;
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}
