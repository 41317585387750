import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Space,
  Select,
} from 'antd';
import {
  uniqueId,
  camelCase,
} from 'lodash';
import moment from 'moment';
import RPButton from '@/components/RPButton';
import RPInput from '@/components/RPInput';
import RPSelect from '@/components/RPSelect';
import RPRangePicker from '@/components/RPRangePicker';
import './index.scss';

const { Option } = Select;

function Filter({
  statuses = [],
  closeFilter,
  search,
  searchFieldName = 'ID',
}) {
  const ref = useRef(null);
  const [form] = Form.useForm();
  const [rangePicker, setRangePicker] = useState([]);
  const durations = [
    { value: '7', label: 'Past 7 days' },
    { value: '30', label: 'Past 30 days' },
    { value: '90', label: 'Past 90 days' },
    { value: 'custom', label: 'Custom Range' },
  ];
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        closeFilter && closeFilter();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [closeFilter]);

  const handleClearForm = () => {
    form.resetFields();
    setRangePicker([]);
  };

  const onFinish = (values) => {
    values.daterange = rangePicker;
    search && search(values);
    closeFilter();
  };

  const handleChangeDate = dates => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      duration: 'custom'
    });
    let datesMoment = [];
    if (dates?.length === 2) {
      datesMoment = dates.map(date => moment(date.format('YYYY-MM-DD'), 'YYYY-MM-DD'));
    }
    setRangePicker(datesMoment);
  };

  const onChangeDuration = opt => {
    const now = moment();
    const past = moment();
    switch (opt) {
      case '7':
        past.subtract(7, 'days');
        setRangePicker([past, now]);
        break;
      case '30':
        past.subtract(30, 'days');
        setRangePicker([past, now]);
        break;
      case '90':
        past.subtract(90, 'days');
        setRangePicker([past, now]);
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="filter-component"
      ref={ref}
    >
      <Form
        form={form}
        layout="horizontal"
        size="large"
        onFinish={onFinish}
      >
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <Form.Item
              name={camelCase(searchFieldName)}
            >
              <RPInput
                label={searchFieldName}
                placeholder={searchFieldName}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="status"
            >
              <RPSelect
                placeholder="Status"
                label="Status"
              >
                {
                  statuses.map((item) => (
                    <Option
                      key={uniqueId('status-options-')}
                      value={item.value}
                    >
                      {item.label}
                    </Option>
                  ))
                }
              </RPSelect>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={10}>
            <Form.Item
              name="duration"
            >
              <RPSelect
                label="Duration"
                placeholder="Duration"
                onChange={onChangeDuration}
              >
                {
                  durations.map((item) => (
                    <Option
                      key={uniqueId('duration-options-')}
                      value={item.value}
                    >
                      {item.label}
                    </Option>
                  ))
                }
              </RPSelect>
            </Form.Item>
          </Col>

          <Col span={14}>
            <Form.Item
              name="daterange"
            >
              <RPRangePicker
                defaultRange={rangePicker}
                label="Date"
                placeholder={['Date', 'Date']}
                handleChange={handleChangeDate}
                disabledDate={(current) => {
                  const customDate = moment().add(1, 'days').format('YYYY-MM-DD');
                  return current && current >= moment(customDate, 'YYYY-MM-DD');
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end">
          <Space size={[28]}>
            <Col>
              <Form.Item>
                <RPButton
                  onClick={handleClearForm}
                >
                  Clear
                </RPButton>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <RPButton
                  danger
                  htmlType='submit'
                >
                  Search
                </RPButton>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
    </div>
  );
}

Filter.propTypes = {
  statuses: PropTypes.array,
  closeFilter: PropTypes.func,
  search: PropTypes.func,
  searchFieldName: PropTypes.string,
};

export default Filter;
