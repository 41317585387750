import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  DatePicker,
} from 'antd';
import {
  uniqueId
} from 'lodash';
import './index.scss';

const { RangePicker } = DatePicker;

function RPRangePicker({
  label,
  defaultRange,
  required,
  handleChange,
  ...props
}) {
  const [datePickerKlass, setDatePickerKlass] = useState(defaultRange ? '--has-value' : '');
  const rpDatePickerIdName = uniqueId('rp-date-picker-');
  const [dateValueSelected, setDateValueSelected] = useState(defaultRange);

  useEffect(() => {
    setDateValueSelected(defaultRange);
  }, [defaultRange]);

  const handleOnChange = (dates) => {
    if (dates) {
      setDatePickerKlass('--has-value');
      setDateValueSelected(dates);
      handleChange(dates);
    } else {
      setDatePickerKlass('');
      setDateValueSelected();
      handleChange();
    }
  };

  return (
    <div
      className={`rp-date-picker-container ${datePickerKlass}`}
      id={rpDatePickerIdName}
    >
      <RangePicker
        {...props}
        value={dateValueSelected}
        getPopupContainer={() => document.getElementById(`${rpDatePickerIdName}`)}
        onChange={handleOnChange}
      />
      {!!label && (
        <label htmlFor='#' className="custom-input__label">
          {label}
          {!!required && (<span className="custom-input__required">*</span>)}
        </label>
      )
      }
    </div>
  );
}

RPRangePicker.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.arrayOf(
    PropTypes.instanceOf(moment)
  ),
  defaultRange: PropTypes.arrayOf(
    PropTypes.instanceOf(moment)
  ),
};

export default RPRangePicker;
