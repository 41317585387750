import { availableAdditionalInputFields } from '@/components/PaymentPages/Step/AvailableAdditionalFields';
import { RDCP_MODES } from '@/constants';
import moment from 'moment';
import { getKeyByValue } from './parse';

const PaymentItemType = {
  'fixed-amount': 1,
  'customers-decide-amount': 2
};

const CustomerDetailFieldType = {
  'email': 1,
  'single-line-text': 2,
  'number': 3,
  'phone-number': 4,
  'text-area': 5,
  'alphabets': 6,
  'alphanumeric': 7,
};

const parseItem = (field) => {
  let amount = field.amount ? Number((parseFloat(field.amount) * 100).toFixed()) : null;
  if (PaymentItemType[field.amountType] === 2) {
    amount = null;
  }
  const item = {
    amount,
    label: field.label,
    type: PaymentItemType[field.amountType],
    optional: field.deletable
  };
  if (field.id) {
    item.id = field.id;
  }
  return item;
};

const parseField = (field) => {
  const type = CustomerDetailFieldType[field.type];
  const item = {
    label: field.label,
    type,
    default: !field.deletable,
  };
  if (field.id) {
    item.id = field.id;
  }
  return item;
};

const renderItems = (fields, typeOfInformation, showCustomerInfoOnReceipt) => {
  const items = [];
  const customerDetail = [];
  fields.forEach((field, index) => {
    if (field.amountType) {
      items.push({
        ...parseItem(field),
        position: index,
      });
    } else {
      customerDetail.push({
        ...parseField(field),
        position: index,
        showOnReceipt: showCustomerInfoOnReceipt && index === typeOfInformation
      });
    }
  });
  return { items, customerDetail };
};

export const parseParamsPaymentPage = (data, currentMode) => {
  const { pageDetails, paymentDetails, paymentReceiptsAndPageSettings } = data;
  let obj = {};
  const { typeOfInformation } = paymentReceiptsAndPageSettings;
  if (paymentDetails.fields?.length > 0) {
    obj = {
      ...renderItems(
        paymentDetails.fields,
        typeOfInformation,
        paymentReceiptsAndPageSettings.showCustomerInformationOnReceipt
      )
    };
  }
  const expireDate = !paymentReceiptsAndPageSettings.noExpiry ?
    paymentReceiptsAndPageSettings.expiryDate?.format('YYYY-MM-DD HH:mm:ss')
    : '';
  const params = {
    title: pageDetails.title,
    description: pageDetails.description,
    supportEmail: pageDetails.supportEmail,
    termAndConditions: pageDetails.termAndConditions,
    supportContactNumber: pageDetails.supportContactNumber,
    items: obj.items || [],
    customerDetail: obj.customerDetail || [],
    automaticSendReceipt: paymentReceiptsAndPageSettings.sendReceiptsAutomatically,
    showCustomerInfoOnReceipt: paymentReceiptsAndPageSettings.showCustomerInformationOnReceipt,
    expireDate,
  };

  if (currentMode === RDCP_MODES.LIVE_MODE && paymentReceiptsAndPageSettings.enableCustomUrl) {
    params.customUrl = paymentReceiptsAndPageSettings.customUrl;
  }

  return params;
};


// #### parse data edit

const parsePageDetails = data => ({
  title: data.title,
  description: data.description,
  supportEmail: data.supportEmail,
  supportContactNumber: data.supportContactNumber,
  termAndConditions: data.termAndConditions,
});

const parseItemToFieldForm = item => {
  const amountType = getKeyByValue(PaymentItemType, item.type);
  return {
    position: item.position,
    label: item.label,
    dataType: 'string',
    amountType,
    amount: item.amount / 100,
    deletable: item.optional,
    editing: false,
    id: item.id,
  };
};

const parseCustomToFieldForm = item => {
  const type = getKeyByValue(CustomerDetailFieldType, item.type);
  const obj = availableAdditionalInputFields.find(o => o.key === type);
  return {
    position: item.position,
    label: item.label,
    deletable: !item.default,
    type,
    dataType: obj.dataType,
    editing: false,
    id: item.id,
    editable: !item.default,
  };
};

const parsePaymentDetails = data => {
  const items = data.items?.map(parseItemToFieldForm) || [];
  const customFields = data.customerDetail?.map(parseCustomToFieldForm) || [];
  const fields = [...customFields, ...items];
  fields.sort((a, b) => parseFloat(a.position) - parseFloat(b.position));
  return {
    fields: fields.map((i, index) => ({ ...i, key: index })),
  };
};

const getTypeOfInformation = (data) => {
  const obj = data.customerDetail.find(field => field.showOnReceipt);
  return obj?.position;
};


const parsePaymentReceiptsAndPageSettings = data => {
  const params = {
    sendReceiptsAutomatically: data.automaticSendReceipt,
    showCustomerInformationOnReceipt: data.showCustomerInfoOnReceipt,
    enableCustomUrl: !!data.customUrl,
    customUrl: data.customUrl,
    expiryDate: data.expireDate ? moment(new Date(data.expireDate)) : null,
    noExpiry: !data.expireDate,
  };

  if (data.showCustomerInfoOnReceipt) {
    params.typeOfInformation = getTypeOfInformation(data);
  };
  return params;
};

export const parseDataForm = data => {
  const pageDetails = parsePageDetails(data);
  const paymentDetails = parsePaymentDetails(data);
  const paymentReceiptsAndPageSettings = parsePaymentReceiptsAndPageSettings(data);
  return {
    pageDetails,
    paymentDetails,
    paymentReceiptsAndPageSettings
  };
};

export const optsStatusPaymentPage = [
  { value: 1, label: 'Active' },
  { value: 2, label: 'Inactive' },
];
