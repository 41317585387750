import React from 'react';
import http from '@/http';
import { ApiAuth, ApiUser } from '@/constants/api';
import { setAuthUser } from '@/utils/localStorageUtil';
import {
  parse,
  messageNotifyUtil,
  localStorageUtil,
} from '@/utils';
import { errorMessages, ROUTES } from '@/constants';

export async function login({ email, password }) {
  try {
    const res = await http.post(ApiAuth.signIn, {
      email,
      password,
    });
    if (res.status === 200) {
      const { data } = parse.camelize(res);
      const { resetPasswordToken } = data;
      if (resetPasswordToken) {
        const emailEncode = encodeURIComponent(email);
        const url = `${ROUTES.UPDATE_PASSWORD}?email=${emailEncode}&reset_code=${resetPasswordToken}`;
        messageNotifyUtil.setWarningMessage({
          title: 'Unauthorize!',
          description: 'Your password was expired. Please update your password before sign in!',
          redirectUrl: url
        });
        return {
          success: false,
          data: {}
        };
      }

      setAuthUser(data);
      return {
        success: true,
        data: parse.camelize(data)
      };
    }
    return {
      success: false,
    };
  } catch (error) {
    if (error.response?.status === 404 && error.response?.data?.code !== 'ACCOUNT_INACTIVE') {
      messageNotifyUtil.setErrorMessage({
        title: 'Unauthorize!',
        description: errorMessages.noAuth,
      });
    }

    if (error.response?.status === 400 && error.response?.data?.code === 'ACCOUNT_TEMPORARY_LOCKED') {
      messageNotifyUtil.setErrorMessage({
        title: 'Unauthorize!',
        description: error.response.data.message,
      });
    }

    return {
      success: false,
      data: error.response.data
    };
  }
}

export async function signup({ email, password }) {
  try {
    const {
      status
    } = await http.post(ApiAuth.signUp, {
      email,
      password,
    });
    return { status };
  } catch (error) {
    messageNotifyUtil.setErrorMessage({
      title: 'ALREADY_EXISTS',
      description: (
        <div>
          Merchant email existed, click here to <a href={ROUTES.LOGIN_PATH}>login</a>
        </div>
      ),
    });
    return error.response;
  }
}

export async function resendEmailConfirm({ email }) {
  try {
    const {
      status
    } = await http.post(ApiAuth.resendConfirm, {
      email,
    });
    messageNotifyUtil.setSuccessMessage({
      title: 'Sign In',
      description: 'Resend email successfully',
    });

    return { status };
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: 'Something went wrong',
      });
    }
    return error.response;
  }
}

export async function verifyEmail(params) {
  try {
    const res = await http.post(ApiAuth.verify, parse.snakelize(params));
    return res;
  } catch (error) {
    return error.response;
  }
}

export async function resetPassword({ email }) {
  try {
    const payload = { email };
    const res = await http.post(ApiAuth.resetPassword, parse.snakelize(payload));

    return parse.camelize(res);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function updatePassword({ token, email, password, confirmPassword }) {
  try {
    const payload = { token, email, password, confirmPassword };
    const res = await http.post(ApiAuth.updatePassword, parse.snakelize(payload));
    return parse.camelize(res);
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return error.response;
  }
}

export async function verifyTokenUpdatePassword(params) {
  try {
    const res = await http.post(ApiAuth.checkTokenUpdatePassword, parse.snakelize(params));
    return res;
  } catch (error) {
    return error.response;
  }
}


export async function oauthLogin(params) {
  try {
    const res = await http.post(ApiAuth.oauthLogin, parse.snakelize(params));
    if (res.status === 200) {
      const { data } = parse.camelize(res);
      setAuthUser(data);
    }
    return res;
  } catch (error) {
    if (error.response.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: 'Unauthorize!',
        description: errorMessages.noAuth,
      });
    }
    return error.response;
  }
}

export async function getProfile() {
  try {
    const res = await http.get(ApiUser.profile);
    let responseData = {};
    if (res.status === 200) {
      const { data } = parse.camelize(res);
      const user = localStorageUtil.getAuthUser();
      localStorageUtil.setAuthUser({ ...user, ...data });
      responseData = {
        success: true,
        data,
      };
    } else {
      responseData = {
        success: false,
        data: null,
      };
    }

    return responseData;
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
}
