import React, { useEffect, useState } from 'react';
import { message, Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import { updatePassword, verifyTokenUpdatePassword } from '@/services/authService';
import UpdatePassword from '@/components/Auth/UpdatePassword';

function UpdatePasswordPage() {
  const [loadingPage, setLoadingPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const { search } = useLocation();
  const values = new URLSearchParams(search);
  const token = values.get('reset_code');
  const email = values.get('email');
  const handle = async (data) => {
    setLoading(true);
    if (!token || !email) {
      message.error('Missing token or email');
    }
    const payload = {
      token,
      email,
      password: data.password,
      confirmPassword: data.confirmPassword,
    };
    const res = await updatePassword(payload);
    if (res.status === 200) {
      setUpdateSuccess(true);
      setError(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoadingPage(true);
    const checkPassword = async () => {
      const res = await verifyTokenUpdatePassword({ token });
      if (res.status !== 200) {
        setError(true);
      }
      setLoadingPage(false);
    };
    checkPassword();
  }, [token]);

  return (
    <>
      <Spin className='loading-spiner' spinning={loadingPage} />
      {!loadingPage &&
        <UpdatePassword
          loading={loading}
          error={error}
          handle={handle}
          success={updateSuccess}
        />
      }
    </>
  );
}

export default UpdatePasswordPage;
