import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  errorUtil,
  localStorageUtil,
} from '@/utils';
import { AuthService } from '@/services';

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }, thunkAPI) => {
    try {
      const { success, data } = await AuthService.login({ email, password });
      if (success) {
        localStorageUtil.setAuthUser({ ...data });
      }

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(errorUtil.errorToObject(error));
    }
  },
);
