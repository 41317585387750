import React from 'react';

import FormContainer from '@/containers/PaymentPage/FormContainer';

import './new.scss';

function New() {
  return(
    <FormContainer />
  );
}

export default New;
