import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import RPButton from '@/components/RPButton';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@/constants';

function ModalDelete({ isModalVisible, setIsModalVisible }) {
  const history = useHistory();
  const handleOk = () => {
    setIsModalVisible(false);
    history.replace(`${ROUTES.APP.SETTINGS.WEBHOOKS.INDEX}`);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Delete Webhook Alert"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="rp-modal webhooks-detail__delete-modal"
    >
      <p>You have chosen to permanently delete the selected webhook URL. Are you sure you want to proceed?</p>
      <div className='webhooks-detail__delete-modal__group-btn'>
        <RPButton
          grey
          onClick={() => handleCancel()}
        >
          Cancel
        </RPButton>
        <RPButton
          gradient
          className='webhooks-detail__delete-modal__delete-btn'
          onClick={() => handleOk()}
        >
          Yes, delete
        </RPButton>
      </div>
    </Modal>
  );
}

ModalDelete.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func
};

export default ModalDelete;
