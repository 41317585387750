import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';

import uploadIcon from '@/assets/images/fileupload/upload-icon.svg';

function UploadProcessing({
  percent,
}) {
  return (
    <div className="rp-upload-file-uploading">
      <div className="upload-list-item-info">
        <div className="left-hand">
          <p className="upload-status">{`${percent}% Uploaded`}</p>
          <Progress
            strokeColor={{
              '0%': '#0849EF',
              '100%': '#B120B7',
            }}
            percent={percent}
            showInfo={false}
          />
          <div className="under-status-title">
            <span>Your file is being uploaded</span>
          </div>
        </div>

        <div className="right-hand">
          <img
            src={uploadIcon}
            alt="upload icon"
            width={31}
            height={39}
          />
        </div>
      </div>
    </div>
  );
};

UploadProcessing.propTypes = {
  percent: PropTypes.number,
};

export default UploadProcessing;
