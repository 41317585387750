/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col
} from 'antd';
import RPSwitch from '../RPSwitch';
import Transactions from '../Transactions/table';

function Detail({
  data,
  onSwitch,
  active,
}) {
  const status = data.status?.toLowerCase();
  const renderItem = (item, key) => (
    <Row key={key} gutter={[12, 12]} className='payment-pages__group-item'>
      <Col span={7}>
        <p>{item.name}</p>
      </Col>
      <Col span={5}>
        <p>{item.totalSaleAmount === null ? '--' : item.totalSaleAmount}</p>
      </Col>
      <Col span={5}>
        <p>{item.amount || '--'}</p>
      </Col>
      <Col span={5}>
        <p>{item.unitSold === null ? '--' : item.unitSold}</p>
      </Col>
    </Row>
  );
  return (
    <div
      className="payment-page"
    >
      <div className="detail__card">
        <Row>
          <Col span={12} className="detail__card__left">
            <Row gutter={[12, 12]}>
              <Col span={12} className="detail__card__label">Status</Col>
              <Col span={12} className={`payment-pages__status --${status}`}>
                <RPSwitch
                  onChange={onSwitch}
                  label={active ? 'Active' : 'Inactive'}
                  checked={active}
                />
              </Col>
              <Col span={12} className="detail__card__label">Payment Button ID</Col>
              <Col span={12}>{data.id}</Col>
            </Row>
          </Col>
          <Col span={12} className="detail__card__right">
            <Row gutter={[12, 12]}>
              <Col span={12} className="detail__card__label">Created By</Col>
              <Col span={12}>
                <div className='detail__card__label__created-by__name'>{data.createdBy?.name}</div>
                <div className='detail__card__label__created-by__email'>{data.createdBy?.email}</div>
              </Col>
              <Col span={12} className="detail__card__label">Date Created</Col>
              <Col span={12}>{data.dateCreated}</Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="detail__card">
        <Row>
          <Col span={24} className="detail__card__left">
            <Row gutter={[12, 12]} className='payment-pages__group-item'>
              <Col span={7}>
                <p className='detail__card__label'>Name</p>
              </Col>
              <Col span={5}>
                <p className='detail__card__label'>Revenue</p>
              </Col>
              <Col span={5}>
                <p className='detail__card__label'>Amount</p>
              </Col>
              <Col span={5}>
                <p className='detail__card__label'>Unit Solds</p>
              </Col>
            </Row>
            {
              data.items?.map((item, key) => renderItem(item, key))
            }
          </Col>
        </Row>
      </div>

      {
        data.id && (
          <Transactions
            refType='PaymentButton'
            refId={data.id}
          />
        )
      }
    </div>
  );
}

Detail.propTypes = {
  data: PropTypes.object,
  onSwitch: PropTypes.func,
  active: PropTypes.bool,
};

export default Detail;
