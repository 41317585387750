import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Skeleton,
} from 'antd';
import {
  useHistory
} from 'react-router-dom';

import CardComponent from '@/components/CardComponent';
import RPButton from '@/components/RPButton';
import accountIcon from '@/assets/images/dashboard/account-icon.svg';
import { listStatuses } from '@/constants/kyc';
import { DashboardData } from './data';

// type: notActivated || notSubmitKyc || kycSubmitted || activated
function Account({
  type,
  loading,
}) {
  const history = useHistory();
  const data = DashboardData[type] || {};
  const {
    cardTitle,
    process01,
    process02,
    process03,
    buttonInfo,
  } = data;

  const handleOnClickBtn = () => {
    history.replace(buttonInfo?.url);
  };

  if (!listStatuses.includes(type)) {
    return null;
  }

  const renderSkeletonLoading = () => (
    <div className="dashboard__account-card --your-account">
      <Row className="dashboard__account-card__process">
        <Col flex="29%" className="dashboard__account-card__process__item --test-mode" />
        <Col flex="48.82%" className="dashboard__account-card__process__item --live-payments-and-settlements" />
        <Col flex="auto" className="dashboard__account-card__process__item" />
      </Row>
      <Row wrap={false}>
        <Col
          flex="29%"
          className="dashboard__account-card__group"
        >
          <Skeleton active title={false} paragraph={{ rows: 2 }} />
        </Col>
        <Col
          flex="48.82%"
          className="dashboard__account-card__group"
        >
          <Skeleton active title={false} paragraph={{ rows: 2 }} />
        </Col>
        <Col
          flex="auto"
          className="dashboard__account-card__group"
        >
          <Skeleton active title={false} paragraph={{ rows: 2 }} />
        </Col>
      </Row>
      <div className="dashboard__account-card__btn-submit">
        <div className="w-10 mx-auto">
          <Skeleton.Button active block shape="round" />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {['StatusKYCSubmitted', 'StatusActivated'].includes(type) && null}
      {!['StatusKYCSubmitted', 'StatusActivated'].includes(type) &&
        <div className="dashboard__account-container">
          <CardComponent
            loading={loading}
            icon={accountIcon}
            title={cardTitle || ''}
          >
            {loading && renderSkeletonLoading()}
            {!loading &&
              <div className="dashboard__account-card --your-account">
                <Row className="dashboard__account-card__process">
                  <Col flex="29%" className="dashboard__account-card__process__item --test-mode">
                    <p className="mb-0">{process01?.title}</p>
                  </Col>
                  <Col flex="48.82%" className="dashboard__account-card__process__item --live-payments-and-settlements">
                    {process02?.title}
                  </Col>
                  <Col flex="auto" className="dashboard__account-card__process__item">
                    <p className="mb-0">{process03?.title}</p>
                  </Col>
                </Row>
                <Row wrap={false}>
                  <Col
                    flex="29%"
                    className="dashboard__account-card__group"
                    dangerouslySetInnerHTML={{ __html: process01?.description }}
                  />
                  <Col
                    flex="48.82%"
                    className="dashboard__account-card__group"
                    dangerouslySetInnerHTML={{ __html: process02?.description }}
                  />
                  <Col
                    flex="auto"
                    className="dashboard__account-card__group"
                    dangerouslySetInnerHTML={{ __html: process03?.description }}
                  />
                </Row>
                <div className="dashboard__account-card__btn-submit">
                  <RPButton
                    danger
                    onClick={handleOnClickBtn}
                    center
                  >
                    {buttonInfo?.text}
                  </RPButton>
                </div>
              </div>
            }
          </CardComponent>
        </div>
      }
    </>
  );
}

Account.propTypes = {
  loading: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export default Account;
