import React from 'react';
import PropTypes from 'prop-types';

import deleteIconRed from '@/assets/images/fileupload/delete-icon-red.svg';
import uploadFailedIcon from '@/assets/images/fileupload/upload-failed-icon.svg';

function UploadError({
  name,
  fileSize,
  remove,
}) {
  const renderUploadErrorTitle = () => {
    let title = name.toUpperCase();

    if (fileSize) {
      title = `${title} (${fileSize})`;
    }

    return <div className="item-name">{title}</div>;
  };

  return (
    <div className="rp-upload-file-error">
      <div className="upload-list-item-info">
        <div className="left-hand">
          <p className="upload-status">Upload Failed</p>
          <div className="under-status-title">
            {renderUploadErrorTitle()}
            <button
              type='button'
              className="delete"
              onClick={remove}
            >
              <img
                src={deleteIconRed}
                alt="delete icon red"
                width={16}
                height={18}
              />
            </button>
          </div>
        </div>

        <div className="right-hand">
          <img
            src={uploadFailedIcon}
            alt="upload failed icon"
            width={48}
            height={48}
          />
        </div>
      </div>
    </div>
  );
};

UploadError.propTypes = {
  name: PropTypes.string,
  fileSize: PropTypes.string,
  remove: PropTypes.func,
};

export default UploadError;
