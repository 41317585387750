const ModalProfileTypes = {
  displayName: 'display_name',
  updatePhone: 'update_phone',
  changePassword: 'change_password',
  addGSTDetail: 'add_gst_detail',
  supportDetail: 'support_detail',
  settlementDetails: 'settlement_detail',
  twoFactorAuthentication: 'twoFactorAuthentication',
};

export { ModalProfileTypes };
