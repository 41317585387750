import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
} from 'antd';

import './index.scss';

function RPSwitch({
  label,
  subLabel,
  ...props
}) {
  return (
    <div className="rp-switch">
      <Switch {...props} />
      <div className='rp-switch__group-label'>
        {label && <p className='mb-0'>{label}</p>}
        {subLabel && <p className='mb-0 rp-switch__sub-label'>{subLabel}</p>}
      </div>
    </div>
  );
}

RPSwitch.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string
};

export default RPSwitch;
