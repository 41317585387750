import React, { useState } from 'react';
import {
  Form,
  Spin,
  Typography
} from 'antd';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ROUTES } from '@/constants';
import { KYCStatus } from '@/constants/kyc';
import {
  parse,
  useBeforeRender,
  filenameFromUrl,
} from '@/utils';
import PageHeader from '@/components/PageHeader';
import Step from '@/components/KYC/Step';
import ContactInfo from '@/components/KYC/Step/ContactInfo';
import BusinessOver from '@/components/KYC/Step/BusinessOver';
import BusinessDetail from '@/components/KYC/Step/BusinessDetail';
import Complete from '@/components/KYC/Step/Complete';
import {
  updateKyc,
  submitKyc,
  getMerchantKyc,
} from '@/services/KYCService';
import useExitPrompt from '@/hooks/useExitPrompt';
import {
  getAuthUser,
  setAuthUser
} from '@/utils/localStorageUtil';

const { Title } = Typography;

const pageHeaderData = {
  title: 'KYC Form',
  description: 'Complete and submit the form to accept payments.',
  headerType: 'white',
};

const initFormData = {
  contactInfo: {
    contactName: '',
    contactNumber: '',
    contactEmail: '',
  },
  businessOverview: {
    type: undefined,
    category: undefined,
    description: '',
    acceptPaymentAppUrl: '',
    acceptPaymentWebsiteUrl: '',
  },
  businessDetail: {
    businessPan: '',
    panHoldername: '',
    panAuthorisedSignatory: '',
    panOwnerName: '',
    cin: '',
    billingLabel: '',
    billingAddress: '',
    billingPinCode: '',
    billingCity: '',
    billingState: undefined,
    isOperationSameBilling: true,
    companyRegistrationCertificate: {},
    cancelledCheque: {},
    operationAddress: '',
    operationPinCode: '',
    operationCity: '',
    operationState: undefined,
  }
};

function KYCPage({ readOnly }) {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const [formInstance] = Form.useForm();
  const [step, setStep] = useState(1);
  const [saveAndExitLoading, setSaveAndExitLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formData, setFormData] = useState(initFormData);
  const [loadingPage, setLoadingPage] = useState(true);
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);
  const valuesSearchParams = new URLSearchParams(search);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  const initData = async () => {
    const currentStep = valuesSearchParams.get('current_step');
    const { success, data } = await getMerchantKyc();
    if (success) {
      if (KYCStatus[data.status] === 'StatusKYCSubmitted') {
        setAlreadySubmitted(true);
        setLoadingPage(false);
        return;
      }

      if (KYCStatus[data.status] === 'StatusActivated' && !readOnly) {
        history.push(ROUTES.APP.DASHBOARD_PATH);
        return;
      }

      const merchantKyc = parse.camelize(data);
      let newFormFieldsValues = {
        contactInfo: {
          contactName: merchantKyc.contactName,
          contactNumber: merchantKyc.contactNumber,
          contactEmail: merchantKyc.contactEmail,
        },
        businessOverview: {
          type: merchantKyc.businessTypeId || undefined,
          category: merchantKyc.businessCategoryId || undefined,
          description: merchantKyc.businessDescription,
          acceptPaymentAppUrl: merchantKyc.acceptPaymentAppUrl,
          acceptPaymentWebsiteUrl: merchantKyc.acceptPaymentWebsiteUrl,
        },
        businessDetail: {
          businessPan: merchantKyc.businessPan,
          panHoldername: merchantKyc.businessName,
          panAuthorisedSignatory: merchantKyc.pan,
          panOwnerName: merchantKyc.panName,
          cin: merchantKyc.cin,
          billingLabel: merchantKyc.billingLabel,
          billingAddress: merchantKyc.address,
          billingPinCode: merchantKyc.pinCode,
          billingCity: merchantKyc.city,
          billingState: merchantKyc.stateCode === '' ? undefined : merchantKyc.stateCode,
          isOperationSameBilling: merchantKyc.isOperationSameAbove,
          operationAddress: merchantKyc.operationAddress,
          operationPinCode: merchantKyc.operationPinCode,
          operationCity: merchantKyc.operationCity,
          operationState: merchantKyc.operationStateCode === '' ? undefined : merchantKyc.operationStateCode,
          companyRegistrationCertificate: {},
          cancelledCheque: {},
        }
      };

      if (merchantKyc.isOperationSameAbove) {
        newFormFieldsValues = {
          ...newFormFieldsValues,
          businessDetail: {
            ...newFormFieldsValues.businessDetail,
            operationAddress: merchantKyc.address,
            operationPinCode: merchantKyc.pinCode,
            operationCity: merchantKyc.city,
            operationState: merchantKyc.stateCode,
          }
        };
      }

      if (merchantKyc.companyRegistrationCertificateUrl) {
        newFormFieldsValues = {
          ...newFormFieldsValues,
          businessDetail: {
            ...newFormFieldsValues.businessDetail,
            companyRegistrationCertificate: {
              id: null,
              url: merchantKyc.companyRegistrationCertificateUrl,
              status: 'done',
              name: filenameFromUrl(merchantKyc.companyRegistrationCertificateUrl),
            },
          }
        };
      }

      if (merchantKyc.cancelledChequeUrl) {
        newFormFieldsValues = {
          ...newFormFieldsValues,
          businessDetail: {
            ...newFormFieldsValues.businessDetail,
            cancelledCheque: {
              id: null,
              url: merchantKyc.cancelledChequeUrl,
              status: 'done',
              name: filenameFromUrl(merchantKyc.cancelledChequeUrl),
            }
          }
        };
      }

      setFormData(newFormFieldsValues);
      formInstance.resetFields();
      formInstance.setFieldsValue(newFormFieldsValues);
      if (currentStep) {
        setStep(parseInt(currentStep, 10));
      } else {
        setStep(merchantKyc.step);
      }
    }
    setLoadingPage(false);
  };

  useBeforeRender(() => initData(), [formInstance]);

  const setContactForm = (values) => {
    // Show Prompt when change value
    setShowExitPrompt(true);
    return (
      setFormData({
        ...formData,
        contactInfo: {
          ...formData.contactInfo,
          ...values,
        }
      })
    );
  };

  const setBusinessOverview = (values) => {
    // Show Prompt when change value
    setShowExitPrompt(true);
    return (
      setFormData({
        ...formData,
        businessOverview: {
          ...values,
        }
      })
    );
  };

  const setbusinessDetail = (values) => {
    // Show Prompt when change value
    setShowExitPrompt(true);
    return (
      setFormData({
        ...formData,
        businessDetail: {
          ...values,
        }
      })
    );
  };

  const clickNext = () => {
    if (step > 4) return;
    valuesSearchParams.set('current_step', step + 1);
    window.history.replaceState({}, '', `${pathname}?${valuesSearchParams}`);
    setStep(step + 1);
  };

  const clickBack = () => {
    if (step <= 1) return;
    valuesSearchParams.set('current_step', step - 1);
    window.history.replaceState({}, '', `${pathname}?${valuesSearchParams}`);
    setStep(step - 1);
  };

  if (step === 4) {
    return (
      <Complete />
    );
  }

  const onFinish = async () => {
    setSubmitLoading(true);
    setShowExitPrompt(false);
    let payload = {
      contactEmail: formData.contactInfo.contactEmail,
      contactName: formData.contactInfo.contactName,
      contactNumber: formData.contactInfo.contactNumber,
      businessTypeId: formData.businessOverview.type,
      businessCategoryId: formData.businessOverview.category,
      businessDescription: formData.businessOverview.description,
      acceptPaymentAppUrl: formData.businessOverview.acceptPaymentAppUrl,
      acceptPaymentWebsiteUrl: formData.businessOverview.acceptPaymentWebsiteUrl,
      businessPan: formData.businessDetail.businessPan,
      businessName: formData.businessDetail.panHoldername,
      pan: formData.businessDetail.panAuthorisedSignatory,
      panName: formData.businessDetail.panOwnerName,
      cin: formData.businessDetail.cin,
      billingLabel: formData.businessDetail.billingLabel,
      address: formData.businessDetail.billingAddress,
      pinCode: formData.businessDetail.billingPinCode,
      city: formData.businessDetail.billingCity,
      stateCode: formData.businessDetail.billingState,
      isOperationSameAbove: formData.businessDetail.isOperationSameBilling,
      operationAddress: formData.businessDetail.operationAddress,
      operationPinCode: formData.businessDetail.operationPinCode,
      operationCity: formData.businessDetail.operationCity,
      operationStateCode: formData.businessDetail.operationState,
      companyRegistrationCertificateId: formData.businessDetail.companyRegistrationCertificate.uid,
      cancelledChequeId: formData.businessDetail.cancelledCheque.uid,
    };

    if (formData.businessDetail.isOperationSameBilling) {
      payload = {
        ...payload,
        operationAddress: formData.businessDetail.billingAddress,
        operationPinCode: formData.businessDetail.billingPinCode,
        operationCity: formData.businessDetail.billingCity,
        operationStateCode: formData.businessDetail.billingState,
      };
    }

    const response = await submitKyc({ payload });

    if (response.status === 200) {
      // Update merchant business name after save kyc
      const user = getAuthUser();
      setAuthUser({
        ...user,
        merchant: {
          ...user.merchant,
          name: payload.businessName,
          contactName: payload.contactName
        }
      });
      setStep(step + 1);
    } else {
      setSubmitLoading(false);
    }
  };

  const handleSaveAndExit = async () => {
    setShowExitPrompt(false);
    setSaveAndExitLoading(true);

    let payload = {
      contactEmail: formData.contactInfo.contactEmail,
      contactName: formData.contactInfo.contactName,
      contactNumber: formData.contactInfo.contactNumber,
      businessTypeId: formData.businessOverview.type,
      businessCategoryId: formData.businessOverview.category,
      businessDescription: formData.businessOverview.description,
      acceptPaymentAppUrl: formData.businessOverview.acceptPaymentAppUrl,
      acceptPaymentWebsiteUrl: formData.businessOverview.acceptPaymentWebsiteUrl,
      businessPan: formData.businessDetail.businessPan,
      businessName: formData.businessDetail.panHoldername,
      pan: formData.businessDetail.panAuthorisedSignatory,
      panName: formData.businessDetail.panOwnerName,
      cin: formData.businessDetail.cin,
      billingLabel: formData.businessDetail.billingLabel,
      address: formData.businessDetail.billingAddress,
      pinCode: formData.businessDetail.billingPinCode,
      city: formData.businessDetail.billingCity,
      stateCode: formData.businessDetail.billingState,
      isOperationSameAbove: formData.businessDetail.isOperationSameBilling,
      operationAddress: formData.businessDetail.operationAddress,
      operationPinCode: formData.businessDetail.operationPinCode,
      operationCity: formData.businessDetail.operationCity,
      operationStateCode: formData.businessDetail.operationState,
    };

    if (formData.businessDetail.isOperationSameBilling) {
      payload = {
        ...payload,
        operationAddress: formData.businessDetail.billingAddress,
        operationPinCode: formData.businessDetail.billingPinCode,
        operationCity: formData.businessDetail.billingCity,
        operationStateCode: formData.businessDetail.billingState,
      };
    }

    if (formData.businessDetail.companyRegistrationCertificate.uid) {
      payload = {
        ...payload,
        companyRegistrationCertificateId: formData.businessDetail.companyRegistrationCertificate.uid,
      };
    }

    if (formData.businessDetail.cancelledCheque.uid) {
      payload = {
        ...payload,
        cancelledChequeId: formData.businessDetail.cancelledCheque.uid,
      };
    }

    const response = await updateKyc({ payload });

    if (response.status === 200) {
      // Update merchant business name after save kyc
      const user = getAuthUser();
      setAuthUser({
        ...user,
        merchant: {
          ...user.merchant,
          name: payload.businessName,
          contactName: payload.contactName
        }
      });
      setTimeout(() => {
        window.location.href = ROUTES.APP.DASHBOARD_PATH;
      }, 1000);
    }
    setSaveAndExitLoading(false);
  };

  return (
    <>
      <Prompt
        when={showExitPrompt}
        message="Do you want to leave this page and discard your changes?"
      />
      <Spin
        className='loading-spiner'
        spinning={loadingPage}
      />
      {!loadingPage &&
        <div className="dashboard-kyc-container">
          <PageHeader {...pageHeaderData} />
          <div
            className="dashboard-kyc"
          >
            {alreadySubmitted &&
              <Title level={5}>You have already submitted KYC</Title>
            }
            {!alreadySubmitted &&
              <>
                <Step step={step} />
                <Form
                  noValidate
                  form={formInstance}
                  name="basic"
                  preserve={false}
                  scrollToFirstError
                  autoComplete="off"
                  onFinish={onFinish}
                  initialValues={formData}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  {step === 1 &&
                    <ContactInfo
                      contactInfoData={formData.contactInfo}
                      setContactForm={setContactForm}
                      onSaveAndExit={handleSaveAndExit}
                      clickNext={clickNext}
                      saveAndExitLoading={saveAndExitLoading}
                      readOnly={readOnly}
                    />
                  }
                  {step === 2 &&
                    <BusinessOver
                      businessOverview={formData.businessOverview}
                      setBusinessOverview={setBusinessOverview}
                      clickBack={clickBack}
                      clickNext={clickNext}
                      onSaveAndExit={handleSaveAndExit}
                      saveAndExitLoading={saveAndExitLoading}
                      readOnly={readOnly}
                    />
                  }
                  {step === 3 &&
                    <BusinessDetail
                      businessDetailData={formData.businessDetail}
                      setbusinessDetail={setbusinessDetail}
                      clickBack={clickBack}
                      onSaveAndExit={handleSaveAndExit}
                      submitLoading={submitLoading}
                      saveAndExitLoading={saveAndExitLoading}
                      readOnly={readOnly}
                    />
                  }
                </Form>
              </>
            }
          </div>
        </div>
      }
    </>
  );
}

KYCPage.propTypes = {
  readOnly: PropTypes.bool,
};

export default KYCPage;
