import http from '@/http';
import { ApiTransactions } from '@/constants/api';
import {
  parse,
  messageNotifyUtil,
} from '@/utils';

export async function getCurrentPayment() {
  try {
    const { data } = await http.get(ApiTransactions.currentPayment);

    return {
      success: true,
      data: parse.camelize(data)
    };
  } catch (error) {
    if (error?.response?.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return {
      success: false,
      data: {}
    };
  }
}

export async function getPaymentInsight({ createdFrom, createdTo, groupBy, paymentMethod }) {
  try {
    const params = parse.snakelize({ createdFrom, createdTo, groupBy, paymentMethod });
    const { data } = await http.get(ApiTransactions.paymentInsight, { params });

    return {
      success: true,
      ...parse.camelize(data)
    };
  } catch (error) {
    if (error?.response?.status === 400) {
      messageNotifyUtil.setErrorMessage({
        title: error.response.data.code,
        description: error.response.data.message,
      });
    }
    return {
      success: false
    };
  }
}
