/* eslint-disable no-continue */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'antd';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { isValidAmount, validateEmail, validatePhoneNumber } from '@/utils/validate';

import RPInput from '@/components/RPInput';
import RPButton from '@/components/RPButton';
import RPCheckbox from '@/components/RPCheckbox';
import RPTextArea from '@/components/RPTextArea';
import RPPhoneNumber from '@/components/RPPhoneNumber';
import RPDatePicker from '@/components/RPDatePicker';
import RPInputAmount from '@/components/RPInputAmount';
import checkedIcon from '@/assets/images/kyc/checked-icon.svg';
import uncheckedIcon from '@/assets/images/kyc/unchecked-icon.svg';
import paymentLinkIcon from '@/assets/images/payment-links/payment-link-icon.svg';
import { formatter } from '@/utils';
import { AMOUNT_LIMIT, DEFAULT_CURRENCY } from '@/constants';

import './form.scss';

function FormPaymentLink({
  onSubmit,
  clickBack,
  paymentDetails,
  customerDetails,
  setPaymentDetails,
  setCustomerDetails,
  isEdit,
  setShowExitPrompt
}) {
  const [formInstance] = Form.useForm();

  const [currentAmount, setCurrentAmount] = useState();
  const [hasErrorAmount, setHasErrorAmount] = useState(false);
  const [initialValues] = useState(() => paymentDetails);

  const [formCheck, setFormCheck] = useState([
    { label: 'Payment Details', value: false, id: 'paymentDetails' },
  ]);

  const [disableLinkExpiry, setDisableLinkExpiry] = useState(() => !!paymentDetails.noExpiryDate);

  const onFinish = (values) => {
    onSubmit(values);
  };

  const changeExpiry = (e) => {
    setShowExitPrompt(true);
    setPaymentDetails({
      ...paymentDetails,
      linkExpiry: e
    });
  };

  const disabledDate = (current) =>
    // Can not select days before today and today
    current && current < moment().startOf('day').add(1, 'day');

  const onChange = (e) => {
    setShowExitPrompt(true);
    let { value } = e.target;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
      setDisableLinkExpiry(value);
    }

    if (Object.keys(paymentDetails).includes(e.target.name)) {
      if (e.target.name === 'amount') {
        return;
      }
      setPaymentDetails({
        ...paymentDetails,
        [e.target.name]: value
      });
    } else {
      setCustomerDetails({
        ...customerDetails,
        [e.target.name]: value
      });
    }
  };

  const isFilledPaymentDetails = (object) =>
    object.amount !== null && object.amount > 0 && isValidAmount(object.amount);

  useEffect(() => {
    const formChecked = [...formCheck];
    const objIndex = formChecked.findIndex((obj => obj.id === 'paymentDetails'));
    if (isFilledPaymentDetails(paymentDetails)) {
      formChecked[objIndex].value = true;
    } else {
      formChecked[objIndex].value = false;
    }
    setFormCheck(formChecked);
    formInstance.setFieldsValue({
      ...paymentDetails,
      ...customerDetails
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDetails]);

  // Reset value of expire date when check/uncheck `No Expiry Date`
  useEffect(() => {
    formInstance.resetFields(['linkExpiry']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableLinkExpiry]);

  const isCompleted = () => formCheck.filter(f => !f.value).length > 0;

  const handleOnChangePhoneNumber = (phone) => {
    setShowExitPrompt(true);
    setCustomerDetails({
      ...customerDetails,
      phoneNo: phone
    });
  };

  const handleOnChangeAmount = value => {
    setShowExitPrompt(true);
    setCurrentAmount(value);

    if (isValidAmount(value)) {
      setHasErrorAmount(false);
    } else {
      setHasErrorAmount(true);
    }
    setPaymentDetails({
      ...paymentDetails,
      amount: value
    });
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      onFinish={onFinish}
      onChange={onChange}
      noValidate
      initialValues={initialValues}
      form={formInstance}
    >
      <div className="rp-payment-links__form__card">
        <Row>
          <Col flex="50%" className="rp-payment-links__form__card__left">
            <img
              src={paymentLinkIcon}
              alt="icon"
              width="32px"
              height="32px"
            />
            <h2 className="rp-payment-links__form__card__left__title">Payment Link Details</h2>
            <p
              className="rp-payment-links__form__card__left__desc"
            >
              Provide the relevant information in this form to continue creating your new payment link.
            </p>
            <p className="rp-payment-links__form__card__left__desc">Form Checklist</p>
            {
              formCheck.map((data) => (
                <div
                  className="rp-payment-links__form__card__left__desc --group"
                  key={uniqueId('form-check-')}
                >
                  <img
                    src={data.value ? checkedIcon : uncheckedIcon}
                    alt="icon"
                    width="24px"
                    height="24px"
                  />
                  {data.label}
                </div>
              ))
            }
          </Col>
          <Col flex="50%" className="rp-payment-links__form__card__right">
            <p className="rp-payment-links__form__card__right__title">Payment Details</p>
            <Form.Item
              name="amount"
              rules={[
                { required: true, message: 'Please input your amount' },
                () => ({
                  validator(_, value) {
                    if (value !== null && value <= 0) {
                      return Promise.reject(
                        new Error('Amount has greater than 0'),
                      );
                    }

                    if (value && !isValidAmount(value)) {
                      return Promise.reject(
                        new Error(`Amount cannot be greater than ${AMOUNT_LIMIT.MAX}`),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              wrapperCol={{ span: 24 }}
            >
              <RPInputAmount
                name="amount"
                label="Amount"
                placeholder="Amount"
                prefix={formatter.currencySymbol(DEFAULT_CURRENCY)}
                required
                onChange={handleOnChangeAmount}
                value={currentAmount}
                status={hasErrorAmount && currentAmount ? 'error' : ''}
              />
            </Form.Item>
            <Form.Item
              name="paymentFor"
              wrapperCol={{ span: 24 }}
            >
              <RPInput
                name="paymentFor"
                className="rp-payment-links__form__card__right__input --custom"
                label="Payment For"
                placeholder="Payment For"
                maxLength={60}
              />
            </Form.Item>
            <Form.Item
              name="promotionCoupon"
              wrapperCol={{ span: 24 }}
            >
              <RPInput
                name="promotionCoupon"
                className="rp-payment-links__form__card__right__input --custom"
                label="Coupon/Discount Code"
                placeholder="Coupon/Discount Code"
                maxLength={60}
              />
            </Form.Item>
            <Form.Item
              label=""
              name="noExpiryDate"
              valuePropName="checked"
            >
              <RPCheckbox
                name="noExpiryDate"
              >
                No Expiry Date
              </RPCheckbox>
            </Form.Item>
            <Form.Item
              name="linkExpiry"
              className="payment-link__link-expiry"
              wrapperCol={{ span: 24 }}
              rules={[
                { required: !disableLinkExpiry, message: 'Link Expiration is required' },
                () => ({
                  validator(_, value) {
                    if (value && value.isBefore(moment().add(15, 'minutes'))) {
                      return Promise.reject(new Error('Link Expiration must be at least 15 minutes in future.'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <RPDatePicker
                placeholder="Link Expiration"
                label="Link Expiration"
                name="linkExpiry"
                className="w-100"
                disabledDate={disabledDate}
                disabled={disableLinkExpiry}
                onChange={changeExpiry}
                showNow={false}
                showTime={false}
              />
            </Form.Item>
            <p className="rp-payment-links__form__card__right__title">Customer Details</p>
            <Form.Item
              wrapperCol={{ span: 24 }}
              label=""
              name="email"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && !validateEmail(value)) {
                      return Promise.reject(new Error('Please input the valid email'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <RPInput
                name="email"
                className="rp-payment-links__form__card__right__input --custom"
                placeholder="skfnasn@email.com"
                label="Email"
                type="email"
                maxLength={60}
              />
            </Form.Item>
            <Form.Item
              name="phoneNo"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && !validatePhoneNumber(value)) {
                      return Promise.reject(new Error('Phone Number Invalid'));
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              wrapperCol={{ span: 24 }}
            >
              <RPPhoneNumber
                name="phoneNo"
                className="rp-payment-links__form__card__right__input --custom mb-0"
                specialLabel="Phone No"
                placeholder="Phone No."
                inputProps={{
                  name: 'phoneNo',
                  required: true,
                }}
                onChange={handleOnChangePhoneNumber}
              />
            </Form.Item>
            <Form.Item
              name="note"
              wrapperCol={{ span: 24 }}
            >
              <RPTextArea
                placeholder="Note"
                label="Note"
                rows={4}
                name="note"
                maxLength={60}
              />
            </Form.Item>
            <Form.Item
              name="referenceId"
              wrapperCol={{ span: 24 }}
            >
              <RPInput
                name="referenceId"
                className="rp-payment-links__form__card__right__input --custom"
                label="Reference ID"
                placeholder="Reference ID"
                maxLength={60}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="rp-payment-links__form__card__group-btn">
        <RPButton
          grey
          onClick={clickBack}
        >
          Cancel
        </RPButton>
        <RPButton
          gradient
          withArrowIcon
          htmlType="submit"
          disabled={isCompleted()}
        >
          <div className="rp-payment-links__form__card__submit-btn">
            {isEdit ? 'Update Link' : 'Create Link'}
          </div>
        </RPButton>
      </div>
    </Form>
  );
}

FormPaymentLink.propTypes = {
  onSubmit: PropTypes.func,
  clickBack: PropTypes.func,
  paymentDetails: PropTypes.object,
  customerDetails: PropTypes.object,
  setPaymentDetails: PropTypes.func,
  setCustomerDetails: PropTypes.func,
  isEdit: PropTypes.bool,
  setShowExitPrompt: PropTypes.func
};

export default FormPaymentLink;
