import React, { useState } from 'react';
import Confirmation from '@/components/Auth/Confirmation';
import { useLocation } from 'react-router-dom';
import { verifyEmail } from '@/services/authService';
import { useBeforeRender } from '@/utils';

function ConfirmationPage() {
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const confirmEmail = async () => {
    const values = new URLSearchParams(search);
    const token = values.get('confirmation_token');
    if (token && token.length > 0) {
      const res = await verifyEmail({activationCode: token});
      if (res.status === 200) {
        setSuccess(true);
        setLoading(false);
        return;
      }
    }
    setSuccess(false);
    setLoading(false);
  };

  useBeforeRender(() => confirmEmail(), []);
  return (
    <Confirmation loading={loading} success={success}/>
  );
}

export default ConfirmationPage;
