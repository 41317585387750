/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';

import { useBeforeRender } from '@/utils';
import { TransactionService } from '@/services';
import { parseTransactionsDetail } from '@/utils/transactions';
import Detail from '@/components/Transactions/Detail';
import PageHeader from '@/components/PageHeader';
import RPButton from '@/components/RPButton';
import { ROUTES } from '@/constants';


function Transactions({ id }) {
  const [data, setData] = useState({});
  const [isCaptured, setIsCaptured] = useState(false);
  const [loading, setLoading] = useState(true);
  const initData = async () => {
    const res = await TransactionService.getTransactionDetail({ id });
    if (res?.id) {
      setIsCaptured(res.status === 'CAPTURED');
      const trans = parseTransactionsDetail(res);
      setData(trans);
      setLoading(false);
    }
  };

  const sendReceipt = async () => {
    await TransactionService.sendReceiptTransaction({ id });
  };

  const history = useHistory();

  const pageHeaderData = {
    title: `Transaction ID ${id}`,
    leftBtns: (
      <RPButton
        grey
        onClick={() => history.replace(ROUTES.APP.TRANSACTION_PATH)}
      >
        Back
      </RPButton>
    ),
    rightBtns: isCaptured && (
      <RPButton
        grey
        onClick={sendReceipt}
      >
        Send Receipt
      </RPButton>
    )
  };

  useBeforeRender(() => initData(), []);

  return (
    <>
      <PageHeader
        {...pageHeaderData}
      />
      <div
        className="dashboard__container user-portal-transaction__container"
      >
        <Spin className="loading-spiner" spinning={loading} />
        {
          !loading && <Detail
            data={data}
          />
        }
      </div>
    </>
  );
}

Transactions.propTypes = {
  id: PropTypes.string,
};

export default Transactions;
