import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
} from 'antd';

import './index.scss';

function RPCheckbox({
  children,
  className,
  ...props
}) {
  return (
    <div className={`rp-checkbox ${className}`}>
      <Checkbox {...props}>{children}</Checkbox>
    </div >
  );

};

RPCheckbox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  className: PropTypes.string,
};

export default RPCheckbox;
