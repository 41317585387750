export const availableAdditionalInputFields = [
  {
    label: 'Single Line Text',
    key: 'single-line-text',
    dataType: 'string',
    fieldType: 'input-field',
    icon: 'ooui:underline-a',
  },
  {
    label: 'Alphabets',
    key: 'alphabets',
    dataType: 'string',
    fieldType: 'input-field',
    icon: 'mdi:sort-alphabetical-ascending-variant',
  },
  {
    label: 'Alphanumeric',
    key: 'alphanumeric',
    dataType: 'string',
    fieldType: 'input-field',
    icon: 'fluent:text-number-format-24-regular',
  },
  {
    label: 'Number',
    key: 'number',
    dataType: 'number',
    fieldType: 'input-field',
    icon: 'octicon:number-24',
  },
  {
    label: 'Email',
    key: 'email',
    dataType: 'email',
    fieldType: 'input-field',
    icon: 'ic:outline-email',
  },
  {
    label: 'Phone Number',
    key: 'phone-number',
    dataType: 'phone-number',
    fieldType: 'input-field',
    icon: 'akar-icons:phone',
  },
];

export const availableAdditionalPriceFields = [
  {
    label: 'Fixed Amount',
    key: 'fixed-amount',
    dataType: 'number',
    fieldType: 'price-field',
    icon: 'fluent:person-edit-20-regular',
  },
  {
    label: 'Customers Decide Amount',
    key: 'customers-decide-amount',
    dataType: 'number',
    fieldType: 'price-field',
    icon: 'fluent:note-edit-24-regular',
  },
];
