/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import { ErrorsAlert } from '@/components';
import { isPending } from '@/utils/loadingUtil';
import { ROUTES } from '@/constants';
import RPButton from '@/components/RPButton';
import maskBg from '@/assets/images/login-register/mask.svg';
import facebookLogo from '@/assets/images/login-register/facebook-logo.svg';
import googleLogo from '@/assets/images/login-register/google-logo.svg';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

import './login.scss';
import { gapi } from 'gapi-script';

function LoginPage({
  handleLogin,
  auth,
  responseFacebook,
  responseGoogle,
}) {
  const [formInstance] = Form.useForm();

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_ID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  // Hide For Now
  const hideFacebookLogin = true;

  const onFinish = (values) => {
    handleLogin(values);
    formInstance.resetFields();
  };

  return (
    <div className='login-page'>
      <Row>
        <Col
          span={12}
          className='login-page__left'
        >
          <div className='login-page__left__container'>
            <div className='login-page__left__content'>
              <div className='login-page__left__content__title'>Welcome Back!</div>
              <div className='login-page__left__content__desc'>You can sign in to access with your existing account.</div>
              <div className='login-page__left__content__via-social'>
                <Row gutter={[36, 0]}>
                  {!hideFacebookLogin &&
                    <Col span={12}>
                      <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_ID}
                        callback={responseFacebook}
                        render={renderProps => (
                          <Button
                            onClick={() => renderProps.onClick()}
                            shape="round"
                            size='large'
                            className='btn btn-login-via-facebook'
                            icon={<img src={facebookLogo} alt='facebook logo' width='29' height='29' />}
                          >Sign in with Facebook</Button>
                        )}
                      />

                    </Col>
                  }
                  <Col>
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_ID}
                      render={renderProps => (
                        <Button
                          onClick={() => renderProps.onClick()}
                          shape="round"
                          size='large'
                          className='btn btn-login-via-google'
                          icon={<img src={googleLogo} alt='google logo' width='22' height='22' />}
                        >Sign in with Google</Button>
                      )}
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      isSignedIn={false}
                    />

                  </Col>
                </Row>
              </div>
            </div>
            <div className='form'>
              {auth.error && (
                <ErrorsAlert
                  message={auth.error.message}
                  details={auth.error.details}
                />
              )}
              <Form
                name='login'
                layout='vertical'
                onFinish={onFinish}
                form={formInstance}
                autoComplete="off"
              >
                <Form.Item
                  name='email'
                  rules={[
                    { type: 'email', message: 'Email Address is not valid' },
                  ]}
                  label={
                    <div className='form-item-label'>
                      <div>Email Address</div>
                    </div>
                  }
                >
                  <Input
                    placeholder='Email'
                    className='form-item-input'
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <div className='form-item-label --custom'>
                      <div>Password</div>
                      <div>
                        <Link to={ROUTES.FORGOT_PASSWORD} tabIndex='-1'>Forgot Password</Link>
                      </div>
                    </div>
                  }
                  name='password'
                >
                  <Input.Password
                    placeholder='Password'
                    className='form-item-input-password'
                    autoComplete='new-password'
                  />
                </Form.Item>
                <div className='form-btn-login'>
                  <RPButton
                    danger
                    htmlType='submit'
                    className='btn-submit'
                    loading={isPending(auth.loading)}
                  >
                    Sign In
                  </RPButton>
                </div>
              </Form>
            </div>
          </div>

          <div className='actions'>
            <div>Don’t have  an account yet?</div>
            <Link to={ROUTES.SIGNUP_PATH}>Sign Up</Link>
          </div>
        </Col>
        <Col
          span={12}
          className='login-page__right'
        >
          <div className='login-page__right__container'>
            <div className='login-page__right__super-title'>Supercharge</div>
            <div className='login-page__right__desc'>your business today</div>
          </div>
          <div className='login-page__right__image'>
            <img
              src={maskBg}
              alt='mask bg'
              width='100%'
              height='100%'
            />
          </div>
        </Col>
      </Row>

    </div>
  );
}
LoginPage.propTypes = {
  handleLogin: PropTypes.func,
  auth: PropTypes.object,
  responseFacebook: PropTypes.func,
  responseGoogle: PropTypes.func,
  loading: PropTypes.bool,
};

export default LoginPage;
