import React from 'react';
import PageHeader from '@/components/PageHeader';
import { Tabs } from 'antd';
// import SupportTicket from '@/components/MyAccount/SupportTicket';
// import Balance from '@/components/MyAccount/Balance';
// import ManageTeam from '@/components/MyAccount/ManageTeam';
import MyProfileContainer from '@/containers/Account/MyProfile';
import './index.scss';
import {
  useParams,
  useHistory,
} from 'react-router-dom';
import { ROUTES } from '@/constants';
import CompanyContainer from '@/containers/Account/Company';

const { TabPane } = Tabs;

function MyAccount() {
  const history = useHistory();
  const onChange = (key) => {
    history.replace(`${ROUTES.APP.MY_ACCOUNT.PATH}/${key}`);
  };
  const pageHeaderData = {
    title: 'My Account',
    description:
      'Edit and manage your account details here.',
  };
  const { id } = useParams();
  return (
    <div className='my-account'>
      <PageHeader className='my-account__page-header' {...pageHeaderData} />
      <div className="dashboard__container">
        <Tabs
          defaultActiveKey={id || 'profile'}
          className="my-account__tabs"
          onChange={onChange}
        >
          <TabPane tab="My Profile" key="profile">
            <div className='my-account__container'>
              <MyProfileContainer />
            </div>
          </TabPane>
          <TabPane tab="Company Display and details" key="company">
            <div className='my-account__container'>
              <CompanyContainer />
            </div>
          </TabPane>
          {/* <TabPane tab="Manage Team" key="manage-team">
            <div className='my-account__container'>
              <ManageTeam />
            </div>
          </TabPane>
          <TabPane tab="Balance" key="balance">
            <div className='my-account__container'>
              <Balance />
            </div>
          </TabPane>
          <TabPane tab="Support Ticket" key="ticket">
            <div className='my-account__container'>
              <SupportTicket />
            </div>
          </TabPane> */}
        </Tabs>
      </div>
    </div>
  );
}

export default MyAccount;
