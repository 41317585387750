import React from 'react';
import { useParams } from 'react-router-dom';
import PaymentLink from '@/containers/PaymentLink/Detail';

import './show.scss';

function Show() {
  const { id } = useParams();


  return (
    <PaymentLink id={id} />
  );
}

export default Show;
