import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form } from 'antd';
import moment from 'moment';

import RPButton from '@/components/RPButton';
import RPDatePicker from '@/components/RPDatePicker';
import '@/components/PaymentLink/modal.scss';
import RPInput from '@/components/RPInput';

function ModalChangePaymentLink({
  isModalVisible,
  setIsModalVisible,
  onSubmit,
  form,
  typeModal
}) {
  const disabledDate = (current) =>
    // Can not select days before today and today
    current && current < moment()
    ;

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderExpiry = (
    <Form.Item
      rules={[{ required: true, message: 'Please input expires on' }]}
      wrapperCol={{ span: 24 }}
      name="expiry"
    >
      <RPDatePicker
        placeholder="Expired at"
        label="Expired at"
        name="expiry"
        className="w-100"
        disabledDate={disabledDate}
        showNow={false}
        showTime={false}
      />
    </Form.Item>
  );

  const renderRefID = (
    <Form.Item
      wrapperCol={{ span: 24 }}
      name="refId"
    >
      <RPInput
        maxLength={60}
        placeholder="Reference ID"
        label="Reference ID"
        name="refId"
      />
    </Form.Item>
  );

  const title = typeModal === 'expiry' ? 'Change Expires On' : 'Change Reference ID';

  return (
    <Modal
      title={title}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      className="rp-modal payment-link__modal-expiry"
      centered
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        onFinish={onSubmit}
        noValidate
      >
        {
          typeModal === 'expiry' && (
            renderExpiry
          )
        }
        {
          typeModal === 'refID' && (
            renderRefID
          )
        }
        <RPButton htmlType="submit" gradient center>
          Update
        </RPButton>
      </Form>
    </Modal>
  );
}

ModalChangePaymentLink.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  onSubmit: PropTypes.func,
  form: PropTypes.object,
  typeModal: PropTypes.string
};

export default ModalChangePaymentLink;
