import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { uniqueId } from 'lodash';
import PageHeader from '@/components//PageHeader';
import List from '@/components/PaymentPages/List';
import { getPaymentPages } from '@/services/PaymentPageService';
import { PaymentPageStatus } from '@/constants/paymentPage';
import { ROUTES } from '@/constants';
import { formatCurrency, parseDateTime } from '@/utils/formatter';
import { ReactComponent as PaymentPageSP } from '@/assets/images/payment-pages/paypage-sp.svg';
import moment from 'moment';

const pageHeaderData = {
  supportElementImageNode: <PaymentPageSP />,
  title: 'Your Payments',
  description: 'Enjoy full access and features when you activate your Radical Pay account now.',
  title1: 'Payment Pages',
};

function PaymentPages() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });
  const [totalPages, setTotalPage] = useState(10);
  const renderPageTitleText = (text, record) => (
    <Link
      className="text-link"
      to={`${ROUTES.APP.PAYMENT_PAGES_PATH}/${record.id}`}
    >
      {text}
    </Link>
  );

  const renderShortLink = (link) => link ? (
    <a className="text-link" href={link} rel="noreferrer" target='_blank'>{link}</a>
  ) : '--';

  const renderStatus = (text) => {
    const status = text === 'Active' ? 'active' : 'failed';

    return <span className={`text-status-${status}`}>{text}</span>;
  };
  const columns = [
    {
      title: 'Page Title',
      dataIndex: 'title',
      key: 'title',
      render: renderPageTitleText,
      fixed: 'left',
    },
    {
      title: 'Total Sales',
      dataIndex: 'totalSaleAmount',
      key: 'totalSaleAmount',
    },
    {
      title: 'Item Name',
      key: 'itemName',
      dataIndex: 'itemName',
    },
    {
      title: 'Page URL',
      key: 'pageUrl',
      dataIndex: 'pageUrl',
      render: renderShortLink,
      ellipsis: true,
    },
    {
      title: 'Created On',
      key: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: renderStatus,
      align: 'center',
      width: 130
    },
  ];

  const parseItemName = items => items.map(item => <div key={uniqueId()}>{item.label}</div>);

  const fetchData = async (pagy) => {
    setLoading(true);
    const res = await getPaymentPages(pagy);
    setData(res.data?.map(obj => ({
      ...obj,
      key: obj.id,
      itemName: parseItemName(obj.items || []),
      createdAt: parseDateTime(obj.createdAt),
      status: PaymentPageStatus[obj.status],
      pageUrl: obj.url,
      totalSaleAmount: formatCurrency(obj.totalSaleAmount / 100)
    })));
    setTotalPage(res.pagination.totalPages);
    setLoading(false);
  };

  const onChangeTable = (params) => {
    const obj = {
      ...filter,
      ...params,
    };
    setFilter(obj);
    fetchData(obj);
  };

  const search = async (values) => {
    const newFilter = filter;
    if (values.status) {
      newFilter.status = values.status;
    } else {
      delete newFilter.status;
    }

    if (values.title) {
      newFilter.title = values.title;
    } else {
      delete newFilter.title;
    }

    if (values.daterange?.length === 2) {
      const [from, to] = values.daterange;
      newFilter.createdFrom = moment(`${from.format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD hh:mm:ss').utc().format();
      newFilter.createdTo = moment(`${to.format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD hh:mm:ss').utc().format();
    } else {
      delete newFilter.createdFrom;
      delete newFilter.createdTo;
    }

    setFilter(newFilter);
    fetchData(newFilter);
  };

  return (
    <div className="rp__payment-pages-container">
      <PageHeader
        withImage
        className="rp__payment-pages__header"
        {...pageHeaderData}
      />
      <List
        loading={loading}
        data={data}
        columns={columns}
        onChangeTable={onChangeTable}
        totalPages={totalPages}
        search={search}
      />
    </div>
  );
}

export default PaymentPages;
