import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Space, Spin } from 'antd';
import PageHeader from '@/components/PageHeader';
import RPButton from '@/components/RPButton';
import Detail from '@/components/PaymentButton/Detail';
import MerchantModeBadge from '@/components/MerchantModeBadge';
import { ROUTES } from '@/constants';
import editIcon from '@/assets/images/button/edit-icon.svg';
import { PaymentButtonStatus } from '@/constants/paymentButton';
import { formatCurrency, parseDateTime } from '@/utils/formatter';
import {
  getPaymentButtonsDetail,
  publishPaymentButton,
  unpublishPaymentButton,
} from '@/services/PaymentButtonService';
import ModalButtonCode from '@/components/PaymentButton/ModalButtonCode';

function PaymentButtonDetail({ id }) {
  const history = useHistory();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleEdit = () => {
    history.push(`${ROUTES.APP.PAYMENT_BUTTONS.INDEX}/${id}/edit`);
  };
  const fetchData = async () => {
    const res = await getPaymentButtonsDetail({ id });
    const obj = {
      id: res.id,
      title: res.title,
      createdBy: {
        name: res.user?.name,
        email: res.user?.email
      },
      dateCreated: parseDateTime(res.createdAt),
      status: PaymentButtonStatus[res.status],
      items: res.items?.map(item => ({
        amount: formatCurrency(item.amount / 100),
        unitSold: item.unitSold,
        name: item.label,
        totalSaleAmount: formatCurrency(Number(item.totalSaleAmount) / 100),
      }))
    };
    if (PaymentButtonStatus[res.status] !== 'Active') {
      setActive(false);
    }
    setData(obj);
    setLoading(false);
  };

  const onSwitch = async () => {
    let res;
    if (active) {
      res = await unpublishPaymentButton({ id: data.id });
    } else {
      res = await publishPaymentButton({ id: data.id });
    }
    if (res?.status === 200) {
      setActive(!active);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const pageHeaderData = {
    title: data.title,
    leftBtns: (
      <RPButton
        grey
        onClick={() => history.replace(ROUTES.APP.PAYMENT_BUTTONS.INDEX)}
      >
        Back
      </RPButton>
    ),
    rightBtns: (
      <Space size={12}>
        <RPButton
          grey
          icon={<img src={editIcon} alt='Edit icon' />}
          onClick={handleEdit}
        >
          Edit
        </RPButton>
        <RPButton
          grey
          onClick={() => setIsModalVisible(true)}
        >
          Button Code
        </RPButton>
      </Space>
    ),
  };

  return (
    <Spin spinning={loading}>
      <PageHeader
        {...pageHeaderData}
      />
      <MerchantModeBadge />
      <Detail
        data={data}
        onSwitch={onSwitch}
        active={active}
      />
      <ModalButtonCode
        buttonData={{ id: data.id }}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </Spin>
  );
}

PaymentButtonDetail.propTypes = {
  id: PropType.string
};

export default PaymentButtonDetail;
